import React, { useState } from "react";
import { ChromePicker } from "react-color";
import Swal from "sweetalert2";
import { useLang } from "../../helpers/language";
import config from "../../config";
import {
	Input,
	PasswordInput,
	NumberInput,
	useMantineColorScheme,
	Card,
	Select,
	Radio,
	Group,
	Switch,
	Flex,
	Button,
	Checkbox,
	useMantineTheme,
	Stack,
	Text as MantineText,
	Center,
	Tooltip
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import {
	IconAt,
	IconPhone,
	IconWorldWww,
	IconCalendar,
	IconBuildingBank,
	IconIdBadge2
} from "@tabler/icons-react";
import { IMaskInput } from "react-imask";

import { useEditor } from "@tiptap/react";
import { RichTextEditor } from "@mantine/tiptap";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";

let lastTheme = null;
let lightEditorTiming = (Math.random() * 100).toString() + 1;
let darkEditorTiming = (Math.random() * 100).toString();
let timeout = null;

const CustomInput = ({
	value,
	usageType,
	setter,
	apiurl,
	api,
	language,
	type,
	id,
	width,
	label,
	disabled,
	placeholder,
	optionListValue,
	radioValue,
	defaultValue,
	priceSetter,
	settimeInterval,
	searchable = false,
	clearable = true,
	trim,
	tolowercase,
	serverCall,
	trimStart,
	description,
	leftIcon,
	rightIcon,
	crud,
	user,
	textAreaProps = {},
	attachment = { files: [], setter: () => {} },
	audioRecorder = {
		audioFile: {},
		setAudioFile: () => {},
		uploadProgress: "",
		setUploadProgress: () => {},
		uploadSuccess: "",
		setUploadSuccess: () => {},
		audioURL: "",
		setAudioURL: () => {}
	},
	calling = {
		isCallStarted: null,
		startCall: null
	}
}) => {
	const textAreaDefaultProps = {
		toolbar: true,
		bold: true,
		italic: true,
		underline: true,
		strikethrough: true,
		code: false,
		alignLeft: false,
		alignCenter: false,
		alignRight: false,
		alignJustify: false,
		blockquote: false,
		h1: false,
		h2: false,
		h3: false,
		h4: false,
		hr: false,
		bulletList: false,
		orderedList: false,
		subscript: false,
		superscript: false,
		link: false,
		unlink: false,
		clearFormatting: false,
		highlight: false,
		undo: false,
		redo: false,
		submit: false,
		attachment: false,
		submitOnEnter: false,
		audioRecorder: false,
		emoji: false,
		videoCall: false,
		audioCall: false
	};
	const mergedTextAreaProps = { ...textAreaDefaultProps, ...textAreaProps };
	const [showColorPicker, setshowColorPicker] = useState(false);
	const [bloko, setbloko] = useState("");
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();

	const handleChange = async (e) => {
		let value = e.target?.value;
		if (trim) {
			value = value.trim();
		}
		if (tolowercase) value = value.toLowerCase();
		if (trimStart) value = value.trimStart();
		if (api && apiurl) {
			// Init a timeout variable to be used below
			clearTimeout(timeout);
			timeout = setTimeout(async function () {
				if (type === "email") {
					let res = await api.get(apiurl, {
						email: value
					});
					if (res[0]) {
						Swal.fire(config.swal.emailIsBusy[language]);
						setter("");
						return;
					}
				} else {
					let res = await api.get(apiurl, {
						username: value
					});
					if (res[0]) {
						Swal.fire(config.swal.usernameIsBusy[language]);
						setter("");
						return;
					}
				}
			}, 1000);
		}
		setter(value);
	};

	const handleColorChange = (color) => {
		setter(color.hex);
	};

	const toggleShowColorPicker = () => {
		setshowColorPicker(!showColorPicker);
	};

	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Superscript,
			SubScript,
			TextAlign.configure({ types: ["heading", "paragraph"] })
		],
		editable: !disabled,
		onUpdate({ editor }) {
			if (editor.getHTML() === "<p></p>") {
				editor.commands.setContent("");
				setter("");
			} else {
				setter(editor.getHTML());
			}
		},
		content: value
	});

	if (usageType === "textfield") {
		return (
			<Input.Wrapper label={label}>
				<Input
					placeholder={placeholder}
					onChange={handleChange}
					value={value || ""}
					disabled={disabled}
					leftSection={leftIcon ? leftIcon : null}
					rightSection={rightIcon ? rightIcon : null}
				/>
			</Input.Wrapper>
		);
	} else if (usageType === "idCard") {
		return (
			<Input.Wrapper label={label}>
				<Input
					placeholder={placeholder}
					onChange={handleChange}
					value={value || ""}
					disabled={disabled}
					leftSection={<IconIdBadge2 size={16} />}
				/>
			</Input.Wrapper>
		);
	} else if (usageType === "bank") {
		return (
			<Input.Wrapper label={label}>
				<Input
					placeholder={placeholder}
					onChange={handleChange}
					value={value || ""}
					disabled={disabled}
					leftSection={<IconBuildingBank size={16} />}
				/>
			</Input.Wrapper>
		);
	} else if (usageType === "email") {
		return (
			<Input.Wrapper label={label}>
				<Input
					placeholder={placeholder}
					onChange={handleChange}
					value={value || ""}
					leftSection={<IconAt size={16} />}
					disabled={disabled}
				/>
			</Input.Wrapper>
		);
	} else if (usageType === "phone") {
		return (
			<Input.Wrapper label={label}>
				<Input
					placeholder={placeholder}
					onChange={handleChange}
					value={value || ""}
					leftSection={<IconPhone size={16} />}
					component={IMaskInput}
					mask="+374 (00) 00-00-00"
					disabled={disabled}
				/>
			</Input.Wrapper>
		);
	} else if (usageType === "password") {
		return (
			<Input.Wrapper label={label}>
				<PasswordInput
					placeholder={placeholder}
					id="your-password"
					value={value || ""}
					onChange={handleChange}
					disabled={disabled}
				/>
			</Input.Wrapper>
		);
	} else if (usageType === "textarea") {
		return (
			<RichTextEditor editor={editor}>
				{mergedTextAreaProps.toolbar && (
					<RichTextEditor.Toolbar sticky stickyOffset={60}>
						{[
							{
								group: [
									{ condition: mergedTextAreaProps.bold, component: <RichTextEditor.Bold /> },
									{ condition: mergedTextAreaProps.italic, component: <RichTextEditor.Italic /> },
									{
										condition: mergedTextAreaProps.underline,
										component: <RichTextEditor.Underline />
									},
									{
										condition: mergedTextAreaProps.strikethrough,
										component: <RichTextEditor.Strikethrough />
									},
									{
										condition: mergedTextAreaProps.clearFormatting,
										component: <RichTextEditor.ClearFormatting />
									},
									{ condition: mergedTextAreaProps.code, component: <RichTextEditor.Code /> }
								]
							},
							{
								group: [
									{ condition: mergedTextAreaProps.h1, component: <RichTextEditor.H1 /> },
									{ condition: mergedTextAreaProps.h2, component: <RichTextEditor.H2 /> },
									{ condition: mergedTextAreaProps.h3, component: <RichTextEditor.H3 /> },
									{ condition: mergedTextAreaProps.h4, component: <RichTextEditor.H4 /> },
									{ condition: mergedTextAreaProps.hr, component: <RichTextEditor.Hr /> }
								]
							},
							{
								group: [
									{
										condition: mergedTextAreaProps.blockquote,
										component: <RichTextEditor.Blockquote />
									},
									{
										condition: mergedTextAreaProps.bulletList,
										component: <RichTextEditor.BulletList />
									},
									{
										condition: mergedTextAreaProps.orderedList,
										component: <RichTextEditor.OrderedList />
									},
									{
										condition: mergedTextAreaProps.subscript,
										component: <RichTextEditor.Subscript />
									},
									{
										condition: mergedTextAreaProps.superscript,
										component: <RichTextEditor.Superscript />
									}
								]
							},
							{
								group: [
									{ condition: mergedTextAreaProps.link, component: <RichTextEditor.Link /> },
									{ condition: mergedTextAreaProps.unlink, component: <RichTextEditor.Unlink /> }
								]
							},
							{
								group: [
									{
										condition: mergedTextAreaProps.alignLeft,
										component: <RichTextEditor.AlignLeft />
									},
									{
										condition: mergedTextAreaProps.alignCenter,
										component: <RichTextEditor.AlignCenter />
									},
									{
										condition: mergedTextAreaProps.alignRight,
										component: <RichTextEditor.AlignRight />
									},
									{
										condition: mergedTextAreaProps.alignJustify,
										component: <RichTextEditor.AlignJustify />
									},
									{
										condition: mergedTextAreaProps.highlight,
										component: <RichTextEditor.Highlight />
									}
								]
							},
							{
								group: [
									{ condition: mergedTextAreaProps.undo, component: <RichTextEditor.Undo /> },
									{ condition: mergedTextAreaProps.redo, component: <RichTextEditor.Redo /> }
								]
							}
						].map((section, index) => (
							<RichTextEditor.ControlsGroup key={index}>
								{section.group.map(
									(item, idx) =>
										item.condition && <React.Fragment key={idx}>{item.component}</React.Fragment>
								)}
							</RichTextEditor.ControlsGroup>
						))}
					</RichTextEditor.Toolbar>
				)}
				<RichTextEditor.Content />
			</RichTextEditor>
		);
	} else if (usageType === "textarea-tiny") {
		// let lightEdotor = (
		// 	<Input.Wrapper label={label}>
		// 		<Editor
		// 			id={lightEditorTiming}
		// 			key={lightEditorTiming}
		// 			disabled={disabled}
		// 			onInit={(evt, editor) => (editorRef.current = editor)}
		// 			init={{
		// 				height: "50vh",
		// 				width: "100%",
		// 				strict_loading_mode: true,
		// 				statusbar: false,
		// 				branding: false,
		// 				placeholder: placeholder,
		// 				fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
		// 				menubar: "file edit insert view format table",
		// 				plugins: [
		// 					"charmap advlist autolink lists link image charmap print preview anchor",
		// 					"searchreplace visualblocks code fullscreen",
		// 					"insertdatetime media table paste code help wordcount code"
		// 				],
		// 				formatpainter_blacklisted_formats:
		// 					"link,address,removeformat,formatpainter_removeformat",
		// 				toolbar:
		// 					// eslint-disable-next-line
		// 					"charmap | undo redo | formatselect | bold italic backcolor | \
		// 						alignleft aligncenter alignright alignjustify | \
		// 						bullist numlist outdent indent | removeformat | help"
		// 			}}
		// 			value={value}
		// 			onEditorChange={handleTextFieldChange}
		// 		/>
		// 	</Input.Wrapper>
		// );
		// let darkEditor = (
		// 	<Input.Wrapper label={label}>
		// 		<Editor
		// 			id={darkEditorTiming}
		// 			key={darkEditorTiming}
		// 			disabled={disabled}
		// 			onInit={(evt, editor) => (editorRef.current = editor)}
		// 			init={{
		// 				skin: "oxide-dark",
		// 				content_css: "dark",
		// 				height: "50vh",
		// 				width: "100%",
		// 				strict_loading_mode: true,
		// 				statusbar: false,
		// 				branding: false,
		// 				placeholder: placeholder,
		// 				fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
		// 				menubar: "file edit insert view format table",
		// 				plugins: [
		// 					"charmap advlist autolink lists link image charmap print preview anchor",
		// 					"searchreplace visualblocks code fullscreen",
		// 					"insertdatetime media table paste code help wordcount code"
		// 				],
		// 				formatpainter_blacklisted_formats:
		// 					"link,address,removeformat,formatpainter_removeformat",
		// 				toolbar:
		// 					// eslint-disable-next-line
		// 					"charmap | undo redo | formatselect | bold italic backcolor | \
		// 						alignleft aligncenter alignright alignjustify | \
		// 						bullist numlist outdent indent | removeformat | help"
		// 			}}
		// 			value={value}
		// 			onEditorChange={handleTextFieldChange}
		// 		/>
		// 	</Input.Wrapper>
		// );
		// if (colorScheme === "light" && lastTheme === "light") return lightEdotor;
		// else if (colorScheme === "dark" && lastTheme === "dark") return darkEditor;
		// else if (colorScheme === "light" && lastTheme === "dark") {
		// 	lastTheme = colorScheme;
		// 	return lightEdotor;
		// } else if (colorScheme === "dark" && lastTheme === "light") {
		// 	lastTheme = colorScheme;
		// 	return darkEditor;
		// } else if (!lastTheme) {
		// 	lastTheme = colorScheme;
		// 	if (colorScheme === "light") return lightEdotor;
		// 	else return darkEditor;
		// }
	} else if (usageType === "optionList") {
		if (optionListValue && Array.isArray(optionListValue)) {
			const normalizedValue = Array.isArray(value)
				? value[0]._id
				: typeof value === "object" && value?._id
				? value._id
				: value;

			const normalizedoptionList = optionListValue
				.filter((item) => item && (item._id || item.value || item.name)) // Ensure valid items
				.map((item) => {
					const label = (
						item.label?.[lang] ||
						item.label ||
						item.name?.[lang] ||
						item.title?.[lang] ||
						item.name ||
						item.title ||
						config.translate.nothingFound[lang]
					).toString();
					const value = item._id || item.value || label;
					return { label, value };
				});

			const isValidValue = normalizedoptionList.some((item) => item.value === normalizedValue);

			if (!isValidValue) {
				console.warn("🚀 ~ Value not found in optionListValue:", normalizedValue);
			}

			return (
				<Input.Wrapper label={label}>
					<Select
						placeholder={placeholder}
						disabled={disabled}
						searchable={searchable}
						clearable={clearable}
						allowDeselect={false}
						data={normalizedoptionList}
						value={normalizedValue}
						onChange={setter}
						nothingFoundMessage={config.translate.nothingFound[lang]}
						defaultValue={defaultValue}
					/>
				</Input.Wrapper>
			);
		}
		return null;
	} else if (usageType === "switch") {
		return (
			<Flex direction={"column"} align={"center"}>
				<MantineText>{label}</MantineText>
				<Switch
					checked={value}
					mt={10}
					value={value}
					onChange={() => {
						setter(!value);
					}}
				/>
			</Flex>
		);
	} else if (usageType === "datePicker") {
		let localValue;
		if (value) {
			localValue = JSON.parse(JSON.stringify(value));
			localValue = new Date(localValue);
		} else {
			localValue = null;
		}
		return (
			<DateInput
				value={localValue}
				onChange={setter}
				valueFormat="DD/MM/YYYY"
				label={label}
				clearable
				placeholder={placeholder}
				leftSection={<IconCalendar size={16} />}
				disabled={disabled}
			/>
		);
	} else if (usageType === "number") {
		return (
			<NumberInput
				label={label}
				description={description}
				placeholder={placeholder}
				value={value || 0}
				onChange={(val) => setter(val)}
				disabled={disabled}
			/>
		);
	} else if (usageType === "url") {
		return (
			<Input.Wrapper label={label}>
				<Input
					placeholder={placeholder}
					onChange={handleChange}
					value={value || ""}
					leftSection={<IconWorldWww size={16} />}
					component={IMaskInput}
					mask="https://www.***************************************************"
					disabled={disabled}
				/>
			</Input.Wrapper>
		);
	} else if (usageType === "boolean") {
		return (
			<div key={id} className="form-element">
				<label htmlFor={label}>{label}</label>
				{value ? (
					<p>
						<i className="fas fa-thumbs-up"></i>
					</p>
				) : (
					<p>
						<i className="fas fa-exclamation-circle"></i>
					</p>
				)}
			</div>
		);
	} else if (usageType === "color-picker") {
		return (
			<div key={id} className="form-element">
				<label htmlFor={label}>{label}</label>
				<div className="input-group">
					<div className="input-group-btn">
						<button className="btn btn-info btn-flat" onClick={toggleShowColorPicker}>
							Color Picker
						</button>
					</div>
					{/* <p >color test</p> */}
					<input
						value={value || ""}
						onChange={handleChange}
						type={type}
						className="form-control"
						id={label}
						placeholder={placeholder}
					/>
					<div className="input-group-btn">
						<button className="btn btn-flat" style={{ backgroundColor: value }}>
							Color Example
						</button>
					</div>
				</div>
				{showColorPicker && <ChromePicker onChange={handleColorChange} />}
			</div>
		);
	} else if (usageType === "radio") {
		return (
			<Input.Wrapper label={label} key={new Date().getTime()}>
				<Radio.Group
					name={label}
					onChange={(e) => {
						console.log("🚀 ~ e:", e);
						setter(e);
					}}
				>
					<Group mt="xs">
						{radioValue && radioValue.length > 0
							? radioValue.map((val, index) => {
									return (
										<Radio
											key={index}
											value={val.value}
											label={val.label}
											checked={val.value === value}
										/>
									);
							  })
							: null}
					</Group>
				</Radio.Group>
			</Input.Wrapper>
		);
	} else if (usageType === "checkbox") {
		return (
			<Input.Wrapper label={label}>
				<Group mt="xs">
					<Checkbox
						checked={value}
						onChange={(e) => {
							setter(e.target.checked);
						}}
					/>
				</Group>
			</Input.Wrapper>
		);
	} else {
		return <h1 key={id}>There is no content to show in input</h1>;
	}
};

export default CustomInput;
