import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as API from "../helpers/api";
import { useLang } from "../helpers/language";
import config from "../config";
import { UPDATE, DELETE } from "../helpers/CRUD";
import classes from "./Tabs.module.css";
//! import modules
import Input from "./modules/input";
import { FilesComponent } from "./modules/filesComponent";
import { TableComponent } from "./modules/tableComponent";
import { ToDoComponent } from "./modules/toDoComponent";
import { CartComponent } from "./modules/cartComponent";
import "date-fns";
import { useUser } from "../helpers/userContext";
import {
	IconArrowBack,
	IconDashboard,
	IconDeviceFloppy,
	IconList,
	IconMessage,
	IconPhoto,
	IconPhotoCode,
	IconPlus,
	IconRotateDot,
	IconTable,
	IconTableColumn
} from "@tabler/icons-react";
import {
	Group,
	Button,
	CloseIcon,
	Container,
	Title,
	Card,
	Tabs,
	Grid,
	CardSection,
	useMantineColorScheme,
	useMantineTheme,
	rem
} from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { TimelineComponent } from "./modules/timelineComponent";

export default function SinglePage({
	inputs,
	simplePage,
	filesComponent,
	tableComponent,
	timelineComponent,
	editpathName,
	toDoComponent,
	hardReload,
	cartComponent,
	route,
	id,
	modelSendToServer,
	setBusy,
	connectedRoute,
	connectedData,
	connectedID,
	commentTicket = () => {},
	closeTicket,
	firstStatus,
	lastStatus,
	Status
}) {
	const { lang, setLang } = useLang();
	const { user, setUser } = useUser();
	const history = useHistory();
	const location = useLocation();
	let formatedLocation = location.search
		.replace("?", "")
		.split("&")
		.reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();
	const [isDesktop, setDesktop] = useState(false);

	function setIsDesktop() {
		if (window.innerWidth > 1000) {
			setDesktop(true);
		} else if (window.innerWidth <= 1000) {
			setDesktop(false);
		}
	}

	// get windows size
	useEffect(() => {
		setIsDesktop();
		window.addEventListener("resize", setIsDesktop);
		return () => window.removeEventListener("resize", setIsDesktop);
	}, []);

	return (
		<Tabs
			defaultValue="first"
			value={formatedLocation.tab}
			variant="unstyled"
			classNames={classes}
			onChange={(value) =>
				history.push(`/${route}/${editpathName ? "edit/" : ""}${id}?tab=${value}`)
			}
		>
			<Tabs.List>
				<Tabs.Tab value="first" color="blue" leftSection={<IconList size={14} />}>
					{config.translate.mainInformation?.[lang]}
				</Tabs.Tab>
				{filesComponent ? (
					<Tabs.Tab value="second" color="orange" leftSection={<IconPhoto size={14} />}>
						{filesComponent.length === 1 && filesComponent[0].placeholder
							? filesComponent[0].placeholder
							: config.translate.files?.[lang]}
					</Tabs.Tab>
				) : null}
				{cartComponent ? (
					<Tabs.Tab value="third" color="cyan" leftSection={<IconTableColumn size={14} />}>
						{cartComponent.title
							? cartComponent.title
							: cartComponent.data?.length === 1 && cartComponent.data?.[0].placeholder
							? cartComponent.data?.[0].placeholder
							: config.translate.cart?.[lang]}
					</Tabs.Tab>
				) : null}
				{toDoComponent ? (
					<Tabs.Tab value="forth" color="violet" leftSection={<IconRotateDot size={14} />}>
						{toDoComponent.length === 1 && toDoComponent[0].placeholder
							? toDoComponent[0].placeholder
							: config.translate.toDo?.[lang]}
					</Tabs.Tab>
				) : null}
				{tableComponent ? (
					<Tabs.Tab value="sixth" color="green" leftSection={<IconTable size={14} />}>
						{config.translate.table?.[lang]}
					</Tabs.Tab>
				) : null}
				{timelineComponent ? (
					<Tabs.Tab value="timeline" color="green" leftSection={<IconTable size={14} />}>
						{timelineComponent.length === 1 && timelineComponent[0].placeholder
							? timelineComponent[0].placeholder
							: config.translate.timeline?.[lang]}
					</Tabs.Tab>
				) : null}
			</Tabs.List>
			{inputs && (
				<Tabs.Panel value="first">
					<Card
						shadow="sm"
						p="md"
						style={{
							borderTopLeftRadius: 0
						}}
					>
						<Grid
							grow
							p={20}
							style={{
								background: colorScheme === "dark" ? Theme.colors.dark[6] : ""
							}}
						>
							{inputs.map((input, index) => {
								if (input?.lang) {
									if (input.lang === lang) {
										return (
											<Grid.Col
												key={index}
												span={{
													base: 12,
													md: input.width ? input.width : 6,
													lg: input.width ? input.width : 3
												}}
											>
												<Input
													key={index}
													id={index}
													label={input.label}
													field={input.field}
													placeholder={input.placeholder}
													price={input.price}
													priceSetter={input.priceSetter}
													timeInterval={input.timeInterval}
													settimeInterval={input.settimeInterval}
													value={input.value}
													width={input.width}
													setter={input.setter}
													type={input.type}
													lang={input.lang}
													user={user}
													usageType={input.usageType}
													optionListValue={input.optionListValue}
													radioValue={input.radioValue}
													disabled={input.disabled}
													crud={{
														create: true,
														read: true,
														update: true,
														delete: true
													}}
													selectSomething={input.selectSomething}
												/>
											</Grid.Col>
										);
									}
								} else {
									return (
										<Grid.Col
											span={{
												base: 12,
												md: input?.width ? input?.width : 6,
												lg: input?.width ? input?.width : 3
											}}
										>
											<Input
												key={index}
												id={index}
												label={input?.label}
												field={input?.field}
												placeholder={input?.placeholder}
												price={input?.price}
												priceSetter={input?.priceSetter}
												timeInterval={input?.timeInterval}
												settimeInterval={input?.settimeInterval}
												value={input?.value}
												setter={input?.setter}
												user={user}
												type={input?.type}
												radioValue={input?.radioValue}
												width={input?.width}
												usageType={input?.usageType}
												optionListValue={input?.optionListValue}
												disabled={input?.disabled}
												crud={{
													create: true,
													read: true,
													update: true,
													delete: true
												}}
											/>
										</Grid.Col>
									);
								}
							})}
						</Grid>
						<CardSection p={20} mt={20}>
							<Group justify="center" grow>
								{
									<>
										<Button
											leftSection={
												<IconDeviceFloppy
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											variant="light"
											onClick={async () => {
												try {
													let result = {};
													let updatedUser = { ...user };
													let callSuccess = false;
													if (location.pathname.includes(user._id)) {
														Object.keys(updatedUser).forEach((key) => {
															if (modelSendToServer[key] === undefined) return;
															updatedUser[key] = modelSendToServer[key];
														});
														updatedUser.temporary = false;
														updatedUser._id = user._id;
														callSuccess = await UPDATE({
															modelSendToServer: updatedUser,
															route,
															setBusy,
															id,
															connectedRoute,
															connectedData,
															connectedID,
															lang,
															user,
															filesComponent,
															hardReload,
															Status
														});
														if (callSuccess) {
															result = await API.update(
																config.api.user,
																updatedUser._id,
																{
																	...updatedUser,
																	history: [
																		{
																			date: new Date(),
																			action: {
																				method: "updated",
																				name: config.translate.userUpdated[lang],
																				route: {
																					pathname: location.pathname
																						.replace(/\/$/, "")
																						.replace(/^\//, ""),
																					search: location.search.replace(/^\?/, "")
																				}
																			}
																		},
																		...user?.history?.slice(0, 20)
																	]
																},
																["role", "position", "department"]
															);
															Object.keys(user).forEach((key) => {
																if (result[key] === undefined) return;
																if (key === "role") {
																	updatedUser[key] = result[key]?.[0];
																} else {
																	updatedUser[key] = result[key];
																}
															});
															setUser({
																...updatedUser,
																history: user.history ? user.history : updatedUser.history
															});
														}
													} else {
														callSuccess = await UPDATE({
															modelSendToServer,
															route,
															history,
															setBusy,
															id,
															connectedRoute,
															connectedData,
															connectedID,
															lang,
															user,
															filesComponent,
															hardReload,
															Status
														});
														if (callSuccess) {
															result = await API.update(config.api.user, updatedUser._id, {
																...updatedUser,
																history: [
																	{
																		date: new Date(),
																		action: {
																			method: "updated",
																			name: config.translate.userUpdated[lang],
																			route: {
																				pathname: location.pathname
																					.replace(/\/$/, "")
																					.replace(/^\//, ""),
																				search: location.search.replace(/^\?/, "")
																			}
																		}
																	},
																	...user?.history?.slice(0, 20)
																]
															});
															setUser({
																...updatedUser,
																history: result.history ? result.history : updatedUser.history
															});
														}
													}
												} catch (error) {
													console.error(error);
												}
											}}
										>
											{config.translate.save?.[lang]}
										</Button>

										<Button
											leftSection={
												<IconArrowBack
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											color="orange"
											variant="light"
											onClick={() => history.goBack()}
										>
											{config.translate.cancel?.[lang]}
										</Button>
									</>
								}
								{route !== "ticket" && user?.role !== undefined ? (
									<Button
										color="red"
										leftSection={
											<CloseIcon size={14} color={colorScheme === "dark" ? "white" : "black"} />
										}
										variant="light"
										onClick={async () => {
											try {
												let deletedObject = await DELETE({ route, id, history, lang, setBusy });
												if (deletedObject) {
													let result = {};
													result = await API.update(config.api.user, user._id, {
														...user,
														history: [
															{
																date: new Date(),
																action: {
																	method: "deleted",
																	name: config.translate.userDeleted[lang],
																	route: {
																		pathname: location.pathname
																			.replace(/\/$/, "")
																			.replace(/^\//, ""),
																		search: location.search.replace(/^\?/, "")
																	}
																}
															},
															...user.history.slice(0, 20)
														]
													});
													setUser({
														...user,
														history: result.history ? result.history : user.history
													});
												}
											} catch (error) {
												console.error(error);
											}
										}}
									>
										{config.translate.remove?.[lang]}
									</Button>
								) : null}
								{route === "ticket" &&
								lastStatus !== modelSendToServer.status &&
								firstStatus !== modelSendToServer.status &&
								!modelSendToServer.closedDate ? (
									<Button
										leftSection={
											<IconMessage size={14} color={colorScheme === "dark" ? "white" : "black"} />
										}
										color="orange"
										variant="light"
										onClick={commentTicket}
									>
										{config.translate.commentTicket?.[lang]}
									</Button>
								) : null}
								{route === "ticket" && lastStatus === modelSendToServer.status && (
									<Button
										leftSection={
											<IconDashboard size={14} color={colorScheme === "dark" ? "white" : "black"} />
										}
										disabled={
											lastStatus === modelSendToServer.status && modelSendToServer.closedDate
										}
										color="green"
										variant="light"
										onClick={closeTicket}
									>
										{config.translate.closeTicket?.[lang]}
									</Button>
								)}
							</Group>
						</CardSection>
					</Card>
				</Tabs.Panel>
			)}
			{filesComponent && (
				<Tabs.Panel value="second">
					<Card shadow="sm" p="md">
						<Grid
							p={20}
							m={0}
							style={{
								backgroundColor: colorScheme === "dark" ? Theme.colors.dark[6] : ""
							}}
						>
							{filesComponent.map((component, index) => {
								return (
									<Grid.Col span={{ base: 12, md: 6 }} key={index}>
										<FilesComponent
											key={index}
											id={id}
											destiny={component.destiny}
											file={component.file}
											files={component.files}
											filesUploadLimit={component.filesUploadLimit}
											acceptedFiles={component.acceptedFiles}
											lang={component.lang}
											alt={component.alt}
											objectToAttachAnUpload={component.objectToAttachAnUpload}
											setAlt={component.setAlt}
											setBusy={setBusy}
											setFile={component.setFile}
											setFiles={component.setFiles}
											route={route}
											thumbSizeWidth={component.thumbSizeWidth}
											thumbSizeHeight={component.thumbSizeHeight}
											responsiveImages={component.responsiveImages}
											width={component.width}
											height={component.height}
											filesMaxSize={component.filesMaxSize}
											disabled={component.disabled}
											user={user}
											crud={{
												create: true,
												read: true,
												update: true,
												delete: true
											}}
											adminMode={component.adminMode}
											date={component.date}
											placeholder={component.placeholder}
										/>
									</Grid.Col>
								);
							})}
						</Grid>
					</Card>
				</Tabs.Panel>
			)}
			{cartComponent?.data && (
				<Tabs.Panel value="third">
					{cartComponent?.data?.map((component, index) => {
						<CartComponent
							key={index}
							title={component.title}
							data={component.data}
							columns={component.columns}
							setter={component.setter}
							value={component.value}
							pagination={component.pagination}
							width={component.width}
							customStyles={component.customStyles}
							placeholder={component.placeholder}
							disabled={component.disabled}
							user={user}
							crud={{
								create: true,
								read: true,
								update: true,
								delete: true
							}}
						/>;

						return null;
					})}
				</Tabs.Panel>
			)}
			{toDoComponent && (
				<Tabs.Panel value="forth">
					{toDoComponent.map((component, index) => {
						return (
							<ToDoComponent
								key={index}
								title={component.title}
								data={component.data}
								columns={component.columns}
								setter={component.setter}
								value={component.value}
								pagination={component.pagination}
								width={component.width}
								customStyles={component.customStyles}
								limit={component.limit}
								user={user}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						);
					})}
				</Tabs.Panel>
			)}
			{tableComponent && (
				<Tabs.Panel value="sixth">
					{tableComponent.map((component, index) => {
						return (
							<TableComponent
								key={index}
								title={component.title}
								data={component.data}
								columns={component.columns}
								setter={component.setter}
								conditionalRowStyles={component.conditionalRowStyles}
								user={user}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						);
					})}
				</Tabs.Panel>
			)}
			{timelineComponent && (
				<Tabs.Panel
					value="timeline"
					style={{
						display: "flex",
						flexDirection: isDesktop ? "row" : "column"
					}}
				>
					{timelineComponent.map((component, index) => {
						return (
							<TimelineComponent
								key={index}
								placeholder={component.placeholder}
								data={component.data}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
								columns={component.columns}
								user={user}
							/>
						);
					})}
				</Tabs.Panel>
			)}
		</Tabs>
	);
}
