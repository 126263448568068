import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";

export default function Page() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.valute;
  const { lang } = useLang();

  const [index, setIndex] = useState("");
  const [value, setValue] = useState("");

  const [nameEn, setNameEn] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameHy, setNameHy] = useState("");
  const [nameFr, setNameFr] = useState("");
  const [nameSp, setNameSp] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameTur, setNameTur] = useState("");
  const [namePers, setNamePers] = useState("");
  const [nameCh, setNameCh] = useState("");

  const modelSendToServer = {
    index,
    value,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
      fr: nameFr,
      sp: nameSp,
      ar: nameAr,
      tur: nameTur,
      pers: namePers,
      ch: nameCh,
    },
  };

  useEffect(() => {
    (async () => {
      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          {},
          {},
          {},
          []
        );
        setIndex(currentItem[0]?.index);
        setValue(currentItem[0]?.value);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setNameFr(currentItem[0]?.name?.fr);
        setNameSp(currentItem[0]?.name?.sp);
        setNameAr(currentItem[0]?.name?.ar);
        setNameTur(currentItem[0]?.name?.tur);
        setNamePers(currentItem[0]?.name?.pers);
        setNameCh(currentItem[0]?.name?.ch);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setIndex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
          },
          {
            value: value,
            setter: setValue,
            type: "number",
            usageType: "number",
            label: config.translate.value[lang],
            placeholder: config.translate.value[lang],
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "en",
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "ru",
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "hy",
          },
          {
            value: nameFr,
            setter: setNameFr,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "fr",
          },
          {
            value: nameSp,
            setter: setNameSp,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "sp",
          },
          {
            value: nameAr,
            setter: setNameAr,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "ar",
          },
          {
            value: nameTur,
            setter: setNameTur,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "tur",
          },
          {
            value: namePers,
            setter: setNamePers,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "pers",
          },
          {
            value: nameCh,
            setter: setNameCh,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "ch",
          },
        ]}
      />
    );
}
