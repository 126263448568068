import React, { useEffect, useState } from "react";
import config from "../../config";
import CustomInput from "./input";
import {
  Input,
  useMantineColorScheme,
  useMantineTheme,
  MantineProvider,
  Button,
  Box,
  Flex,
  Menu,
  Text,
  Title,
} from "@mantine/core";
import { useLang } from "../../helpers/language";
import Select from "react-select";

import {
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import {
  IconUserCircle,
  IconSend,
  IconEdit,
  IconDownload,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useDidUpdate } from "@mantine/hooks";

export function TableComponent({
  value = [],
  setter,
  columns,
  data,
  placeholder,
  disabled,
  key,
}) {
  const { colorScheme } = useMantineColorScheme();
  const Theme = useMantineTheme();

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      background: colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
      border: `1px solid ${
        colorScheme === "dark" ? Theme.colors.dark[4] : Theme.colors.dark[1]
      }`,
      fontSize: Theme.fontSizes.sm,
      boxShadow: "none",
      color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9],
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      background: colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
      zIndex: 1000,
      color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9],
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor:
        colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
      color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9],
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      background: "transparent",
      color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9],
    }),
  };

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    let merged = selectedRows;
    // props.setter(selectedRows);
  };

  const table = useMantineReactTable({
    columns,
    data,
    // enableRowSelection: true,
    // paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    // mantinePaginationProps: {
    //   radius: "xl",
    //   size: "lg",
    // },
  });
  return (
    <MantineProvider key={key}>
      <MantineReactTable table={table} />
    </MantineProvider>
  );
}
