import { Box, Center, Container } from "@mantine/core";
import React from "react";

export default function Home() {
  return (
    <Container size="sm">
      <Center maw={400} h={400}>
        <Box>
          <table className="home-table">
            <tbody>
              <tr>
                <td className="home-table-td_first">
                  <img src="/logo.svg" alt="dms" />
                </td>
              </tr>

              <tr>
                <td className="home-table-td_second">
                  Коммуникационная платформа для компаний, где полная
                  <br /> конфиденциальность имеет решающее значение для бизнеса.
                </td>
              </tr>

              <tr>
                <td className="home-table-td_third">
                  <a href="https://admin.apit.am" type="button">
                    Перейти в основную платформу
                  </a>
                </td>
              </tr>

              <tr>
                <td className="home-table-td_fourth">
                  Если у Вас возникли вопросы обращайтесь по адресу <br />
                  <a href="mailto:info@deepmindsystems.com">
                    info@deepmindsystems.com
                  </a>
                </td>
              </tr>

              <tr>
                <td className="home-table-td_fifth">
                  С уважением, команда{" "}
                  <a href="https://deepmindsystems.com">Deepminsystems</a>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Center>
    </Container>
  );
}
