import { useContext, createContext } from "react";
import { useState } from "react";
import {
  UnstyledButton,
  Menu,
  Image,
  Group,
  Radio,
  Flex,
  Button,
  useMantineTheme,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import classes from "./LanguagePicker.module.css";
import config from "../config";

export const Language = createContext();

export function useLang() {
  return useContext(Language);
}

const data = [
  {
    label: "English",
    image: <span className="fi fi-us"></span>,
    value: "en",
  },
  {
    label: "Русский",
    image: <span className="fi fi-ru"></span>,
    value: "ru",
  },
  {
    label: "Հայերեն",
    image: <span className="fi fi-am"></span>,
    value: "hy",
  },
  {
    label: "Français",
    image: <span className="fi fi-fr"></span>,
    value: "fr",
  },
  {
    label: "Español",
    image: <span className="fi fi-es"></span>,
    value: "sp",
  },
  {
    label: "العربية",
    image: <span className="fi fi-sa"></span>,
    value: "ar",
  },
  {
    label: "Türkçe",
    image: <span className="fi fi-tr"></span>,
    value: "tur",
  },
  {
    label: "فارسی",
    image: <span className="fi fi-ir"></span>,
    value: "pers",
  },
  {
    label: "中文",
    image: <span className="fi fi-cn"></span>,
    value: "ch",
  }
];

export function LanguagePicker() {
  const { lang, setLang } = useLang();
  const theme = useMantineTheme();
  return (
    <Flex wrap="wrap" mt={1} direction={"row"}>
      {data.map((item) => (
        <Button
          justify="center"
          width
          value={item.value}
          onClick={() => setLang(item.value)}
          m={2}
          leftSection={item.image}
          variant={lang === item.value ? "light" : "default"}
        >
          {item.label}
        </Button>
      ))}
    </Flex>
  );
}
