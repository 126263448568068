import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";

export default function List() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessor: "ID",
      header: config.translate.ID[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = row.ID ? row.ID : "...";
        return result;
      },
    },
    {
      accessorFn: (row) =>
        `${row.name}`,
      header: config.translate.name[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.name ? row.name: "...");
        return result;
      },
    },
    {
      accessorFn: (row) => `${row.phone}`,
      header: config.translate.phone[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.phone ? row.phone : "...");
        return result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.order}
      query={{}}
      sorting={{ index: "asc" }}
      uploadType={true}
      limit={null}
      columns={columns}
    />
  );
}
