import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import Swal from "sweetalert2";

export default function Page() {
  const [isBusy, setBusy] = useState(true);
  const route = config.api.map;
  const { lang } = useLang();
  const [id, setId] = useState(useParams().id);

  const [lat, setlat] = useState("");
  const [lng, setLng] = useState("");
  const [zoom, setZoom] = useState("");

  const [index, setindex] = useState("");

  const [nameEn, setNameEn] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameHy, setNameHy] = useState("");
  const [nameFr, setNameFr] = useState("");
  const [nameSp, setNameSp] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameTur, setNameTur] = useState("");
  const [namePers, setNamePers] = useState("");
  const [nameCh, setNameCh] = useState("");

  const [addressEn, setAddressEn] = useState("");
  const [addressRu, setAddressRu] = useState("");
  const [addressHy, setAddressHy] = useState("");
  const [addressFr, setAddressFr] = useState("");
  const [addressSp, setAddressSp] = useState("");
  const [addressAr, setAddressAr] = useState("");
  const [addressTur, setAddressTur] = useState("");
  const [addressPers, setAddressPers] = useState("");
  const [addressCh, setAddressCh] = useState("");

  const [countryEn, setCountryEn] = useState("");
  const [countryRu, setCountryRu] = useState("");
  const [countryHy, setCountryHy] = useState("");
  const [countryFr, setCountryFr] = useState("");
  const [countrySp, setCountrySp] = useState("");
  const [countryAr, setCountryAr] = useState("");
  const [countryTur, setCountryTur] = useState("");
  const [countryPers, setCountryPers] = useState("");
  const [countryCh, setCountryCh] = useState("");

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
      fr: nameFr,
      sp: nameSp,
      ar: nameAr,
      tur: nameTur,
      pers: namePers,
      ch: nameCh,
    },
    country: {
      en: countryEn,
      ru: countryRu,
      hy: countryHy,
      fr: countryFr,
      sp: countrySp,
      ar: countryAr,
      tur: countryTur,
      pers: countryPers,
      ch: countryCh,
    },
    address: {
      en: addressEn,
      ru: addressRu,
      hy: addressHy,
      fr: addressFr,
      sp: addressSp,
      ar: addressAr,
      tur: addressTur,
      pers: addressPers,
      ch: addressCh,
    },
    lat,
    lng,
    zoom,
  };

  useEffect(() => {
    (async () => {
      if (!id) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let result = await API.get(route);
        if (result?.[0]) {
          setId(result?.[0]._id);
        } else {
          let currentItem = await API.post(route);
          if (currentItem?.[0]) {
            setId(currentItem?.[0]._id);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        }
      }
      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          {},
          {},
          {},
          []
        );
        setindex(currentItem[0]?.index);
        setlat(currentItem[0]?.lat);
        setLng(currentItem[0]?.lng);
        setZoom(currentItem[0]?.zoom);

        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setNameFr(currentItem[0]?.name?.fr);
        setNameSp(currentItem[0]?.name?.sp);
        setNameAr(currentItem[0]?.name?.ar);
        setNameTur(currentItem[0]?.name?.tur);
        setNamePers(currentItem[0]?.name?.pers);
        setNameCh(currentItem[0]?.name?.ch);

        setAddressEn(currentItem[0]?.address?.en);
        setAddressRu(currentItem[0]?.address?.ru);
        setAddressHy(currentItem[0]?.address?.hy);
        setAddressFr(currentItem[0]?.address?.fr);
        setAddressSp(currentItem[0]?.address?.sp);
        setAddressAr(currentItem[0]?.address?.ar);
        setAddressTur(currentItem[0]?.address?.tur);
        setAddressPers(currentItem[0]?.address?.pers);
        setAddressCh(currentItem[0]?.address?.ch);

        setCountryEn(currentItem[0]?.country?.en);
        setCountryRu(currentItem[0]?.country?.ru);
        setCountryHy(currentItem[0]?.country?.hy);
        setCountryFr(currentItem[0]?.country?.fr);
        setCountrySp(currentItem[0]?.country?.sp);
        setCountryAr(currentItem[0]?.country?.ar);
        setCountryTur(currentItem[0]?.country?.tur);
        setCountryPers(currentItem[0]?.country?.pers);
        setCountryCh(currentItem[0]?.country?.ch);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "en",
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "ru",
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "hy",
          },
          {
            value: nameFr,
            setter: setNameFr,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "fr",
          },
          {
            value: nameSp,
            setter: setNameSp,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "sp",
          },
          {
            value: nameAr,
            setter: setNameAr,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "ar",
          },
          {
            value: nameTur,
            setter: setNameTur,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "tur",
          },
          {
            value: namePers,
            setter: setNamePers,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "pers",
          },
          {
            value: nameCh,
            setter: setNameCh,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "ch",
          },
          // country
          {
            value: countryEn,
            setter: setCountryEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "en",
          },
          {
            value: countryRu,
            setter: setCountryRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "ru",
          },
          {
            value: countryHy,
            setter: setCountryHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "hy",
          },
          {
            value: countryFr,
            setter: setCountryFr,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "fr",
          },
          {
            value: countrySp,
            setter: setCountrySp,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "sp",
          },
          {
            value: countryAr,
            setter: setCountryAr,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "ar",
          },
          {
            value: countryTur,
            setter: setCountryTur,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "tur",
          },
          {
            value: countryPers,
            setter: setCountryPers,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "pers",
          },
          {
            value: countryCh,
            setter: setCountryCh,
            type: "text",
            usageType: "textfield",
            label: config.translate.country[lang],
            placeholder: config.translate.country[lang],
            lang: "ch",
          },
          // address
          {
            value: addressEn,
            setter: setAddressEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "en",
          },
          {
            value: addressRu,
            setter: setAddressRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "ru",
          },
          {
            value: addressHy,
            setter: setAddressHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "hy",
          },
          {
            value: addressFr,
            setter: setAddressFr,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "fr",
          },
          {
            value: addressSp,
            setter: setAddressSp,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "sp",
          },
          {
            value: addressAr,
            setter: setAddressAr,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "ar",
          },
          {
            value: addressTur,
            setter: setAddressTur,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "tur",
          },
          {
            value: addressPers,
            setter: setAddressPers,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "pers",
          },
          {
            value: addressCh,
            setter: setAddressCh,
            type: "text",
            usageType: "textfield",
            label: config.translate.address[lang],
            placeholder: config.translate.address[lang],
            lang: "ch",
          },
          {
            value: lat,
            setter: setlat,
            type: "number",
            usageType: "number",
            label: config.translate.lat[lang],
            placeholder: config.translate.lat[lang],
          },
          {
            value: lng,
            setter: setLng,
            type: "number",
            usageType: "number",
            label: config.translate.lng[lang],
            placeholder: config.translate.lng[lang],
          },
          {
            value: zoom,
            setter: setZoom,
            type: "number",
            usageType: "number",
            label: config.translate.zoom[lang],
            placeholder: config.translate.zoom[lang],
          },
        ]}
      />
    );
}
