import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useLang } from "../../../helpers/language";
import Swal from "sweetalert2";
import { useUser } from "../../../helpers/userContext";

export default function Page() {
	const [isBusy, setBusy] = useState(true);
	const [id, setId] = useState(useParams().id);
	const route = config.api.homepage.intro;
	const { lang } = useLang();

	const { user } = useUser();

	const [titleEn, setTitleEn] = useState("");
	const [titleRu, setTitleRu] = useState("");
	const [titleHy, setTitleHy] = useState("");
	const [titleFr, setTitleFr] = useState("");
	const [titleSp, setTitleSp] = useState("");
	const [titleAr, setTitleAr] = useState("");
	const [titleTur, setTitleTur] = useState("");
	const [titlePers, setTitlePers] = useState("");
	const [titleCh, setTitleCh] = useState("");

	const [descEn, setDescEn] = useState("");
	const [descRu, setDescRu] = useState("");
	const [descHy, setDescHy] = useState("");
	const [descFr, setDescFr] = useState("");
	const [descSp, setDescSp] = useState("");
	const [descAr, setDescAr] = useState("");
	const [descTur, setDescTur] = useState("");
	const [descPers, setDescPers] = useState("");
	const [descCh, setDescCh] = useState("");

	const [buttonTextEn, setButtonTextEn] = useState("");
	const [buttonTextRu, setButtonTextRu] = useState("");
	const [buttonTextHy, setButtonTextHy] = useState("");
	const [buttonTextFr, setButtonTextFr] = useState("");
	const [buttonTextSp, setButtonTextSp] = useState("");
	const [buttonTextAr, setButtonTextAr] = useState("");
	const [buttonTextTur, setButtonTextTur] = useState("");
	const [buttonTextPers, setButtonTextPers] = useState("");
	const [buttonTextCh, setButtonTextCh] = useState("");

	const [image, setImage] = useState(undefined);

	const modelSendToServer = {
		title: {
			en: titleEn,
			ru: titleRu,
			hy: titleHy,
			fr: titleFr,
			sp: titleSp,
			ar: titleAr,
			tur: titleTur,
			pers: titlePers,
			ch: titleCh
		},
		desc: {
			en: descEn,
			ru: descRu,
			hy: descHy,
			fr: descFr,
			sp: descSp,
			ar: descAr,
			tur: descTur,
			pers: descPers,
			ch: descCh
		},
		buttonText: {
			en: buttonTextEn,
			ru: buttonTextRu,
			hy: buttonTextHy,
			fr: buttonTextFr,
			sp: buttonTextSp,
			ar: buttonTextAr,
			tur: buttonTextTur,
			pers: buttonTextPers,
			ch: buttonTextCh
		}
	};

	useEffect(() => {
		(async () => {
			if (!id) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				let result = await API.get(route);
				if (result?.[0]) {
					setId(result?.[0]._id);
				} else {
					let currentItem = await API.post(route);
					if (currentItem?.[0]) {
						setId(currentItem?.[0]._id);
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Something went wrong!"
						});
					}
				}
			}
			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id
					},
					{},
					{},
					{},
					[]
				);

				setTitleEn(currentItem[0]?.title?.en);
				setTitleRu(currentItem[0]?.title?.ru);
				setTitleHy(currentItem[0]?.title?.hy);
				setTitleFr(currentItem[0]?.title?.fr);
				setTitleSp(currentItem[0]?.title?.sp);
				setTitleAr(currentItem[0]?.title?.ar);
				setTitleTur(currentItem[0]?.title?.tur);
				setTitlePers(currentItem[0]?.title?.pers);
				setTitleCh(currentItem[0]?.title?.ch);

				setDescEn(currentItem[0]?.desc?.en);
				setDescRu(currentItem[0]?.desc?.ru);
				setDescHy(currentItem[0]?.desc?.hy);
				setDescFr(currentItem[0]?.desc?.fr);
				setDescSp(currentItem[0]?.desc?.sp);
				setDescAr(currentItem[0]?.desc?.ar);
				setDescTur(currentItem[0]?.desc?.tur);
				setDescPers(currentItem[0]?.desc?.pers);
				setDescCh(currentItem[0]?.desc?.ch);

				setButtonTextEn(currentItem[0]?.buttonText?.en);
				setButtonTextRu(currentItem[0]?.buttonText?.ru);
				setButtonTextHy(currentItem[0]?.buttonText?.hy);
				setButtonTextFr(currentItem[0]?.buttonText?.fr);
				setButtonTextSp(currentItem[0]?.buttonText?.sp);
				setButtonTextAr(currentItem[0]?.buttonText?.ar);
				setButtonTextTur(currentItem[0]?.buttonText?.tur);
				setButtonTextPers(currentItem[0]?.buttonText?.pers);
				setButtonTextCh(currentItem[0]?.buttonText?.ch);

				setImage(undefined);
				currentItem[0].uploads?.map((item) => {
					if (item.destiny === "image") setImage(item);
					return item;
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: titleEn,
						setter: setTitleEn,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "en"
					},
					{
						value: titleRu,
						setter: setTitleRu,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ru"
					},
					{
						value: titleHy,
						setter: setTitleHy,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "hy"
					},
					{
						value: titleFr,
						setter: setTitleFr,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "fr"
					},
					{
						value: titleSp,
						setter: setTitleSp,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "sp"
					},
					{
						value: titleAr,
						setter: setTitleAr,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ar"
					},
					{
						value: titleTur,
						setter: setTitleTur,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "tur"
					},
					{
						value: titlePers,
						setter: setTitlePers,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "pers"
					},
					{
						value: titleCh,
						setter: setTitleCh,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ch"
					},
					{
						value: buttonTextEn,
						setter: setButtonTextEn,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "en"
					},
					{
						value: buttonTextRu,
						setter: setButtonTextRu,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "ru"
					},
					{
						value: buttonTextHy,
						setter: setButtonTextHy,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "hy"
					},
					{
						value: buttonTextFr,
						setter: setButtonTextFr,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "fr"
					},
					{
						value: buttonTextSp,
						setter: setButtonTextSp,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "sp"
					},
					{
						value: buttonTextAr,
						setter: setButtonTextAr,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "ar"
					},
					{
						value: buttonTextTur,
						setter: setButtonTextTur,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "tur"
					},
					{
						value: buttonTextPers,
						setter: setButtonTextPers,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "pers"
					},
					{
						value: buttonTextCh,
						setter: setButtonTextCh,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "ch"
					},
					{
						value: descEn,
						setter: setDescEn,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "en",
						width: 12
					},
					{
						value: descRu,
						setter: setDescRu,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "ru",
						width: 12
					},
					{
						value: descHy,
						setter: setDescHy,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "hy",
						width: 12
					},
					{
						value: descFr,
						setter: setDescFr,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "fr",
						width: 12
					},
					{
						value: descSp,
						setter: setDescSp,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "sp",
						width: 12
					},
					{
						value: descAr,
						setter: setDescAr,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "ar",
						width: 12
					},
					{
						value: descTur,
						setter: setDescTur,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "tur",
						width: 12
					},
					{
						value: descPers,
						setter: setDescPers,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "pers",
						width: 12
					},
					{
						value: descCh,
						setter: setDescCh,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "ch",
						width: 12
					}
				]}
				filesComponent={[
					{
						destiny: "image",
						placeholder: config.translate.image[lang],
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						height: 500,
						width: 500
					}
				]}
			/>
		);
}
