import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import Swal from "sweetalert2";
import { useUser } from "../../helpers/userContext";

export default function Page() {
	const [isBusy, setBusy] = useState(true);
	const [id, setId] = useState(useParams().id);
	const route = config.api.contact;
	const { lang } = useLang();

	const { user } = useUser();

	const [titleEn, setTitleEn] = useState("");
	const [titleRu, setTitleRu] = useState("");
	const [titleHy, setTitleHy] = useState("");
	const [titleFr, setTitleFr] = useState("");
	const [titleSp, setTitleSp] = useState("");
	const [titleAr, setTitleAr] = useState("");
	const [titleTur, setTitleTur] = useState("");
	const [titlePers, setTitlePers] = useState("");
	const [titleCh, setTitleCh] = useState("");
	const [descEn, setDescEn] = useState("");
	const [descRu, setDescRu] = useState("");
	const [descHy, setDescHy] = useState("");
	const [descFr, setDescFr] = useState("");
	const [descSp, setDescSp] = useState("");
	const [descAr, setDescAr] = useState("");
	const [descTur, setDescTur] = useState("");
	const [descPers, setDescPers] = useState("");
	const [descCh, setDescCh] = useState("");
	const [contactForm, setContactForm] = useState("");
	const [forms, setForms] = useState("");
	const [maps, setMaps] = useState("");
	const [image, setImage] = useState(undefined);

	const modelSendToServer = {
		title: {
			en: titleEn,
			ru: titleRu,
			hy: titleHy,
			fr: titleFr,
			sp: titleSp,
			ar: titleAr,
			tur: titleTur,
			pers: titlePers,
			ch: titleCh
		},
		desc: {
			en: descEn,
			ru: descRu,
			hy: descHy,
			fr: descFr,
			sp: descSp,
			ar: descAr,
			tur: descTur,
			pers: descPers,
			ch: descCh
		},
		contact_form: contactForm ? contactForm._id : null,
		maps: []
	};

	const columns = [
		{
			accessorKey: "name",
			header: config.translate.name[lang]
		},
		{
			accessorKey: "href",
			header: "URL"
		}
	];

	useEffect(() => {
		(async () => {
			const rawForms = await API.get(config.api.contactForm, {
				temprorary: { $ne: true }
			});
			setForms(rawForms);
			if (!id) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				let result = await API.get(route);
				if (result?.[0]) {
					setId(result?.[0]._id);
				} else {
					let currentItem = await API.post(route);
					if (currentItem?.[0]) {
						setId(currentItem?.[0]._id);
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Something went wrong!"
						});
					}
				}
			}
			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id
					},
					{},
					{},
					{},
					["contact_form"]
				);

				setTitleEn(currentItem[0]?.title?.en);
				setTitleRu(currentItem[0]?.title?.ru);
				setTitleHy(currentItem[0]?.title?.hy);
				setTitleFr(currentItem[0]?.title?.fr);
				setTitleSp(currentItem[0]?.title?.sp);
				setTitleAr(currentItem[0]?.title?.ar);
				setTitleTur(currentItem[0]?.title?.tur);
				setTitlePers(currentItem[0]?.title?.pers);
				setTitleCh(currentItem[0]?.title?.ch);

				setDescEn(currentItem[0]?.desc?.en);
				setDescRu(currentItem[0]?.desc?.ru);
				setDescHy(currentItem[0]?.desc?.hy);
				setDescFr(currentItem[0]?.desc?.fr);
				setDescSp(currentItem[0]?.desc?.sp);
				setDescAr(currentItem[0]?.desc?.ar);
				setDescTur(currentItem[0]?.desc?.tur);
				setDescPers(currentItem[0]?.desc?.pers);
				setDescCh(currentItem[0]?.desc?.ch);

				setContactForm(currentItem[0]?.contact_form);

				setImage(undefined);
				currentItem[0].uploads?.map((item) => {
					if (item.destiny === "image") setImage(item);
					return item;
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: titleEn,
						setter: setTitleEn,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "en"
					},
					{
						value: titleRu,
						setter: setTitleRu,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ru"
					},
					{
						value: titleHy,
						setter: setTitleHy,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "hy"
					},
					{
						value: titleFr,
						setter: setTitleFr,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "fr"
					},
					{
						value: titleSp,
						setter: setTitleSp,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "sp"
					},
					{
						value: titleAr,
						setter: setTitleAr,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ar"
					},
					{
						value: titleTur,
						setter: setTitleTur,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "tur"
					},
					{
						value: titlePers,
						setter: setTitlePers,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "pers"
					},
					{
						value: titleCh,
						setter: setTitleCh,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ch"
					},
					{
						value: descEn,
						setter: setDescEn,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "en"
					},
					{
						value: descRu,
						setter: setDescRu,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "ru"
					},
					{
						value: descHy,
						setter: setDescHy,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "hy"
					},
					{
						value: descFr,
						setter: setDescFr,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "fr"
					},
					{
						value: descSp,
						setter: setDescSp,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "sp"
					},
					{
						value: descAr,
						setter: setDescAr,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "ar"
					},
					{
						value: descTur,
						setter: setDescTur,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "tur"
					},
					{
						value: descPers,
						setter: setDescPers,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "pers"
					},
					{
						value: descCh,
						setter: setDescCh,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						lang: "ch"
					},
					{
						value: contactForm,
						setter: setContactForm,
						optionListValue: forms,
						type: "optionList",
						usageType: "optionList",
						label: config.translate.contactForm[lang],
						placeholder: config.translate.contactForm[lang]
					}
				]}
				filesComponent={[
					{
						destiny: "image",
						placeholder: config.translate.image[lang],
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						height: 500,
						width: 500
					}
				]}
			/>
		);
}
