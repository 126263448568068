import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NProgress from "nprogress";
import * as API from "../helpers/api";
import config from "../config";
import { useUser } from "../helpers/userContext";
import { useConnectedUser } from "../helpers/connectedUserContext";
import { useLang } from "../helpers/language";
import { DELETE } from "../helpers/CRUD";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_GlobalFilterTextInput,
	MRT_ToggleFiltersButton
} from "mantine-react-table";
import { CloseIcon, useMantineTheme } from "@mantine/core";
import { Button, Flex, Menu, } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import ErrorPage from "../helpers/ErrorPage";

export function ListPage({
	route,
	query,
	sorting,
	uploadType,
	limit = 10,
	skip = 0,
	populate,
	columns,
	editPage,
	addPage,
	renderDetailPanel = null,
	pageTitle,
	noAdd,
	showSkeletons = true,
	enableEditing = false,
	enableColumnFilterModes = false,
	enableColumnOrdering = false,
	enableFacetedValues = false,
	enableGrouping = false,
	enablePinning = false,
	enableRowSelection = false,
	enableRowActions = true,
	enableStickyHeader = false,
	enableStickyFooter = false,
	mantineSearchTextInputProps = null,
	enableColumnResizing = false,
	mantinePaginationProps = {
		radius: "sm",
		size: "sm"
	},
	paginationDisplayMode = "pages",
	positionToolbarAlertBanner = "bottom",
	enableClickToCopy = false,
	initialState = {
		sorting: [],
		filters: [],
		grouping: [],
		pinnedColumns: [],
		hiddenColumns: [],
		selectedRows: [],
		searchQuery: "",
		showColumnFilters: false,
		showGlobalFilter: true
	}
}) {
	let history = useHistory();
	const [isBusy, setBusy] = useState(true);
	const { user } = useUser();
	const [apiData, setApiData] = useState([]);
	const [firstRender, setfirstRender] = useState(false);
	const location = useLocation();
	const { lang } = useLang();
	const [buttonLoading, setButtonLoading] = useState(false);
	const { colorScheme } = useMantineTheme();
	const { connectedUsers } = useConnectedUser();
	//data and fetching state
	const [isError, setIsError] = useState(false);
	let editedPageTitle = `${pageTitle}`;
	let formatedLocation = location.search
		.replace("?", "")
		.split("&")
		.reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
	const [CollectionCount, setCollectionCount] = useState(0);
	const [pagination, setPagination] = useState({
		pageIndex: formatedLocation.pageIndex ? Number(formatedLocation.pageIndex) : 0,
		pageSize: Number(formatedLocation.pageSize ? formatedLocation.pageSize : 10) //customize the default page size
	});

	const addNew = async () => {
		setButtonLoading(true);
		let result;
		try {
			result = await API.post(route, { temprorary: true });
			history.push(`/${route}/${result._id}?temprorary=true`);
			setButtonLoading(false);
		} catch (error) {
			console.error(error);
			setButtonLoading(false);
		}
	};

	const table = useMantineReactTable({
		columns: columns ? columns : [],
		data: apiData ? apiData : [],
		enableColumnFilterModes,
		enableColumnOrdering,
		enableFacetedValues,
		enableGrouping,
		enablePinning,
		enableRowSelection,
		enableRowActions,
		initialState: {
			...initialState
		},
		paginationDisplayMode,
		positionToolbarAlertBanner,
		mantinePaginationProps,
		enableClickToCopy,
		mantineSearchTextInputProps,
		renderDetailPanel,
		enableColumnResizing,
		enableStickyHeader,
		enableStickyFooter,
		enableEditing,
		manualPagination: true,
		showSkeletons,
		mantineToolbarAlertBannerProps: isError
			? { color: "red", children: "Error loading data" }
			: undefined,
		rowCount: CollectionCount,
		onPaginationChange: (props) => {
			let localPagination = {
				pageIndex: formatedLocation.pageIndex ? Number(formatedLocation.pageIndex) : 0,
				pageSize: Number(formatedLocation.pageSize ? formatedLocation.pageSize : 10) //customize the default page size
			};
			if (props(localPagination).pageIndex !== 0) {
				setPagination(props(localPagination));
			} else if (props(localPagination).pageIndex === 0 && localPagination.pageIndex === 0) {
				setPagination({ ...localPagination, pageIndex: 0 });
			} else if (props(localPagination).pageIndex === 0 && localPagination.pageIndex === 1) {
				if (!firstRender) {
					setPagination({ ...localPagination, pageIndex: 1 });
				} else {
					setPagination({ ...localPagination, pageIndex: 0 });
				}
			} else {
				setPagination({ ...localPagination });
			}
		},
		state: {
			pagination
		},
		// onSortingChange: setSorting,
		renderRowActionMenuItems: ({ row }) => {
			return (
				<>
					<Menu.Item>
						<Link to={`/${route}${editPage ? editPage : ""}/${row?.original?._id}`}>
							<Button
								leftSection={
									<IconEdit size={14} color={colorScheme === "dark" ? "black" : "white"} />
								}
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between"
								}}
								variant="light"
							>
								{config.translate.edit[lang]}
							</Button>
						</Link>
					</Menu.Item>
					{user?.role === "admin" ? (
						<Menu.Item>
							<Button
								color="red"
								leftSection={
									<CloseIcon size={14} color={colorScheme === "dark" ? "black" : "white"} />
								}
								variant="light"
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between"
								}}
								onClick={() => {
									DELETE({ route, id: row?.original?._id, history, lang, setBusy, isBusy });
								}}
							>
								{config.translate.remove[lang]}
							</Button>
						</Menu.Item>
					) : null}
				</>
			);
		},
		renderTopToolbar: ({ table }) => {
			return (
				<Flex p="md" justify="space-between">
					<Flex gap="xs">
						{/* import MRT sub-components */}
						<MRT_GlobalFilterTextInput table={table} />
						<MRT_ToggleFiltersButton table={table} />
					</Flex>
					<Flex sx={{ gap: "8px" }}>
						{noAdd ? null : (
							<Button color="green" loading={buttonLoading} onClick={addNew} variant="filled">
								{config.translate.add[lang]}
							</Button>
						)}
					</Flex>
				</Flex>
			);
		}
	});

	useEffect(() => {
		setfirstRender(true);
		const newUrl = `${location.pathname}?pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`;
		history.push(newUrl);
		NProgress.start();
		(async () => {
			let rawData;
			if (user.role !== "editor") {
				try {
					rawData = await API.get(
						route,
						query ? { temprorary: { $ne: true }, ...query } : { temprorary: { $ne: true } },
						sorting ? sorting : "desc",
						uploadType ? uploadType : "",
						pagination.pageSize ? pagination.pageSize : limit ? limit : "",
						populate ? populate : "",
						pagination.pageIndex * pagination.pageSize,
						true
					);
				} catch (error) {
					setIsError(true);
					console.error(error);
					return;
				}
			} else {
				try {
					rawData = await API.get(
						route,
						query
							? { createdByUser: user._id, temprorary: { $ne: true }, ...query }
							: { createdByUser: user._id, temprorary: { $ne: true } },
						sorting ? sorting : "desc",
						uploadType ? uploadType : "",
						pagination.pageSize ? pagination.pageSize : limit ? limit : "",
						populate ? populate : "",
						pagination.pageIndex * pagination.pageSize,
						true
					);
				} catch (error) {
					setIsError(true);
					console.error(error);
					return;
				}
			}
			setApiData(rawData.items);
			setCollectionCount(rawData.count);
			// setBusy(false);
			NProgress.done();
		})();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const newUrl = `${location.pathname}?pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`;
		history.push(newUrl);
		NProgress.start();
		(async () => {
			let rawData;
			if (user.role !== "editor") {
				try {
					rawData = await API.get(
						route,
						query ? { temprorary: { $ne: true }, ...query } : { temprorary: { $ne: true } },
						sorting ? sorting : "desc",
						uploadType ? uploadType : "",
						pagination.pageSize ? pagination.pageSize : limit ? limit : "",
						populate ? populate : "",
						pagination.pageIndex * pagination.pageSize,
						true
					);
				} catch (error) {
					setIsError(true);
					console.error(error);
					return;
				}
			} else {
				try {
					rawData = await API.get(
						route,
						query
							? {
									createdByUser: user._id,
									temprorary: { $ne: true },
									...query
							  }
							: { createdByUser: user._id, temprorary: { $ne: true } },
						sorting ? sorting : "desc",
						uploadType ? uploadType : "",
						pagination.pageSize ? pagination.pageSize : limit ? limit : "",
						populate ? populate : "",
						pagination.pageIndex * pagination.pageSize,
						true
					);
				} catch (error) {
					setIsError(true);
					console.error(error);
					return;
				}
			}
			setApiData(rawData.items);
			setCollectionCount(rawData.count);
			// setBusy(false);
			NProgress.done();
		})();
		// eslint-disable-next-line
	}, [location.search, isBusy]);

	useEffect(() => {
		const newUrl = `${location.pathname}?pageIndex=${pagination.pageIndex}&pageSize=${pagination.pageSize}`;
		history.push(newUrl);
		// eslint-disable-next-line
	}, [pagination?.pageIndex, pagination?.pageSize]);

	if (NProgress.done()) {
		return <MantineReactTable table={table} />;
	} else return <ErrorPage />;
}
