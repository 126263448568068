import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useLang } from "../../../helpers/language";
import Swal from "sweetalert2";
import history from "../../../helpers/history";
import { useUser } from "../../../helpers/userContext";

export default function Page() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.homepage.steps;
  const { lang } = useLang();
  const { user } = useUser();

  const [index, setindex] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [titleRu, setTitleRu] = useState("");
  const [titleHy, setTitleHy] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [titleSp, setTitleSp] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [titleTur, setTitleTur] = useState("");
  const [titlePers, setTitlePers] = useState("");
  const [titleCh, setTitleCh] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descRu, setDescRu] = useState("");
  const [descHy, setDescHy] = useState("");
  const [descFr, setDescFr] = useState("");
  const [descSp, setDescSp] = useState("");
  const [descAr, setDescAr] = useState("");
  const [descTur, setDescTur] = useState("");
  const [descPers, setDescPers] = useState("");
  const [descCh, setDescCh] = useState("");

  const [image, setImage] = useState(undefined);

  const modelSendToServer = {
    index,
    title: {
      en: titleEn,
      ru: titleRu,
      hy: titleHy,
      fr: titleFr,
      sp: titleSp,
      ar: titleAr,
      tur: titleTur,
      pers: titlePers,
      ch: titleCh,
    },
    desc: {
      en: descEn,
      ru: descRu,
      hy: descHy,
      fr: descFr,
      sp: descSp,
      ar: descAr,
      tur: descTur,
      pers: descPers,
      ch: descCh,
    },
  };

  useEffect(() => {
    (async () => {
      if (id !== "add") {
        let currentItem = await API.get(
          route,
          {
            _id: id,
          },
          {},
          {},
          {},
          []
        );
        if (currentItem.length === 0) {
          Swal.fire({
            title: "Error!",
            text: "Item not found",
            icon: "error",
            confirmButtonText: "Cool",
          });
          history.push(`/${route}`);
          return;
        }
        setindex(currentItem[0]?.index);

        setTitleEn(currentItem[0]?.title?.en);
        setTitleRu(currentItem[0]?.title?.ru);
        setTitleHy(currentItem[0]?.title?.hy);
        setTitleFr(currentItem[0]?.title?.fr);
        setTitleSp(currentItem[0]?.title?.sp);
        setTitleAr(currentItem[0]?.title?.ar);
        setTitleTur(currentItem[0]?.title?.tur);
        setTitlePers(currentItem[0]?.title?.pers);
        setTitleCh(currentItem[0]?.title?.ch);

        setDescEn(currentItem[0]?.desc?.en);
        setDescRu(currentItem[0]?.desc?.ru);
        setDescHy(currentItem[0]?.desc?.hy);
        setDescFr(currentItem[0]?.desc?.fr);
        setDescSp(currentItem[0]?.desc?.sp);
        setDescAr(currentItem[0]?.desc?.ar);
        setDescTur(currentItem[0]?.desc?.tur);
        setDescPers(currentItem[0]?.desc?.pers);
        setDescCh(currentItem[0]?.desc?.ch);

        setImage(undefined);
        currentItem[0].uploads?.map((item) => {
          if (item.destiny === "image") setImage(item);
          return item;
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setindex,
						type: "number",
						usageType: "number",
						label: config.translate.index[lang],
						placeholder: config.translate.index[lang]
					},
					{
						value: titleEn,
						setter: setTitleEn,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "en"
					},
					{
						value: titleRu,
						setter: setTitleRu,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ru"
					},
					{
						value: titleHy,
						setter: setTitleHy,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "hy"
					},
					{
						value: titleFr,
						setter: setTitleFr,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "fr"
					},
					{
						value: titleSp,
						setter: setTitleSp,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "sp"
					},
					{
						value: titleAr,
						setter: setTitleAr,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ar"
					},
					{
						value: titleTur,
						setter: setTitleTur,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "tur"
					},
					{
						value: titlePers,
						setter: setTitlePers,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "pers"
					},
					{
						value: titleCh,
						setter: setTitleCh,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ch"
					},
					{
						value: descEn,
						setter: setDescEn,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "en"
					},
					{
						value: descRu,
						setter: setDescRu,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "ru"
					},
					{
						value: descHy,
						setter: setDescHy,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "hy"
					},
					{
						value: descFr,
						setter: setDescFr,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "fr"
					},
					{
						value: descSp,
						setter: setDescSp,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "sp"
					},
					{
						value: descAr,
						setter: setDescAr,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "ar"
					},
					{
						value: descTur,
						setter: setDescTur,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "tur"
					},
					{
						value: descPers,
						setter: setDescPers,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "pers"
					},
					{
						value: descCh,
						setter: setDescCh,
						type: "text",
						usageType: "textarea",
						label: config.translate.description[lang],
						placeholder: config.translate.name[lang],
						width: 12,
						lang: "ch"
					}
				]}
				filesComponent={[
					{
						destiny: "image",
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						adminMode: user.role !== "editor",
						height: 500,
						width: 500
					}
				]}
			/>
		);
}
