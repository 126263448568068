import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import Swal from "sweetalert2";

export default function Page() {
	const [isBusy, setBusy] = useState(true);
	const [id, setId] = useState(useParams().id);

	const route = config.api.termsAndConditions;
	const { lang } = useLang();

	const [descEn, setDescEn] = useState("");
	const [descRu, setDescRu] = useState("");
	const [descHy, setDescHy] = useState("");
	const [descFr, setDescFr] = useState("");
	const [descSp, setDescSp] = useState("");
	const [descAr, setDescAr] = useState("");
	const [descTur, setDescTur] = useState("");
	const [descPers, setDescPers] = useState("");
	const [descCh, setDescCh] = useState("");

	const modelSendToServer = {
		desc: {
			en: descEn,
			ru: descRu,
			hy: descHy,
			fr: descFr,
			sp: descSp,
			ar: descAr,
			tur: descTur,
			pers: descPers,
			ch: descCh
		}
	};

	useEffect(() => {
		(async () => {
			if (!id) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				let result = await API.get(route);
				if (result?.[0]) {
					setId(result?.[0]._id);
				} else {
					let currentItem = await API.post(route);
					if (currentItem?.[0]) {
						setId(currentItem?.[0]._id);
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Something went wrong!"
						});
					}
				}
			}
			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id
					},
					{},
					{},
					{},
					[]
				);
				setDescEn(currentItem?.[0]?.desc?.en);
				setDescRu(currentItem?.[0]?.desc?.ru);
				setDescHy(currentItem?.[0]?.desc?.hy);
				setDescFr(currentItem?.[0]?.desc?.fr);
				setDescSp(currentItem?.[0]?.desc?.sp);
				setDescAr(currentItem?.[0]?.desc?.ar);
				setDescTur(currentItem?.[0]?.desc?.tur);
				setDescPers(currentItem?.[0]?.desc?.pers);
				setDescCh(currentItem?.[0]?.desc?.ch);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: descEn,
						setter: setDescEn,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "en"
					},
					{
						value: descRu,
						setter: setDescRu,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "ru"
					},
					{
						value: descHy,
						setter: setDescHy,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "hy"
					},
					{
						value: descFr,
						setter: setDescFr,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "fr"
					},
					{
						value: descSp,
						setter: setDescSp,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "sp"
					},
					{
						value: descAr,
						setter: setDescAr,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "ar"
					},
					{
						value: descTur,
						setter: setDescTur,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "tur"
					},
					{
						value: descPers,
						setter: setDescPers,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "pers"
					},
					{
						value: descCh,
						setter: setDescCh,
						type: "text",
						usageType: "textarea",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: "ch"
					}
				]}
			/>
		);
}
