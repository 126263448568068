import React, { useState, useEffect, Suspense } from "react";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "mantine-react-table/styles.css"; //import MRT styles
import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/tiptap/styles.css";

// Helpers
import history from "./helpers/history";
import { cookies, setCookies } from "./helpers/cookies";
import { isLoggedIn } from "./helpers/auth";
import { UserContext } from "./helpers/userContext";
import { ConnectedUserContext } from "./helpers/connectedUserContext";
import API from "./helpers/api";
import { useStateWithLocalStorage } from "./helpers/storage";
import config from "./config";
import Loading from "./components/modules/loading.jsx";
import { io } from "socket.io-client";
import { Language } from "./helpers/language";

// global components
import Login from "./components/login";
import Main from "./components/main/main";

//Routing
import { Router, Route, Switch } from "react-router-dom";

// Components
import Home from "./components/home";
import HomepageIntro from "./components/homepage/intro/page.jsx";
import HomepageSteps from "./components/homepage/steps/page.jsx";
import HomepageStepsList from "./components/homepage/steps/list.jsx";
import HomepageWhywe from "./components/homepage/whywe/page.jsx";
import HomepageWhyweList from "./components/homepage/whywe/list.jsx";
import Page404 from "./components/Page404";
import AboutUs from "./components/aboutus/page.jsx";
import Blog from "./components/blog/page.jsx";
import BolgList from "./components/blog/list.jsx";
import Client from "./components/client/page.jsx";
import ClientList from "./components/client/list.jsx";
import Contact from "./components/contact/page.jsx";
import ContactPart from "./components/contact-part/page.jsx";
import ContactPartList from "./components/contact-part/list.jsx";
import ContactSocial from "./components/contact-social/page.jsx";
import ContactSocialList from "./components/contact-social/list.jsx";
import ContactForm from "./components/contact-form/page.jsx";
import ContactFormList from "./components/contact-form/list.jsx";
import Map from "./components/map/page.jsx";
import MapList from "./components/map/list.jsx";
import PrivacyPolicy from "./components/privacyPolicy/page.jsx";
import Service from "./components/service/page.jsx";
import ServiceList from "./components/service/list.jsx";
import TermsAndConditions from "./components/termsAndConditions/page.jsx";
import User from "./components/user/page.jsx";
import UserList from "./components/user/list.jsx";
import Valute from "./components/valute/page.jsx";
import ValuteList from "./components/valute/list.jsx";
import Order from "./components/order/page.jsx";
import OrderList from "./components/order/list.jsx";

function RouterComponent({ user }) {
	// window.scrollTo(0, 0);
	if (user) {
		return (
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/home" component={Home} />

					<Route exact path="/homepage-intro/:id" component={HomepageIntro} />
					<Route exact path="/homepage-intro" component={HomepageIntro} />
					<Route exact path="/homepage-steps/:id" component={HomepageSteps} />
					<Route exact path="/homepage-steps" component={HomepageStepsList} />
					<Route exact path="/homepage-whywe/:id" component={HomepageWhywe} />
					<Route exact path="/homepage-whywe" component={HomepageWhyweList} />

					<Route exact path="/aboutus/:id" component={AboutUs} />
					<Route exact path="/aboutus" component={AboutUs} />
					<Route exact path="/blog/:id" component={Blog} />
					<Route exact path="/blog" component={BolgList} />
					<Route exact path="/client/:id" component={Client} />
					<Route exact path="/client" component={ClientList} />
					<Route exact path="/contact/:id" component={Contact} />
					<Route exact path="/contact" component={Contact} />
					<Route exact path="/contact-part/:id" component={ContactPart} />
					<Route exact path="/contact-part" component={ContactPartList} />
					<Route exact path="/contact-social/:id" component={ContactSocial} />
					<Route exact path="/contact-social" component={ContactSocialList} />
					<Route exact path="/contact-form/:id" component={ContactForm} />
					<Route exact path="/contact-form" component={ContactFormList} />
					<Route exact path="/map/:id" component={Map} />
					<Route exact path="/map" component={MapList} />
					<Route exact path="/privacy-policy/:id" component={PrivacyPolicy} />
					<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					<Route exact path="/service/:id" component={Service} />
					<Route exact path="/service" component={ServiceList} />
					<Route exact path="/order/:id" component={Order} />
					<Route exact path="/order" component={OrderList} />
					<Route exact path="/terms-and-conditions/:id" component={TermsAndConditions} />
					<Route exact path="/terms-and-conditions" component={TermsAndConditions} />
					<Route exact path="/user/:id" component={User} />
					<Route exact path="/user" component={UserList} />
					<Route exact path="/valute/:id" component={Valute} />
					<Route exact path="/valute" component={ValuteList} />
					<Route exact path="/user/:id" component={User} />
					<Route exact path="/user" component={UserList} />
					<Route path="*" component={Page404} />
				</Switch>
			</Suspense>
		);
	} else {
		return (
			<Suspense>
				<Route path="*" component={Page404} />
			</Suspense>
		);
	}
}

const theme = createTheme(
	{
		shadows: {
			md: "1px 1px 3px rgba(0, 0, 0, .25)",
			xl: "5px 5px 3px rgba(0, 0, 0, .25)"
		},
		primaryColor: "blue",
		fontFamily: "Roboto, sans-serif"
	},
	{
		links: {
			textDecoration: "none",
			color: "inherit"
		}
	}
);

function App() {
	let [loggedIn, setLoggedIn] = useState(Boolean);
	let [user, setUser] = useState(Boolean);
	// eslint-disable-next-line
	let [connectedUsers, setconnectedUsers] = useState([]);
	let [notifications, setnotifications] = useState([]);
	let [socket, setSocket] = useState({});
	let DissconnectSocket = () => {
		socket.disconnect();
		setconnectedUsers([...connectedUsers]);
	};
	let [lang, setLang] = useStateWithLocalStorage("language");
	const [colorScheme, setColorScheme] = useStateWithLocalStorage("mantine-color-scheme-value");

	async function logout() {
		await setCookies("");
		await setconnectedUsers("");
		DissconnectSocket();
		await setLoggedIn(false);
	}

	function ConnectSocket(userFromServer) {
		const socket = io(`${config.api.API_URL}`, {
			transports: ["websocket"]
		});
		setSocket(socket);
		let user;
		if (userFromServer) user = JSON.parse(JSON.stringify(userFromServer));
		socket.on("connect_error", () => {
			setTimeout(() => {
				socket.connect();
			}, 1000);
		});
		socket.on("connect", () => {
			user.socket_id = socket.id;
			socket.emit("registerUser", user);
			setUser(user);
		});
		socket.on("disconnect", () => {
			setconnectedUsers(connectedUsers);
		});
		setUser(user);

		socket.on("GetMyNotifications", (notifications) => {
			setnotifications(JSON.parse(notifications));
			showNotification();
		});

		user.role === "admin" &&
			socket.on("createNotification", (notifications) => {
				let Notifications = JSON.parse(notifications);
				let myCurrentNotifications = Notifications.filter((notification) => {
					if (user.role === "admin") {
						return notification.user?._id !== user._id;
					}
				});
				setnotifications(myCurrentNotifications);
			});

		socket.on("updateNotification", (notifications) => {
			let Notifications = JSON.parse(notifications);
			let myCurrentNotifications = Notifications.filter((notification) => {
				if (user.role === "admin") {
					// return notification.admin?._id === user._id;
				} else {
					return notification.user?._id === user._id;
				}
			});
			setnotifications(myCurrentNotifications);
		});
	}

	function login(username, password) {
		if (username && password) {
			return API.post(config.authentication.login, {
				username,
				password
			})
				.then((res) => {
					if (res.status === 200) {
						setCookies(res.data.token);
						setLoggedIn(true);
						ConnectSocket(res.data.user);
					}
				})
				.catch((error) => {
					console.error(error);
					setLoggedIn(false);
				});
		} else {
			setLoggedIn(false);
			console.log("There is no user with provided username & password");
		}
	}

	async function checkToken() {
		let token = await cookies.get(config.authentication.tokenAddress);
		if (token) {
			API.post(config.authentication.verify, {
				token
			})
				.then((res) => {
					if (res.status === 200) {
						setCookies(token);
						ConnectSocket(res?.data?.user);
						setLoggedIn(true);
					} else {
						setLoggedIn(false);
					}
				})
				.catch((res) => {
					console.error(res);
					console.error(`Please don't edit cookies manually`);
					cookies.remove("authorization");
				});
		} else {
			setLoggedIn(false);
			console.log(
				"You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running"
			);
		}
	}

	const showNotification = () => {
		// create a new notification
		const notification = new Notification("JavaScript Notification API", {
			body: "This is a JavaScript Notification API demo",
			icon: "/assets/black.png",
			vibrate: true
		});

		// close the notification after 10 seconds
		setTimeout(() => {
			notification.close();
		}, 10 * 1000);

		// history to a URL
		notification.addEventListener("click", () => {
			window.open("https://www.javascripttutorial.net/web-apis/javascript-notification/", "_blank");
		});
	};
	let granted = false;
	// show an error message
	const showError = () => {
		const error = document.querySelector(".error");
		if (error) {
			error.style.display = "block";
			error.textContent = "You blocked the notifications";
		}
	};
	useEffect(() => {
		(async () => {
			if (lang === "" || lang === null) {
				setLang("en");
			}
			if (colorScheme === "" || colorScheme === null) {
				setColorScheme("light");
			}
			await checkToken();
			if (Notification.permission === "granted") {
				granted = true;
			} else if (Notification.permission !== "denied") {
				let permission = await Notification.requestPermission();
				granted = permission === "granted" ? true : false;
			}
			granted ? showNotification() : showError();
			// eslint-disable-next-line
		})();
	}, []);

	if (loggedIn && user) {
		return (
			<Language.Provider value={{ lang, setLang, colorScheme, setColorScheme }}>
				<isLoggedIn.Provider value={{ login, logout, loggedIn }}>
					<UserContext.Provider value={{ user }}>
						<ConnectedUserContext.Provider
							value={{
								connectedUsers,
								socket,
								notifications,
								setnotifications
							}}
						>
							<MantineProvider theme={theme} defaultColorScheme="dark">
								<Notifications />
								<Router history={history}>
									<Main component={RouterComponent} user={user} />
								</Router>
							</MantineProvider>
						</ConnectedUserContext.Provider>
					</UserContext.Provider>
				</isLoggedIn.Provider>
			</Language.Provider>
		);
	} else {
		return (
			<Language.Provider value={{ lang, setLang }}>
				<isLoggedIn.Provider value={{ login, logout, loggedIn }}>
					<MantineProvider theme={theme} defaultColorScheme="dark">
						<Router history={history}>
							<Route component={Login} />
						</Router>
					</MantineProvider>
				</isLoggedIn.Provider>
			</Language.Provider>
		);
	}
}

export default App;
