import React, { useEffect, useState } from "react";
import { Badge, Breadcrumbs } from "@mantine/core";
import { useLocation, Link } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { useMobile } from "../../helpers/is-mobile";

export function BreadcrumbsComponent() {
	let location = useLocation();
	let [isBusy, setIsBusy] = useState(true);
	let [crumbs, setCrumbs] = useState([]);
	const { lang } = useLang();
	const isMobile = useMobile();

	useEffect(() => {
		(async () => {
			let crumbs;
			if (location.pathname === "/") {
				crumbs = ["home"];
			} else {
				crumbs = location.pathname.split("/").slice(1);
			}
			crumbs = crumbs.filter((crumb) => crumb !== "");
			let result;
			if (crumbs.length > 1) {
				if (crumbs[0] === "users" || crumbs[0] === "user") {
					crumbs[0] = "users";
					if (
						crumbs[1] === "interview1" ||
						crumbs[1] === "interview2" ||
						crumbs[1] === "interview3" ||
						crumbs[1] === "interview4" ||
						crumbs[1] === "archive" ||
						crumbs[1] === "contract" ||
						crumbs[1] === "all" ||
						crumbs[1] === "working" ||
						crumbs[1] === "fired" ||
						crumbs[1] === "maternityLeave"
					) {
						setCrumbs(crumbs);
					} else {
						try {
							result = await API.get(
								config.api.user,
								{
									_id: crumbs[1]
								},
								null,
								null,
								1
							);
							if (result?.length > 0) {
								crumbs[crumbs.length - 1] = result[0].name
									? result[0].name
									: result[0].title
									? result[0].title
									: result[0].index
									? result[0].index
									: result[0].ID
									? result[0].ID
									: result[0]._id;
								setCrumbs(crumbs);
							} else {
								setCrumbs(crumbs);
							}
						} catch (error) {
							console.log(error);
						}
					}
				} else {
					if (crumbs[0].includes("-")) {
						crumbs[0] = crumbs[0].replace(/-./g, (x) => x.toUpperCase()[1]);
					}
					try {
						result = await API.get(
							config.api[crumbs[0]],
							{
								_id: crumbs[1]
							},
							null,
							null,
							1
						);
						if (result?.length > 0) {
							const { name, title, index, ID, _id } = result[0];
							crumbs[crumbs.length - 1] = name
								? name?.[lang]
									? name.lang
									: title
									? title?.[lang]
										? title[lang]
										: title
									: index
									? index
									: ID
									? ID
									: _id
								: crumbs[1];
							setCrumbs(crumbs);
						} else {
							setCrumbs(crumbs);
						}
					} catch (error) {
						console.log(error);
					}
				}
			} else {
				setCrumbs(crumbs);
			}
			setIsBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, location.pathname]);

	if (isBusy) {
		return <Loading />;
	} else
		return (
			<Breadcrumbs
				style={{
					maxWidth: isMobile ? "100px" : "100%",
					overflow: "hidden",
					margin: "0 20px",
					flex: "1 1 240px",
					display: "flex",
					justifyContent: "center"
				}}
			>
				{crumbs.map((crumb, index) => {
					const linkPath = `/${crumbs.slice(0, index + 1).join("/")}`;
					const crumbText =
						typeof crumb === "object" ? crumb[lang] : config.translate?.[crumb]?.[lang] || crumb;

					return (
						<Link key={index} to={linkPath}>
							<Badge variant="gradient" size="md">
								{crumbText}
							</Badge>
						</Link>
					);
				})}
			</Breadcrumbs>
		);
}
