const config = {
	api: {
		homepage: {
			intro: "homepage-intro",
			steps: "homepage-steps",
			whywe: "homepage-whywe"
		},
		aboutus: "aboutus",
		blog: "blog",
		client: "client",
		contact: "contact",
		contactPart: "contact-part",
		contactSocial: "contact-social",
		contactForm: "contact-form",
		map: "map",
		notification: "notification",
		order: "order",
		privacyPolicy: "privacy-policy",
		user: "user",
		service: "service",
		termsAndConditions: "terms-and-conditions",
		valute: "valute",
		upload: "upload",

		API_URL: process.env.REACT_APP_API_URL,
		API_Version: "v3"
	},
	authentication: {
		login: "/login/private",
		verify: "/verify",
		tokenAddress: "private-token"
	},
	translate: {
		ID: {
			en: "ID",
			ru: "ID",
			hy: "ID",
			fr: "ID",
			sp: "ID",
			ar: "ID",
			tur: "ID",
			pers: "ID",
			ch: "ID"
		},
		nothingFound: {
			en: "Nothing found",
			ru: "Ничего не найдено",
			hy: "Ոչինչ չի գտնվել",
			fr: "Rien trouvé",
			sp: "Nada encontrado",
			ar: "لم يتم العثور على شيء",
			tur: "Hiçbir şey bulunamadı",
			pers: "چیزی یافت نشد",
			ch: "未找到任何内容"
		},
		
		country: {
			en: "Country",
			ru: "Страна",
			hy: "Երկիր",
			fr: "Pays",
			sp: "País",
			ar: "بلد",
			tur: "Ülke",
			pers: "کشور",
			ch: "国家"
		},
		address: {
			en: "Address",
			ru: "Адрес",
			hy: "Հասցե",
			fr: "Adresse",
			sp: "Dirección",
			ar: "عنوان",
			tur: "Adres",
			pers: "آدرس",
			ch: "地址"
		},
		dateInterval: {
			en: "Date Interval",
			ru: "Интервал дат",
			hy: "Ամսական միջակայք",
			fr: "Intervalle de dates",
			sp: "Intervalo de fechas",
			ar: "فترة التاريخ",
			tur: "Tarih Aralığı",
			pers: "بازه زمانی",
			ch: "日期间隔"
		},

		itemImages: {
			en: "Item Images",
			ru: "Фото товара",
			hy: "Ապրանքի նկարներ",
			fr: "Images de l'article",
			sp: "Imágenes del artículo",
			ar: "صور العنصر",
			tur: "Ürün Resimleri",
			pers: "تصاویر محصول",
			ch: "项目图片"
		},
		itemVideo: {
			en: "Item Video",
			ru: "Видео товара",
			hy: "Ապրանքի տեսանյութ",
			fr: "Vidéo de l'article",
			sp: "Video del artículo",
			ar: "فيديو العنصر",
			tur: "Ürün Videosu",
			pers: "ویدیو محصول",
			ch: "项目视频"
		},
		packageVideo: {
			en: "Package Video",
			ru: "Видео пакета",
			hy: "Փաթեթի տեսանյութ",
			fr: "Vidéo du package",
			sp: "Video del paquete",
			ar: "فيديو الحزمة",
			tur: "Paket Videosu",
			pers: "ویدیو بسته بندی",
			ch: "包装视频"
		},
		packageImages: {
			en: "Package Images",
			ru: "Фото пакета",
			hy: "Փաթեթի նկարներ",
			fr: "Images du package",
			sp: "Imágenes del paquete",
			ar: "صور الحزمة",
			tur: "Paket Resimleri",
			pers: "تصاویر بسته بندی",
			ch: "包装图片"
		},
		registeredDate: {
			en: "Registered Date",
			ru: "Дата регистрации",
			hy: "Գրանցման ամսաթիվ",
			fr: "Date d'inscription",
			sp: "Fecha de registro",
			ar: "تاريخ التسجيل",
			tur: "Kayıt Tarihi",
			pers: "تاریخ ثبت نام",
			ch: "注册日期"
		},
		paymentType: {
			en: "Payment Type",
			ru: "Тип оплаты",
			hy: "Վճարման տեսակ",
			fr: "Type de paiement",
			sp: "Tipo de pago",
			ar: "نوع الدفع",
			tur: "Ödeme Türü",
			pers: "نوع پرداخت",
			ch: "支付类型"
		},
		itemVideo: {
			en: "Item Video",
			ru: "Видео товара",
			hy: "Ապրանքի տեսանյութ",
			fr: "Vidéo de l'article",
			sp: "Video del artículo",
			ar: "فيديو العنصر",
			tur: "Ürün Videosu",
			pers: "ویدیو محصول",
			ch: "项目视频"
		},
		itemPhoto: {
			en: "Item Photo",
			ru: "Фото товара",
			hy: "Ապրանքի նկար",
			fr: "Photo de l'article",
			sp: "Foto del artículo",
			ar: "صورة العنصر",
			tur: "Ürün Fotoğrafı",
			pers: "عکس محصول",
			ch: "项目照片"
		},
		contactForm: {
			en: "Contact Form",
			ru: "Контактная форма",
			hy: "Կապի ձև",
			fr: "Formulaire de contact",
			sp: "Formulario de contacto",
			ar: "نموذج الاتصال",
			tur: "İletişim Formu",
			pers: "فرم تماس",
			ch: "联系表"
		},
		itemUrl: {
			en: "Item URL",
			ru: "URL товара",
			hy: "Ապրանքի URL",
			fr: "URL de l'article",
			sp: "URL del artículo",
			ar: "عنوان العنصر",
			tur: "Ürün URL'si",
			pers: "آدرس محصول",
			ch: "项目网址"
		},
		packageUrl: {
			en: "Package URL",
			ru: "URL пакета",
			hy: "Փաթեթի URL",
			fr: "URL du package",
			sp: "URL del paquete",
			ar: "عنوان الحزمة",
			tur: "Paket URL'si",
			pers: "آدرس بسته بندی",
			ch: "包装网址"
		},
		packageDesc: {
			en: "Package Description",
			ru: "Описание пакета",
			hy: "Փաթեթի նկարագրություն",
			fr: "Description du package",
			sp: "Descripción del paquete",
			ar: "وصف الحزمة",
			tur: "Paket Açıklaması",
			pers: "توضیحات بسته",
			ch: "包装描述"
		},
		packageVideo: {
			en: "Package Video",
			ru: "Видео пакета",
			hy: "Փաթեթի տեսանյութ",
			fr: "Vidéo du package",
			sp: "Video del paquete",
			ar: "فيديو الحزمة",
			tur: "Paket Videosu",
			pers: "ویدیو بسته بندی",
			ch: "包装视频"
		},
		packagePhoto: {
			en: "Package Photo",
			ru: "Фото пакета",
			hy: "Փաթեթի նկար",
			fr: "Photo du package",
			sp: "Foto del paquete",
			ar: "صورة الحزمة",
			tur: "Paket Fotoğrafı",
			pers: "عکس بسته بندی",
			ch: "包装照片"
		},
		itemDesc: {
			en: "Item Description",
			ru: "Описание товара",
			hy: "Ապրանքի նկարագրություն",
			fr: "Description de l'article",
			sp: "Descripción del artículo",
			ar: "وصف العنصر",
			tur: "Ürün Açıklaması",
			pers: "توضیحات محصول",
			ch: "项目描述"
		},
		itemName: {
			en: "Item Name",
			ru: "Название товара",
			hy: "Ապրանքի անուն",
			fr: "Nom de l'article",
			sp: "Nombre del artículo",
			ar: "اسم العنصر",
			tur: "Ürün Adı",
			pers: "نام محصول",
			ch: "项目名称"
		},
		packageName: {
			en: "Package Name",
			ru: "Название пакета",
			hy: "Փաթեթի անուն",
			fr: "Nom du package",
			sp: "Nombre del paquete",
			ar: "اسم الحزمة",
			tur: "Paket Adı",
			pers: "نام بسته بندی",
			ch: "包名"
		},
		serviceType: {
			en: "Service Type",
			ru: "Тип услуги",
			hy: "Ծառայության տեսակ",
			fr: "Type de service",
			sp: "Tipo de servicio",
			ar: "نوع الخدمة",
			tur: "Hizmet Türü",
			pers: "نوع خدمت",
			ch: "服务类型"
		},
		dateToDeliver: {
			en: "Date to Deliver",
			ru: "Дата доставки",
			hy: "Առաքման ամսաթիվ",
			fr: "Date de livraison",
			sp: "Fecha de entrega",
			ar: "تاريخ التسليم",
			tur: "Teslim Tarihi",
			pers: "تاریخ تحویل",
			ch: "交付日期"
		},
		amountOfMoney: {
			en: "Amount of Money",
			ru: "Сумма денег",
			hy: "Գումարի չափը",
			fr: "Montant d'argent",
			sp: "Cantidad de dinero",
			ar: "مبلغ من المال",
			tur: "Para Miktarı",
			pers: "مقدار پول",
			ch: "金额"
		},
		url: {
			en: "URL",
			ru: "URL",
			hy: "URL",
			fr: "URL",
			sp: "URL",
			ar: "رابط",
			tur: "URL",
			pers: "URL",
			ch: "链接"
		},
		photo: {
			en: "Photo",
			ru: "Фото",
			hy: "Նկար",
			fr: "Photo",
			sp: "Foto",
			ar: "صورة",
			tur: "Fotoğraf",
			pers: "عکس",
			ch: "照片"
		},
		video: {
			en: "Video",
			ru: "Видео",
			hy: "Տեսանյութ",
			fr: "Vidéo",
			sp: "Vídeo",
			ar: "فيديو",
			tur: "Video",
			pers: "ویدیو",
			ch: "视频"
		},
		desc: {
			en: "Description",
			ru: "Описание",
			hy: "Նկարագրություն",
			fr: "Description",
			sp: "Descripción",
			ar: "وصف",
			tur: "Açıklama",
			pers: "توضیحات",
			ch: "描述"
		},
		bussinessType: {
			en: "Bussiness Type",
			ru: "Тип бизнеса",
			hy: "Բիզնեսի տեսակ",
			fr: "Type d'entreprise",
			sp: "Tipo de negocio",
			ar: "نوع العمل",
			tur: "İş Türü",
			pers: "نوع کسب و کار",
			ch: "业务类型"
		},
		m3: {
			en: "M3",
			ru: "М3",
			hy: "Մ3",
			fr: "M3",
			sp: "M3",
			ar: "م3",
			tur: "M3",
			pers: "متر مکعب",
			ch: "立方米"
		},
		packageType: {
			en: "Package Type",
			ru: "Тип упаковки",
			hy: "Փաթեթի տեսակ",
			fr: "Type de colis",
			sp: "Tipo de paquete",
			ar: "نوع الحزمة",
			tur: "Paket Türü",
			pers: "نوع بسته بندی",
			ch: "包装类型"
		},
		kg: {
			en: "Kg",
			ru: "Кг",
			hy: "կգ",
			fr: "Kg",
			sp: "Kg",
			ar: "كغ",
			tur: "Kg",
			pers: "کیلوگرم",
			ch: "公斤"
		},
		deliveryType: {
			en: "Delivery Type",
			ru: "Тип доставки",
			hy: "Առաքման տեսակ",
			fr: "Type de livraison",
			sp: "Tipo de entrega",
			ar: "نوع التسليم",
			tur: "Teslimat Türü",
			pers: "نوع تحویل",
			ch: "交付类型"
		},
		dateIntevalStart: {
			en: "Date Inteval Start",
			ru: "Дата начала интервала",
			hy: "Միջակայքի սկիզբ",
			fr: "Date de début de l'intervalle",
			sp: "Fecha de inicio del intervalo",
			ar: "تاريخ بدء الفاصل الزمني",
			tur: "Zaman Aralığı Başlangıcı",
			pers: "تاریخ شروع بازه زمانی",
			ch: "日期间隔开始"
		},
		dateIntevalEnd: {
			en: "Date Inteval End",
			ru: "Дата окончания интервала",
			hy: "Միջակայքի ավարտ",
			fr: "Date de fin de l'intervalle",
			sp: "Fecha de finalización del intervalo",
			ar: "تاريخ نهاية الفاصل الزمني",
			tur: "Zaman Aralığı Sonu",
			pers: "تاریخ پایان بازه زمانی",
			ch: "日期间隔结束"
		},
		payment: {
			en: "Payment",
			ru: "Оплата",
			hy: "Վճար",
			fr: "Paiement",
			sp: "Pago",
			ar: "دفع",
			tur: "Ödeme",
			pers: "پرداخت",
			ch: "付款"
		},
		slug: {
			en: "Slug",
			ru: "Slug",
			hy: "Slug",
			fr: "Slug",
			sp: "Slug",
			ar: "Slug",
			tur: "Slug",
			pers: "Slug",
			ch: "Slug"
		},
		position: {
			en: "Position",
			ru: "Позиция",
			hy: "Հաստատություն",
			fr: "Position",
			sp: "Posición",
			ar: "موقف",
			tur: "Pozisyon",
			pers: "موقعیت",
			ch: "位置"
		},
		value: {
			en: "Value",
			ru: "Значение",
			hy: "Արժեք",
			fr: "Valeur",
			sp: "Valor",
			ar: "قيمة",
			tur: "Değer",
			pers: "ارزش",
			ch: "值"
		},
		lat: {
			en: "Latitude",
			ru: "Широта",
			hy: "Լայնություն",
			fr: "Latitude",
			sp: "Latitud",
			ar: "خط العرض",
			tur: "Enlem",
			pers: "عرض جغرافیایی",
			ch: "纬度"
		},
		lng: {
			en: "Longitude",
			ru: "Долгота",
			hy: "Երկայնություն",
			fr: "Longitude",
			sp: "Longitud",
			ar: "خط الطول",
			tur: "Boylam",
			pers: "طول جغرافیایی",
			ch: "经度"
		},
		zoom: {
			en: "Zoom",
			ru: "Увеличение",
			hy: "Զում",
			fr: "Zoom",
			sp: "Zoom",
			ar: "تكبير",
			tur: "Yakınlaştırma",
			pers: "بزرگنمایی",
			ch: "缩放"
		},
		buttonText: {
			en: "Button Text",
			ru: "Текст кнопки",
			hy: "Կոճակի տեքստ",
			fr: "Texte du bouton",
			sp: "Texto del botón",
			ar: "نص الزر",
			tur: "Düğme Metni",
			pers: "متن دکمه",
			ch: "按钮文字"
		},
		title: {
			en: "Title",
			ru: "Заголовок",
			hy: "Վերնագիր",
			fr: "Titre",
			sp: "Título",
			ar: "عنوان",
			tur: "Başlık",
			pers: "عنوان",
			ch: "标题"
		},
		whatsapp: {
			en: "Whatsapp",
			ru: "Whatsapp",
			hy: "Whatsapp",
			fr: "Whatsapp",
			sp: "Whatsapp",
			ar: "Whatsapp",
			tur: "Whatsapp",
			pers: "Whatsapp",
			ch: "Whatsapp"
		},
		viber: {
			en: "Viber",
			ru: "Viber",
			hy: "Viber",
			fr: "Viber",
			sp: "Viber",
			ar: "Viber",
			tur: "Viber",
			pers: "Viber",
			ch: "Viber"
		},
		corporation: {
			en: "Corporation",
			ru: "Корпорация",
			hy: "Կորպորացիա",
			fr: "Corporation",
			sp: "Corporación",
			ar: "شركة",
			tur: "Kurum",
			pers: "شرکت",
			ch: "公司"
		},
		deadline: {
			en: "Deadline",
			ru: "Крайний срок",
			hy: "Վերջնաժամկետ",
			fr: "Date limite",
			sp: "Fecha límite",
			ar: "الموعد النهائي",
			tur: "Son tarih",
			pers: "مهلت",
			ch: "最后期限"
		},
		tel: {
			en: "Tel",
			ru: "Тел",
			hy: "Հեռ",
			fr: "Tel",
			sp: "Tel",
			ar: "هاتف",
			tur: "Tel",
			pers: "تلفن",
			ch: "电话"
		},
		telegram: {
			en: "Telegram",
			ru: "Телеграм",
			hy: "Տելեգրամ",
			fr: "Telegram",
			sp: "Telegram",
			ar: "تلغرام",
			tur: "Telegram",
			pers: "تلگرام",
			ch: "电报"
		},
		uploadDate: {
			en: "Upload Date",
			ru: "Дата загрузки",
			hy: "Բեռնման ամսաթիվ",
			fr: "Date de téléchargement",
			sp: "Fecha de carga",
			ar: "تاريخ التحميل",
			tur: "Yükleme tarihi",
			pers: "تاریخ بارگذاری",
			ch: "上传日期"
		},
		seconds: {
			en: "seconds",
			ru: "секунды",
			hy: "վարկյան",
			fr: "secondes",
			sp: "segundos",
			ar: "ثواني",
			tur: "saniye",
			pers: "ثانیه",
			ch: "秒"
		},
		rightNow: {
			en: "Right now",
			ru: "Прямо сейчас",
			hy: "Հիմա",
			fr: "En ce moment",
			sp: "Ahora mismo",
			ar: "الآن",
			tur: "Şu anda",
			pers: "همین الان",
			ch: "现在"
		},
		completed: {
			en: "Completed",
			ru: "Завершено",
			hy: "Ավարտված է",
			fr: "Terminé",
			sp: "Terminado",
			ar: "منجز",
			tur: "Tamamlandı",
			pers: "کامل شده",
			ch: "完成"
		},
		update: {
			en: "Update",
			ru: "Обновить",
			hy: "Թարմացում",
			fr: "Mettre à jour",
			sp: "Actualizar",
			ar: "تحديث",
			tur: "Güncelleme",
			pers: "به روز رسانی",
			ch: "更新"
		},
		ago: {
			en: "ago",
			ru: "назад",
			hy: "առաջ",
			fr: "il y a",
			sp: "hace",
			ar: "منذ",
			tur: "önce",
			pers: "قبل",
			ch: "前"
		},
		starts: {
			en: "Starts",
			ru: "Начинается",
			hy: "Սկսվել է",
			fr: "Commence",
			sp: "Comienza",
			ar: "يبدأ",
			tur: "Başlar",
			pers: "شروع می شود",
			ch: "开始"
		},
		ends: {
			en: "Ends",
			ru: "Заканчивается",
			hy: "Ավարտվել է",
			fr: "Se termine",
			sp: "Termina",
			ar: "ينتهي",
			tur: "Biter",
			pers: "تمام می شود",
			ch: "结束"
		},
		timeline: {
			en: "Timeline",
			ru: "Хронология",
			hy: "Ժամանակագրություն",
			fr: "Chronologie",
			sp: "Cronología",
			ar: "الجدول الزمني",
			tur: "Zaman çizelgesi",
			pers: "زمان بندی",
			ch: "时间线"
		},
		error: {
			en: "Error",
			ru: "Ошибка",
			hy: "Սխալ",
			fr: "Erreur",
			sp: "Error",
			ar: "خطأ",
			tur: "Hata",
			pers: "خطا",
			ch: "错误"
		},
		previousStep: {
			en: "Previous step",
			ru: "Предыдущий шаг",
			hy: "Նախորդ քայլ",
			fr: "Étape précédente",
			sp: "Paso anterior",
			ar: "الخطوة السابقة",
			tur: "Önceki adım",
			pers: "مرحله قبلی",
			ch: "上一步"
		},
		nextStep: {
			en: "Next step",
			ru: "Следующий шаг",
			hy: "Հաջորդ քայլ",
			fr: "Étape suivante",
			sp: "Siguiente paso",
			ar: "الخطوة التالية",
			tur: "Sonraki adım",
			pers: "مرحله بعدی",
			ch: "下一步"
		},
		yesNextStep: {
			en: "Yes, go to the next step",
			ru: "Да, перейти к следующему шагу",
			hy: "Այո, անցել հաջորդ քայլին",
			fr: "Oui, passez à l'étape suivante",
			sp: "Sí, ve al siguiente paso",
			ar: "نعم، انتقل إلى الخطوة التالية",
			tur: "Evet, bir sonraki adıma geç",
			pers: "بله، به مرحله بعدی برو",
			ch: "是的，进入下一步"
		},
		yesPreviousStep: {
			en: "Yes, go to the previous step",
			ru: "Да, перейти к предыдущему шагу",
			hy: "Այո, անցել նախորդ քայլին",
			fr: "Oui, passez à l'étape précédente",
			sp: "Sí, ve al paso anterior",
			ar: "نعم، انتقل إلى الخطوة السابقة",
			tur: "Evet, bir önceki adıma git",
			pers: "بله، به مرحله قبلی برو",
			ch: "是的，进入上一步"
		},
		youWontBeAbleToRevert: {
			en: "You won't be able to revert this!",
			ru: "Вы не сможете отменить это!",
			hy: "Դուք չեք կարող կրկին վերադառնալ այսը!",
			fr: "Vous ne pourrez pas revenir en arrière!",
			sp: "¡No podrás revertir esto!",
			ar: "لن تتمكن من العودة!",
			tur: "Bunu geri alamayacaksınız!",
			pers: "شما قادر به بازگشت نخواهید بود!",
			ch: "您将无法恢复此操作！"
		},
		somethingWentWrong: {
			en: "Something went wrong",
			ru: "Что-то пошло не так",
			hy: "Ինչ-որ բան անհաջող է",
			fr: "Quelque chose s'est mal passé",
			sp: "Algo salió mal",
			ar: "حدث خطأ ما",
			tur: "Bir şeyler ters gitti",
			pers: "مشکلی پیش آمده است",
			ch: "出现了一些问题"
		},
		hideTimeline: {
			en: "Hide timeline",
			ru: "Скрыть хронологию",
			hy: "Թաքցնել ժամանակագրությունը",
			fr: "Masquer la chronologie",
			sp: "Ocultar la cronología",
			ar: "إخفاء الجدول الزمني",
			tur: "Zaman çizelgesini gizle",
			pers: "زمان بندی را مخفی کن",
			ch: "隐藏时间线"
		},
		showTimeline: {
			en: "Show timeline",
			ru: "Показать хронологию",
			hy: "Ցուցադրել ժամանակագրությունը",
			fr: "Afficher la chronologie",
			sp: "Mostrar la cronología",
			ar: "عرض الجدول الزمني",
			tur: "Zaman çizelgesini göster",
			pers: "زمان بندی را نشان بده",
			ch: "显示时间线"
		},
		yourFileHasBeenNotSaved: {
			en: "Your file has not been saved.",
			ru: "Ваш файл не был сохранен.",
			hy: "Ձեր ֆայլը չի պահպանվել"
		},
		showProjectDetails: {
			en: "Show project details",
			ru: "Показать детали проекта",
			hy: "Ցուցադրել նախագծի մանրամասները",
			fr: "Afficher les détails du projet",
			sp: "Mostrar detalles del proyecto",
			ar: "عرض تفاصيل المشروع",
			tur: "Proje detaylarını göster",
			pers: "جزئیات پروژه را نشان بده",
			ch: "显示项目详细信息"
		},
		hideProjectDetails: {
			en: "Hide project details",
			ru: "Скрыть детали проекта",
			hy: "Թաքցնել նախագծի մանրամասները",
			fr: "Masquer les détails du projet",
			sp: "Ocultar detalles del proyecto",
			ar: "إخفاء تفاصيل المشروع",
			tur: "Proje detaylarını gizle",
			pers: "جزئیات پروژه را مخفی کن",
			ch: "隐藏项目详细信息"
		},
		step: {
			en: "Step",
			ru: "Шаг",
			hy: "Քայլ",
			fr: "Étape",
			sp: "Paso",
			ar: "خطوة",
			tur: "Adım",
			pers: "مرحله",
			ch: "步"
		},
		somethingWentWrongInCreatingSteps: {
			en: "Something went wrong in creating steps! Please try again",
			ru: "Что-то пошло не так при создании шагов! Пожалуйста, попробуйте еще раз",
			hy: "Ինչ-որ բան անհաջող է ստեղծել քայլերը։ Խնդրում եմ կրկին փորձեք",
			fr: "Quelque chose s'est mal passé lors de la création des étapes! Veuillez réessayer",
			sp: "¡Algo salió mal al crear los pasos! Por favor, inténtelo de nuevo",
			ar: "حدث خطأ ما أثناء إنشاء الخطوات! يرجى المحاولة مرة أخرى",
			tur: "Adımlar oluşturulurken bir şeyler ters gitti! Lütfen tekrar deneyin",
			pers: "در ایجاد مراحل مشکلی پیش آمده است! لطفا دوباره امتحان کنید",
			ch: "创建步骤时出现问题！请重试"
		},
		errorpageTitle: {
			en: "You have found a secret place.",
			ru: "Вы нашли секретное место.",
			hy: "Դուք գտել եք գաղտնի վայրը։",
			fr: "Vous avez trouvé un endroit secret.",
			sp: "Has encontrado un lugar secreto.",
			ar: "لقد وجدت مكانًا سريًا.",
			tur: "Gizli bir yeri buldun.",
			pers: "شما یک مکان مخفی پیدا کرده اید.",
			ch: "您找到了一个秘密地方。"
		},
		errorpageText: {
			en: "Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.",
			ru: "К сожалению, это только страница 404. Возможно, вы ошиблись в адресе или страница была перемещена на другой URL.",
			hy: "Ներողություններով, սա միայն 404 էջն է։ Դուք կարող եք սխալ գրել հասցեն, կամ էջը տեղափոխվել է այլ URL-ի վրա։",
			fr: "Malheureusement, il ne s'agit que d'une page 404. Vous avez peut-être mal tapé l'adresse ou la page a été déplacée vers une autre URL.",
			sp: "Desafortunadamente, esta es solo una página 404. Es posible que haya escrito mal la dirección o que la página se haya movido a otra URL.",
			ar: "للأسف، هذه هي صفحة 404 فقط. قد تكون قد أدخلت العنوان بشكل خاطئ، أو تم نقل الصفحة إلى عنوان URL آخر.",
			tur: "Maalesef, bu sadece bir 404 sayfası. Adresi yanlış yazmış olabilirsiniz veya sayfa başka bir URL'ye taşınmış olabilir.",
			pers: "متاسفانه، این فقط یک صفحه 404 است. ممکن است آدرس را اشتباه وارد کرده باشید یا صفحه به URL دیگری منتقل شده باشد.",
			ch: "很遗憾，这只是一个404页面。您可能输入了错误的地址，或者页面已移动到另一个URL。"
		},
		errorpageButton: {
			en: "Take me back to home page",
			ru: "Верните меня на домашнюю страницу",
			hy: "Վերադարձեք ինձ դեպի գլխավոր էջ",
			fr: "Ramenez-moi à la page d'accueil",
			sp: "Llévame de vuelta a la página de inicio",
			ar: "أعدني إلى الصفحة الرئيسية",
			tur: "Beni ana sayfaya götür",
			pers: "مرا به صفحه اصلی ببر",
			ch: "带我回首页"
		},
		yourFileHasBeenSaved: {
			en: "Your file has been saved.",
			ru: "Ваш файл был сохранен.",
			hy: "Ձեր ֆայլը պահպանված է",
			fr: "Votre fichier a été enregistré.",
			sp: "Su archivo ha sido guardado.",
			ar: "تم حفظ ملفك.",
			tur: "Dosyanız kaydedildi.",
			pers: "فایل شما ذخیره شده است.",
			ch: "您的文件已保存。"
		},
		deleted: {
			en: "Deleted",
			ru: "Удален",
			hy: "Ջնջված է",
			fr: "Supprimé",
			sp: "Borrado",
			ar: "حذف",
			tur: "Silindi",
			pers: "حذف شده",
			ch: "已删除"
		},
		yourFileHasBeenDeleted: {
			en: "Your file has been deleted.",
			ru: "Ваш файл был удален.",
			hy: "Ձեր ֆայլը ջնջվել է",
			fr: "Votre fichier a été supprimé.",
			sp: "Su archivo ha sido eliminado.",
			ar: "تم حذف ملفك.",
			tur: "Dosyanız silindi.",
			pers: "فایل شما حذف شده است.",
			ch: "您的文件已删除。"
		},
		table: {
			en: "Table",
			ru: "Таблица",
			hy: "Աղյուսակ",
			fr: "Table",
			sp: "Tabla",
			ar: "الجدول",
			tur: "Tablo",
			pers: "جدول",
			ch: "表"
		},
		toDo: {
			en: "To Do",
			ru: "Сделать",
			hy: "Կատարել",
			fr: "À faire",
			sp: "Hacer",
			ar: "للقيام",
			tur: "Yapılacaklar",
			pers: "انجام دادن",
			ch: "要做"
		},
		done: {
			en: "Done",
			ru: "Готово",
			hy: "Ավարտված է",
			fr: "Terminé",
			sp: "Hecho",
			ar: "منجز",
			tur: "Tamamlandı",
			pers: "انجام شده",
			ch: "完成"
		},
		slider: {
			en: "Slider",
			ru: "Слайдер",
			hy: "Սլայդեր",
			fr: "Curseur",
			sp: "Deslizador",
			ar: "منزلق",
			tur: "Kaydırıcı",
			pers: "اسلایدر",
			ch: "滑块"
		},
		historyTable: {
			en: "History table",
			ru: "Таблица истории",
			hy: "Պատմության աղյուսակ",
			fr: "Table d'histoire",
			sp: "Tabla de historia",
			ar: "جدول التاريخ",
			tur: "Tarih tablosu",
			pers: "جدول تاریخچه",
			ch: "历史表"
		},
		minutes: {
			en: "minutes",
			ru: "минуты",
			hy: "րոպե",
			fr: "minutes",
			sp: "minutos",
			ar: "دقائق",
			tur: "dakika",
			pers: "دقیقه",
			ch: "分钟"
		},
		closed: {
			en: "Closed",
			ru: "Закрыто",
			hy: "Փակված է",
			fr: "Fermé",
			sp: "Cerrado",
			ar: "مغلق",
			tur: "Kapalı",
			pers: "بسته شده",
			ch: "已关闭"
		},
		loading: {
			en: "Loading",
			ru: "Загрузка",
			hy: "Բեռնում",
			fr: "Chargement",
			sp: "Cargando",
			ar: "تحميل",
			tur: "Yükleniyor",
			pers: "در حال بارگذاری",
			ch: "加载中"
		},
		swal: {
			error: {
				en: '"Something went wrong!"',
				ru: '"Что-то пошло не так!"',
				hy: '"Ինչ-որ բան անհաջող է!"',
				fr: '"Quelque chose s\'est mal passé!"',
				sp: '"¡Algo salió mal!"',
				ar: '"حدث خطأ ما!"',
				tur: '"Bir şeyler ters gitti!"',
				pers: '"مشکلی پیش آمده است!"',
				ch: '"出现了一些问题!"'
			},
			success: {
				en: "Success",
				ru: "Успех",
				hy: "Հաջողություն",
				fr: "Succès",
				sp: "Éxito",
				ar: "نجاح",
				tur: "Başarılı",
				pers: "موفقیت",
				ch: "成功"
			},
			warning: {
				en: "Warning",
				ru: "Предупреждение",
				hy: "Զգուշացում",
				fr: "Attention",
				sp: "Advertencia",
				ar: "تحذير",
				tur: "Uyarı",
				pers: "هشدار",
				ch: "警告"
			},
			info: {
				en: "Info",
				ru: "Информация",
				hy: "Տեղեկություն",
				fr: "Info",
				sp: "Información",
				ar: "معلومات",
				tur: "Bilgi",
				pers: "اطلاعات",
				ch: "信息"
			}
		},
		selectFiles: {
			en: "Select files",
			ru: "Выберите файлы",
			hy: "Ընտրեք ֆայլերը",
			fr: "Sélectionner des fichiers",
			sp: "Seleccionar archivos",
			ar: "حدد الملفات",
			tur: "Dosyaları seç",
			pers: "انتخاب فایل ها",
			ch: "选择文件"
		},
		maxSize: {
			en: "Max size",
			ru: "Максимальный размер",
			hy: "Մաքսիմալ չափ",
			fr: "Taille maximale",
			sp: "Tamaño máximo",
			ar: "الحجم الأقصى",
			tur: "Maksimum boyut",
			pers: "حداکثر اندازه",
			ch: "最大尺寸"
		},
		attach: {
			en: "Attach",
			ru: "Прикрепить",
			hy: "Կցել",
			fr: "Attacher",
			sp: "Adjuntar",
			ar: "إرفاق",
			tur: "Ekle",
			pers: "پیوست",
			ch: "附上"
		},
		image: {
			en: "Image",
			ru: "Изображение",
			hy: "Նկար",
			fr: "Image",
			sp: "Imagen",
			ar: "صورة",
			tur: "Resim",
			pers: "تصویر",
			ch: "图片"
		},
		pdf: {
			en: "PDF",
			ru: "PDF",
			hy: "PDF",
			fr: "PDF",
			sp: "PDF",
			ar: "PDF",
			tur: "PDF",
			pers: "PDF",
			ch: "PDF"
		},
		files: {
			en: "Files",
			ru: "Файлы",
			hy: "Ֆայլեր",
			fr: "Fichiers",
			sp: "Archivos",
			ar: "ملفات",
			tur: "Dosyalar",
			pers: "فایل ها",
			ch: "文件"
		},
		file: {
			en: "File",
			ru: "Файл",
			hy: "Ֆայլ",
			fr: "Fichier",
			sp: "Archivo",
			ar: "ملف",
			tur: "Dosya",
			pers: "فایل",
			ch: "文件"
		},
		dropzoneText: {
			en: "Drag images here or click to select files",
			ru: "Перетащите изображения сюда или нажмите, чтобы выбрать файлы",
			hy: "Քաշեք նկարները այստեղ կամ սեղմեք, որ ընտրեք ֆայլերը",
			fr: "Faites glisser les images ici ou cliquez pour sélectionner des fichiers",
			sp: "Arrastra imágenes aquí o haz clic para seleccionar archivos",
			ar: "اسحب الصور هنا أو انقر لتحديد الملفات",
			tur: "Resimleri buraya sürükleyin veya dosyaları seçmek için tıklayın",
			pers: "تصاویر را به اینجا بکشید یا برای انتخاب فایل ها کلیک کنید",
			ch: "将图像拖到此处或单击以选择文件"
		},
		inputNumber: {
			en: "Input number",
			ru: "Ввод номера",
			hy: "Համարի մուտք",
			fr: "Entrer le numéro",
			sp: "Número de entrada",
			ar: "إدخال الرقم",
			tur: "Numara gir",
			pers: "ورودی شماره",
			ch: "输入号码"
		},
		inputInLatin: {
			en: "Input in Latin",
			ru: "Ввод на кирилице",
			hy: "Մուտքագրում լատինատառերով",
			fr: "Saisie en latin",
			sp: "Entrada en latín",
			ar: "إدخال باللاتينية",
			tur: "Latin harfleriyle giriş yapın",
			pers: "ورودی به زبان لاتین",
			ch: "输入拉丁文"
		},
		inputInArmenian: {
			en: "Input in Armenian",
			ru: "Ввод на армянском",
			hy: "Մուտքագրում հայերեն",
			fr: "Saisie en arménien",
			sp: "Entrada en armenio",
			ar: "إدخال بالأرمنية",
			tur: "Ermenice giriş",
			pers: "ورودی به زبان ارمنی",
			ch: "输入亚美尼亚语"
		},
		inputInRussian: {
			en: "Input in Russian",
			ru: "Ввод на русском",
			hy: "Մուտքագրում ռուսերեն",
			fr: "Saisie en russe",
			sp: "Entrada en ruso",
			ar: "إدخال بالروسية",
			tur: "Rusça giriş",
			pers: "ورودی به زبان روسی",
			ch: "输入俄语"
		},
		software: {
			en: "Software",
			ru: "Программное обеспечение",
			hy: "Համակարգային ծրագիր",
			fr: "Logiciel",
			sp: "Software",
			ar: "برنامج",
			tur: "Yazılım",
			pers: "نرم افزار",
			ch: "软件"
		},
		description: {
			en: "Description",
			ru: "Описание",
			hy: "Նկարագրություն",
			fr: "Description",
			sp: "Descripción",
			ar: "وصف",
			tur: "Açıklama",
			pers: "توضیحات",
			ch: "描述"
		},
		closedDate: {
			en: "Closed Date",
			ru: "Дата закрытия",
			hy: "Փակված է",
			fr: "Date de clôture",
			sp: "Fecha de cierre",
			ar: "تاريخ الإغلاق",
			tur: "Kapanış Tarihi",
			pers: "تاریخ بسته شده",
			ch: "关闭日期"
		},
		selectClosedDate: {
			en: "Select closed date",
			ru: "Выберите дату закрытия",
			hy: "Ընտրեք փակված ամսաթիվ",
			fr: "Sélectionner la date de clôture",
			sp: "Seleccionar fecha de cierre",
			ar: "حدد تاريخ الإغلاق",
			tur: "Kapanış tarihini seç",
			pers: "تاریخ بسته شده را انتخاب کنید",
			ch: "选择关闭日期"
		},
		add: {
			en: "Add",
			ru: "Добавить",
			hy: "Ավելացնել",
			fr: "Ajouter",
			sp: "Añadir",
			ar: "أضف",
			tur: "Ekle",
			pers: "افزودن",
			ch: "添加"
		},
		languagePickerLabel: {
			en: "Language",
			ru: "Язык",
			hy: "Լեզու",
			fr: "Langue",
			sp: "Idioma",
			ar: "لغة",
			tur: "Dil",
			pers: "زبان",
			ch: "语言"
		},
		themePickerLabel: {
			en: "Theme",
			ru: "Тема",
			hy: "Թեմա",
			fr: "Thème",
			sp: "Tema",
			ar: "موضوع",
			tur: "Tema",
			pers: "تم",
			ch: "主题"
		},
		settings: {
			en: "Settings",
			ru: "Настройки",
			hy: "Կարգավորումներ",
			fr: "Paramètres",
			sp: "Ajustes",
			ar: "الإعدادات",
			tur: "Ayarlar",
			pers: "تنظیمات",
			ch: "设置"
		},
		logOut: {
			en: "Log out",
			ru: "Выйти",
			hy: "Ելք",
			fr: "Se déconnecter",
			sp: "Cerrar sesión",
			ar: "تسجيل الخروج",
			tur: "Çıkış yap",
			pers: "خروج",
			ch: "登出"
		},
		leader: {
			en: "Leader",
			ru: "Руководитель",
			hy: "Տնօրեն",
			fr: "Chef",
			sp: "Líder",
			ar: "قائد",
			tur: "Lider",
			pers: "رهبر",
			ch: "领导"
		},
		linkCopied: {
			en: "Link copied",
			ru: "Ссылка скопирована",
			hy: "Լինկը պատճենվել է",
			fr: "Lien copié",
			sp: "Enlace copiado",
			ar: "تم نسخ الرابط",
			tur: "Bağlantı kopyalandı",
			pers: "لینک کپی شد",
			ch: "链接已复制"
		},
		cart: {
			en: "Cart",
			ru: "Корзина",
			hy: "Զամբյուղ",
			fr: "Panier",
			sp: "Carrito",
			ar: "عربة التسوق",
			tur: "Sepet",
			pers: "سبد",
			ch: "购物车"
		},
		passportNo: {
			en: "Passport No",
			ru: "Паспорт №",
			hy: "Անձնագրի №",
			fr: "Numéro de passeport",
			sp: "Número de pasaporte",
			ar: "رقم الجواز",
			tur: "Pasaport No",
			pers: "شماره پاسپورت",
			ch: "护照号"
		},
		changedDate: {
			en: "Change Date",
			ru: "Дата изменения",
			hy: "Փոփոխության ամսաթիվը",
			fr: "Date de modification",
			sp: "Fecha de cambio",
			ar: "تاريخ التغيير",
			tur: "Değiştirme Tarihi",
			pers: "تاریخ تغییر",
			ch: "更改日期"
		},
		email: {
			en: "Email",
			ru: "Эл. адрес",
			hy: "Էլ. հասցեն",
			fr: "Email",
			sp: "Email",
			ar: "البريد الإلكتروني",
			tur: "E-posta",
			pers: "ایمیل",
			ch: "电子邮件"
		},
		user: {
			en: "User",
			ru: "Пользователь",
			hy: "Օգտատեր",
			fr: "Utilisateur",
			sp: "Usuario",
			ar: "المستخدم",
			tur: "Kullanıcı",
			pers: "کاربر",
			ch: "用户"
		},
		banner: {
			en: "Banner",
			ru: "Баннер",
			hy: "Բաններ",
			fr: "Bannière",
			sp: "Banner",
			ar: "راية",
			tur: "Banner",
			pers: "بنر",
			ch: "横幅"
		},
		ordersCart: {
			en: "Orderes chart",
			ru: "График заказов",
			hy: "Պատվերների գծապատկեր",
			fr: "Graphique des commandes",
			sp: "Gráfico de pedidos",
			ar: "رسم بياني للطلبات",
			tur: "Siparişler grafiği",
			pers: "نمودار سفارشات",
			ch: "订单图"
		},
		quantity: {
			en: "Quantity",
			ru: "Количество",
			hy: "Քանակ",
			fr: "Quantité",
			sp: "Cantidad",
			ar: "كمية",
			tur: "Miktar",
			pers: "تعداد",
			ch: "数量"
		},
		color: {
			en: "Color",
			ru: "Цвет",
			hy: "Գույն",
			fr: "Couleur",
			sp: "Color",
			ar: "لون",
			tur: "Renk",
			pers: "رنگ",
			ch: "颜色"
		},
		addToList: {
			en: "Add to list",
			ru: "Добавить в лист",
			hy: "Ավելացնել լիստում",
			fr: "Ajouter à la liste",
			sp: "Agregar a la lista",
			ar: "أضف إلى القائمة",
			tur: "Listeye ekle",
			pers: "افزودن به لیست",
			ch: "添加到清单"
		},
		noContent: {
			en: "There is no content",
			ru: "Нет контента",
			hy: "Բովանդակություն չկա",
			fr: "Il n'y a pas de contenu",
			sp: "No hay contenido",
			ar: "لا يوجد محتوى",
			tur: "İçerik yok",
			pers: "محتوایی وجود ندارد",
			ch: "没有内容"
		},
		paymentMethod: {
			en: "Payment method",
			ru: "Метод оплаты",
			hy: "Վճարման եղանակ",
			fr: "Méthode de paiement",
			sp: "Método de pago",
			ar: "طريقة الدفع",
			tur: "Ödeme şekli",
			pers: "روش پرداخت",
			ch: "付款方式"
		},
		paymentSystem: {
			en: "Payment System",
			ru: "Платежная система",
			hy: "Վճարային համակարգ",
			fr: "Système de paiement",
			sp: "Sistema de pago",
			ar: "نظام الدفع",
			tur: "Ödeme sistemi",
			pers: "سیستم پرداخت",
			ch: "支付系统"
		},
		deliveryMethod: {
			en: "Delivery method",
			ru: "Способ доставки",
			hy: "Առաքման եղանակը",
			fr: "Méthode de livraison",
			sp: "Método de entrega",
			ar: "طريقة التوصيل",
			tur: "Teslimat şekli",
			pers: "روش تحویل",
			ch: "交货方式"
		},
		deliveryAddress: {
			en: "Delivery Address",
			ru: "Адресс доставки",
			hy: "Առաքման հասցե",
			fr: "Adresse de livraison",
			sp: "Dirección de entrega",
			ar: "عنوان التوصيل",
			tur: "Teslimat adresi",
			pers: "آدرس تحویل",
			ch: "交货地址"
		},
		placeholder: {
			en: "Placeholder",
			ru: "Ввод на кирилице",
			hy: "Մուտքագրում հայատառ",
			fr: "Placeholder",
			sp: "Marcador de posición",
			ar: "عنصر الوسم",
			tur: "Yer Tutucu",
			pers: "جایگزین",
			ch: "占位符"
		},
		username: {
			en: "Username",
			ru: "Имя пользователя",
			hy: "Օգտատիրոջ անուն",
			fr: "Nom d'utilisateur",
			sp: "Nombre de usuario",
			ar: "اسم المستخدم",
			tur: "Kullanıcı adı",
			pers: "نام کاربری",
			ch: "用户名"
		},
		login: {
			en: "Login",
			ru: "Логин",
			hy: "Մուտք",
			fr: "Connexion",
			sp: "Iniciar sesión",
			ar: "تسجيل الدخول",
			tur: "Giriş",
			pers: "ورود",
			ch: "登录"
		},
		loginToStartSession: {
			en: "Sign in to start a session",
			ru: "Войдите, чтобы начать сеанс",
			hy: "Մուտք գործեք՝ սկսելու համար",
			fr: "Connectez-vous pour commencer une session",
			sp: "Inicie sesión para comenzar una sesión",
			ar: "قم بتسجيل الدخول لبدء جلسة",
			tur: "Bir oturum başlatmak için oturum açın",
			pers: "برای شروع یک جلسه وارد شوید",
			ch: "登录以开始会话"
		},
		loginButton: {
			en: "Log in",
			ru: "Войти",
			hy: "Մուտք",
			fr: "Connexion",
			sp: "Iniciar sesión",
			ar: "تسجيل الدخول",
			tur: "Giriş yap",
			pers: "ورود",
			ch: "登录"
		},
		minute: {
			en: "minute",
			ru: "минута",
			hy: "րոպե",
			fr: "minute",
			sp: "minuto",
			ar: "دقيقة",
			tur: "dakika",
			pers: "دقیقه",
			ch: "分钟"
		},
		reset: {
			en: "Reset",
			ru: "Збросить",
			hy: "Չեղարկել",
			fr: "Réinitialiser",
			sp: "Reiniciar",
			ar: "إعادة تعيين",
			tur: "Sıfırla",
			pers: "بازنشانی",
			ch: "重置"
		},
		notifications: {
			en: "Notifications",
			ru: "Уведомления",
			hy: "Ծանուցումները",
			fr: "Notifications",
			sp: "Notificaciones",
			ar: "إشعارات",
			tur: "Bildirimler",
			pers: "اعلان ها",
			ch: "通知"
		},
		supportText: {
			en: "For all questions write",
			ru: "По всем вопросам пишите",
			hy: "Բոլոր հարցերի համար գրեք",
			fr: "Pour toutes les questions écrire",
			sp: "Para todas las preguntas escribir",
			ar: "لجميع الأسئلة اكتب",
			tur: "Tüm sorular için yaz",
			pers: "برای همه سوالات بنویسید",
			ch: "有问题写"
		},
		incorrectLogin: {
			en: "You entered an incorrect username or password",
			ru: "Вы ввели неверный логин или пароль",
			hy: "Դուք մուտքագրել եք սխալ օգտվողի անուն կամ գաղտնաբառ",
			fr: "Vous avez entré un nom d'utilisateur ou un mot de passe incorrect",
			sp: "Ha introducido un nombre de usuario o contraseña incorrectos",
			ar: "أدخلت اسم مستخدم أو كلمة مرور غير صحيحة",
			tur: "Yanlış bir kullanıcı adı veya şifre girdiniz",
			pers: "نام کاربری یا رمز عبور نادرست وارد کردید",
			ch: "您输入了不正确的用户名或密码"
		},
		address: {
			en: "Address",
			ru: "Адрес",
			hy: "Հասցե",
			fr: "Adresse",
			sp: "Dirección",
			ar: "عنوان",
			tur: "Adres",
			pers: "آدرس",
			ch: "地址"
		},
		dashboard: {
			en: "Dashboard",
			ru: "Доска",
			hy: "Վահանակ",
			fr: "Tableau de bord",
			sp: "Tablero",
			ar: "لوحة القيادة",
			tur: "Gösterge paneli",
			pers: "داشبورد",
			ch: "仪表板"
		},
		password: {
			en: "Password",
			ru: "Пароль",
			hy: "Գաղտնաբառ",
			fr: "Mot de passe",
			sp: "Contraseña",
			ar: "كلمة السر",
			tur: "Şifre",
			pers: "رمز عبور",
			ch: "密码"
		},
		href: {
			en: "Href",
			ru: "Ссылка",
			hy: "Հղում",
			fr: "Href",
			sp: "Href",
			ar: "Href",
			tur: "Href",
			pers: "Href",
			ch: "Href"
		},
		name: {
			en: "Name",
			ru: "Имя",
			hy: "Անուն",
			fr: "Nom",
			sp: "Nombre",
			ar: "اسم",
			tur: "Ad",
			pers: "نام",
			ch: "名称"
		},
		firstName: {
			en: "First Name",
			ru: "Имя",
			hy: "Անուն",
			fr: "Prénom",
			sp: "Nombre",
			ar: "الاسم الاول",
			tur: "İsim",
			pers: "نام",
			ch: "名字"
		},
		lasts: {
			en: "Lasts",
			ru: "Длилось",
			hy: "Տևեց",
			fr: "Dure",
			sp: "Dura",
			ar: "يستمر",
			tur: "Sürer",
			pers: "مدت",
			ch: "持续"
		},
		lastName: {
			en: "Last Name",
			ru: "Фамилия",
			hy: "Ազգանուն",
			fr: "Nom de famille",
			sp: "Apellido",
			ar: "الكنية",
			tur: "Soyad",
			pers: "نام خانوادگی",
			ch: "姓"
		},
		dateOfBirth: {
			en: "Date Of Birth",
			ru: "Дата рождения",
			hy: "Ծննդյան ամսաթիվ",
			fr: "Date de naissance",
			sp: "Fecha de nacimiento",
			ar: "تاريخ الميلاد",
			tur: "Doğum tarihi",
			pers: "تاریخ تولد",
			ch: "出生日期"
		},
		subDesc: {
			en: "Sub Description",
			ru: "Под описание",
			hy: "Ենթա նկարագրություն",
			fr: "Sous description",
			sp: "Sub descripción",
			ar: "وصف فرعي",
			tur: "Alt Açıklama",
			pers: "توضیحات فرعی",
			ch: "子描述"
		},
		cannotPayOnline: {
			en: "Cannot pay online",
			ru: "Невозможно оплатить онлайн",
			hy: "Հնարավոր չէ վճարել առցանց",
			fr: "Impossible de payer en ligne",
			sp: "No se puede pagar en línea",
			ar: "لا يمكن الدفع عبر الإنترنت",
			tur: "Online ödeme yapılamaz",
			pers: "نمی توانید آنلاین پرداخت کنید",
			ch: "无法在线支付"
		},
		notAvailable: {
			en: "Product not available",
			ru: "Продукт недоступен",
			hy: "Ապրանքը հասանելի չէ",
			fr: "Produit non disponible",
			sp: "Producto no disponible",
			ar: "المنتج غير متوفر",
			tur: "Ürün mevcut değil",
			pers: "محصول موجود نیست",
			ch: "产品不可用"
		},
		role: {
			en: "Role",
			ru: "Роль",
			hy: "Դեր",
			fr: "Rôle",
			sp: "Papel",
			ar: "وظيفة",
			tur: "Rol",
			pers: "نقش",
			ch: "角色"
		},
		roles: [
			{
				_id: "admin",
				name: {
					en: "Admin",
					ru: "Администратор",
					hy: "Ադմին",
					fr: "Admin",
					sp: "Admin",
					ar: "مدير",
					tur: "Yönetici",
					pers: "مدیر",
					ch: "管理员"
				}
			},
			{
				_id: "manager",
				name: {
					en: "Manager",
					ru: "Управляющий",
					hy: "Մենեջեր",
					fr: "Gestionnaire",
					sp: "Gerente",
					ar: "مدير",
					tur: "Yönetici",
					pers: "مدیر",
					ch: "经理"
				}
			},
			{
				_id: "editor",
				name: {
					en: "Editor",
					ru: "Редактор",
					hy: "Խմբագիր",
					fr: "Éditeur",
					sp: "Editor",
					ar: "محرر",
					tur: "Editör",
					pers: "ویرایشگر",
					ch: "编辑"
				}
			}
		],
		rate: {
			en: "Rate",
			ru: "Показатель",
			hy: "Գործակից",
			fr: "Taux",
			sp: "Tarifa",
			ar: "معدل",
			tur: "Oran",
			pers: "نرخ",
			ch: "速率"
		},
		price: {
			en: "Price",
			ru: "Цена",
			hy: "Գին",
			fr: "Prix",
			sp: "Precio",
			ar: "السعر",
			tur: "Fiyat",
			pers: "قیمت",
			ch: "价格"
		},
		company: {
			en: "Company",
			ru: "Компания",
			hy: "Ընկերություն",
			fr: "Entreprise",
			sp: "Empresa",
			ar: "شركة",
			tur: "Şirket",
			pers: "شرکت",
			ch: "公司"
		},
		socialNetworkLink: {
			en: "Social Network Link",
			ru: "Ссылка на социальную сеть",
			hy: "Սոցիալական ցանցի հղումը",
			fr: "Lien de réseau social",
			sp: "Enlace de red social",
			ar: "رابط الشبكة الاجتماعية",
			tur: "Sosyal Ağ Bağlantısı",
			pers: "لینک شبکه اجتماعی",
			ch: "社交网络链接"
		},
		phone: {
			en: "Phone",
			ru: "Телефон",
			hy: "Հեռախոս",
			fr: "Téléphone",
			sp: "Teléfono",
			ar: "هاتف",
			tur: "Telefon",
			pers: "تلفن",
			ch: "电话"
		},
		startDate: {
			en: "Start Date",
			ru: "Дата начала",
			hy: "Մեկնարկի ամսաթիվ",
			fr: "Date de début",
			sp: "Fecha de inicio",
			ar: "تاريخ البدء",
			tur: "Başlangıç tarihi",
			pers: "تاریخ شروع",
			ch: "开始日期"
		},
		endDate: {
			en: "End Date",
			ru: "Дата окончания",
			hy: "Ավարտի ամսաթիվ",
			fr: "Date de fin",
			sp: "Fecha de finalización",
			ar: "تاريخ الانتهاء",
			tur: "Bitiş tarihi",
			pers: "تاریخ پایان",
			ch: "结束日期"
		},
		status: {
			en: "Status",
			ru: "Статус",
			hy: "Ստատուս",
			fr: "Statut",
			sp: "Estado",
			ar: "الحالة",
			tur: "Durum",
			pers: "وضعیت",
			ch: "状态"
		},
		client: {
			en: "Client",
			ru: "Клиент",
			hy: "Հաճախորդ",
			fr: "Client",
			sp: "Cliente",
			ar: "زبون",
			tur: "Müşteri",
			pers: "مشتری",
			ch: "客户"
		},
		genders: [
			{
				name: {
					en: "Male",
					ru: "Мужчина",
					hy: "Արական",
					fr: "Mâle",
					sp: "Masculino",
					ar: "ذكر",
					tur: "Erkek",
					pers: "مرد",
					ch: "男"
				}
			},
			{
				name: {
					en: "Female",
					ru: "Женщина",
					hy: "իգական",
					fr: "Femelle",
					sp: "Hembra",
					ar: "أنثى",
					tur: "Dişi",
					pers: "زن",
					ch: "女"
				}
			}
		],
		gender: {
			en: "Gender",
			ru: "Пол",
			hy: "Սեռ",
			fr: "Sexe",
			sp: "Género",
			ar: "جنس",
			tur: "Cinsiyet",
			pers: "جنسیت",
			ch: "性别"
		},
		familyStatuses: [
			{
				name: {
					en: "Single",
					ru: "Одинокий",
					hy: "Միայնակ",
					fr: "Célibataire",
					sp: "Soltero",
					ar: "أعزب",
					tur: "Bekar",
					pers: "مجرد",
					ch: "单身"
				}
			},
			{
				name: {
					en: "Married",
					ru: "В браке",
					hy: "Ամուսնացած",
					fr: "Marié",
					sp: "Casado",
					ar: "متزوج",
					tur: "Evli",
					pers: "متاهل",
					ch: "已婚"
				}
			}
		],
		more: {
			en: "Additional",
			ru: "Дополнительный",
			hy: "Լրացուցիչ",
			fr: "Supplémentaire",
			sp: "Adicional",
			ar: "إضافي",
			tur: "Ek",
			pers: "اضافی",
			ch: "额外"
		},
		addNew: {
			en: "Add New",
			ru: "Добавить",
			hy: "Ավելացնել",
			fr: "Ajouter",
			sp: "Añadir",
			ar: "إضافة جديد",
			tur: "Yeni Ekle",
			pers: "افزودن",
			ch: "新增"
		},
		create: {
			en: "Order",
			ru: "Заказ",
			hy: "Պատվեր",
			fr: "Commander",
			sp: "Orden",
			ar: "طلب",
			tur: "Sipariş",
			pers: "سفارش",
			ch: "订单"
		},
		save: {
			en: "Save",
			ru: "Сохранить",
			hy: "Պահպանել",
			fr: "Enregistrer",
			sp: "Salvar",
			ar: "حفظ",
			tur: "Kaydet",
			pers: "ذخیره",
			ch: "保存"
		},
		sent: {
			en: "Sent",
			ru: "Отправленно",
			hy: "Ուղարկված է",
			fr: "Envoyé",
			sp: "Enviado",
			ar: "أرسل",
			tur: "Gönderildi",
			pers: "ارسال شده",
			ch: "发送"
		},
		send: {
			en: "Send",
			ru: "Отправить",
			hy: "Ուղարկել",
			fr: "Envoyer",
			sp: "Enviar",
			ar: "إرسال",
			tur: "Gönder",
			pers: "ارسال",
			ch: "发送"
		},
		open: {
			en: "Open",
			ru: "Открыть",
			hy: "Բացել",
			fr: "Ouvrir",
			sp: "Abierto",
			ar: "افتح",
			tur: "Açık",
			pers: "باز کن",
			ch: "打开"
		},
		remove: {
			en: "Remove",
			ru: "Удалить",
			hy: "Ջնջել",
			fr: "Supprimer",
			sp: "Eliminar",
			ar: "إزالة",
			tur: "Kaldır",
			pers: "حذف",
			ch: "去掉"
		},
		cancel: {
			en: "Cancel",
			ru: "Отменить",
			hy: "Չեղարկել",
			fr: "Annuler",
			sp: "Cancelar",
			ar: "إلغاء",
			tur: "İptal",
			pers: "لغو",
			ch: "取消"
		},
		edit: {
			en: "Edit",
			ru: "Редактировать",
			hy: "Խմբագրել",
			fr: "Modifier",
			sp: "Editar",
			ar: "تصحيح",
			tur: "Düzenle",
			pers: "ویرایش",
			ch: "编辑"
		},
		write: {
			en: "Write",
			ru: "Написать",
			hy: "Գրել",
			fr: "Écrire",
			sp: "Escribir",
			ar: "كتابة",
			tur: "Yazmak",
			pers: "نوشتن",
			ch: "写"
		},
		selectSomething: {
			en: "Select something",
			ru: "Выберите что-нибудь",
			hy: "Ընտրեք մի բան",
			fr: "Sélectionnez quelque chose",
			sp: "Seleccione algo",
			ar: "اختر شيئا",
			tur: "Bir şey seç",
			pers: "چیزی انتخاب کنید",
			ch: "选择某事"
		},
		close: {
			en: "Close",
			ru: "Закрыть",
			hy: "Փակել",
			fr: "Fermer",
			sp: "Cerrar",
			ar: "أغلق",
			tur: "Kapat",
			pers: "بستن",
			ch: "关"
		},
		index: {
			en: "Index",
			ru: "Индекс",
			hy: "Ինդեքս",
			fr: "Indice",
			sp: "Índice",
			ar: "فهرس",
			tur: "Dizin",
			pers: "نمایه",
			ch: "索引"
		},
		developed: {
			en: "Developed",
			ru: "Разработанно",
			hy: "Ստեղծված է",
			fr: "Développé",
			sp: "Desarrollado",
			ar: "مطور",
			tur: "Geliştirilmiş",
			pers: "توسعه یافته",
			ch: "发展"
		},
		version: {
			en: "Version",
			ru: "Версия",
			hy: "Տարբերակ",
			fr: "Version",
			sp: "Versión",
			ar: "إصدار",
			tur: "Versiyon",
			pers: "نسخه",
			ch: "版本"
		},
		mainInformation: {
			en: "Basic information",
			ru: "Основная информация",
			hy: "Հիմնական տեղեկատվություն",
			fr: "Informations de base",
			sp: "Información básica",
			ar: "معلومات أساسية",
			tur: "Temel bilgi",
			pers: "اطلاعات اصلی",
			ch: "基本信息"
		},
		city: {
			en: "City",
			ru: "Город",
			hy: "Քաղաք",
			fr: "Ville",
			sp: "Ciudad",
			ar: "مدينة",
			tur: "Şehir",
			pers: "شهر",
			ch: "城市"
		},
		voltage: {
			en: "Voltage",
			ru: "Напряжение",
			hy: "Լարման մակարդակ",
			fr: "Tension",
			sp: "Voltaje",
			ar: "الجهد",
			tur: "Gerilim",
			pers: "ولتاژ",
			ch: "电压"
		},
		workingMinutes: {
			en: "Working Minutes",
			ru: "Рабочие минуты",
			hy: "Աշխատանքային րոպեները",
			fr: "Minutes de travail",
			sp: "Minutos de trabajo",
			ar: "دقائق العمل",
			tur: "Çalışma Dakikaları",
			pers: "دقایق کاری",
			ch: "工作分钟"
		},
		processTime: {
			en: "Process Time",
			ru: "Время обработки",
			hy: "Ընթացիկ ժամանակը",
			fr: "Temps de traitement",
			sp: "Tiempo de proceso",
			ar: "وقت المعالجة",
			tur: "İşlem Süresi",
			pers: "زمان پردازش",
			ch: "处理时间"
		},
		detailedSteps: {
			en: "Detailed Steps",
			ru: "Детальные шаги",
			hy: "Մանրամասն քայլերը",
			fr: "Étapes détaillées",
			sp: "Pasos detallados",
			ar: "خطوات مفصلة",
			tur: "Detaylı Adımlar",
			pers: "مراحل دقیق",
			ch: "详细步骤"
		},
		inProgress: {
			en: "In Progress",
			ru: "В процессе",
			hy: "Ընթացիկ",
			fr: "En cours",
			sp: "En progreso",
			ar: "قيد الإجراء",
			tur: "Devam etmekte",
			pers: "در حال اجرا",
			ch: "进行中"
		},
		id: {
			en: "ID",
			ru: "ID",
			hy: "ID",
			fr: "ID",
			sp: "ID",
			ar: "ID",
			tur: "ID",
			pers: "ID",
			ch: "ID"
		},
		updatedAt: {
			en: "Updated At",
			ru: "Обновлено",
			hy: "Թարմացված է",
			fr: "Mis à jour",
			sp: "Actualizado",
			ar: "تحديث",
			tur: "Güncelleme",
			pers: "به روز شده",
			ch: "更新"
		},
		createdAt: {
			en: "Created At",
			ru: "Создано",
			hy: "Ստեղծվել է",
			fr: "Créé",
			sp: "Creado",
			ar: "خلق",
			tur: "Oluşturuldu",
			pers: "ایجاد شده",
			ch: "创建"
		},
		projectDetails: {
			en: "Project Details",
			ru: "Детали проекта",
			hy: "Նախագծի մանրամասներ",
			fr: "Détails du projet",
			sp: "Detalles del proyecto",
			ar: "تفاصيل المشروع",
			tur: "Proje Detayları",
			pers: "جزئیات پروژه",
			ch: "项目详情"
		},
		createdByUser: {
			en: "Created By User",
			ru: "Создано пользователем",
			hy: "Ստեղծված է օգտվողի կողմից",
			fr: "Créé par l'utilisateur",
			sp: "Creado por el usuario",
			ar: "تم إنشاؤه بواسطة المستخدم",
			tur: "Kullanıcı Tarafından Oluşturuldu",
			pers: "ایجاد شده توسط کاربر",
			ch: "由用户创建"
		},
		addFiles: {
			en: "Add Files",
			ru: "Добавить файлы",
			hy: "Ավելացնել ֆայլեր",
			fr: "Ajouter des fichiers",
			sp: "Agregar archivos",
			ar: "إضافة ملفات",
			tur: "Dosyaları Ekle",
			pers: "افزودن فایل",
			ch: "添加文件"
		},
		low: {
			en: "Low",
			ru: "Низкий",
			hy: "Ցածր",
			fr: "Faible",
			sp: "Bajo",
			ar: "منخفض",
			tur: "Düşük",
			pers: "پایین",
			ch: "低"
		},
		high: {
			en: "High",
			ru: "Высокий",
			hy: "Բարձր",
			fr: "Haute",
			sp: "Alto",
			ar: "عالي",
			tur: "Yüksek",
			pers: "بالا",
			ch: "高"
		},
		medium: {
			en: "Medium",
			ru: "Средний",
			hy: "Միջին",
			fr: "Moyen",
			sp: "Medio",
			ar: "متوسط",
			tur: "Orta",
			pers: "متوسط",
			ch: "中"
		},
		undefined: {
			en: "Undefined",
			ru: "Не определено",
			hy: "Սահմանված չէ",
			fr: "Non défini",
			sp: "No definido",
			ar: "غير معروف",
			tur: "Tanımsız",
			pers: "تعریف نشده",
			ch: "未定义"
		},
		cancelBack: {
			en: "Cancel back",
			ru: "Отменить обратно",
			hy: "Հետ",
			fr: "Annuler en arrière",
			sp: "Cancelar de nuevo",
			ar: "إلغاء العودة",
			tur: "Geri iptal",
			pers: "لغو به عقب",
			ch: "取消返回"
		},
		removeFromList: {
			en: "Remove from list",
			ru: "Удалить из списка",
			hy: "Ջնջել ցուցակից",
			fr: "Supprimer de la liste",
			sp: "Eliminar de la lista",
			ar: "إزالة من القائمة",
			tur: "Listeden kaldır",
			pers: "حذف از لیست",
			ch: "从列表中删除"
		},
		users: {
			en: "Users",
			ru: "Пользователи",
			hy: "Օգտատերեր",
			fr: "Utilisateurs",
			sp: "Usuarios",
			ar: "المستخدمين",
			tur: "Kullanıcılar",
			pers: "کاربران",
			ch: "用户"
		},
		workingDays: {
			en: "Working Days",
			ru: "Рабочие дни",
			hy: "Աշխատանքային օրեր",
			fr: "Jours ouvrables",
			sp: "Días laborables",
			ar: "أيام العمل",
			tur: "Çalışma Günleri",
			pers: "روزهای کاری",
			ch: "工作日"
		},
		deadLine: {
			en: "Dead Line",
			ru: "Срок",
			hy: "Վերջնաժամկետ",
			fr: "Délai",
			sp: "Fecha límite",
			ar: "آخر",
			tur: "Son Tarih",
			pers: "مهلت",
			ch: "最后期限"
		},
		history: {
			en: "History",
			ru: "История",
			hy: "Պատմություն",
			fr: "Histoire",
			sp: "Historia",
			ar: "تاريخ",
			tur: "Tarih",
			pers: "تاریخچه",
			ch: "历史"
		},
		edited: {
			en: "Edited",
			ru: "Редактировано",
			hy: "Խմբագրված է",
			fr: "Édité",
			sp: "Editado",
			ar: "تم التعديل",
			tur: "Düzenlendi",
			pers: "ویرایش شده",
			ch: "编辑"
		},
		added: {
			en: "Added",
			ru: "Добавлено",
			hy: "Ավելացված է",
			fr: "Ajouté",
			sp: "Añadido",
			ar: "أضيف",
			tur: "Eklendi",
			pers: "افزوده شده",
			ch: "加"
		},
		end: {
			en: "End",
			ru: "Конец",
			hy: "Ավարտ",
			fr: "Fin",
			sp: "Fin",
			ar: "نهاية",
			tur: "Son",
			pers: "پایان",
			ch: "结束"
		},
		openDate: {
			en: "Date opened",
			ru: "Дата открытия",
			hy: "Ստեղծման ամսաթիվը",
			fr: "Date d'ouverture",
			sp: "Fecha de apertura",
			ar: "تاريخ الافتتاح",
			tur: "Açılış tarihi",
			pers: "تاریخ باز شدن",
			ch: "开放日期"
		},
		selectOpenedDate: {
			en: "Select opened date",
			ru: "Выберите дату открытия",
			hy: "Ընտրեք ստեղծման ամսաթիվը",
			fr: "Sélectionner la date d'ouverture",
			sp: "Seleccione la fecha de apertura",
			ar: "حدد تاريخ الافتتاح",
			tur: "Açılış tarihini seçin",
			pers: "تاریخ باز شدن را انتخاب کنید",
			ch: "选择开放日期"
		},
		next: {
			en: "Next",
			ru: "Следующий",
			hy: "Հաջորդ",
			fr: "Prochain",
			sp: "Siguiente",
			ar: "التالى",
			tur: "Sonraki",
			pers: "بعدی",
			ch: "下一个"
		},
		orderNumber: {
			en: "Order number",
			ru: "Номер заказа",
			hy: "Պատվերի համար",
			fr: "Numéro de commande",
			sp: "Número de orden",
			ar: "رقم الطلب",
			tur: "Sipariş numarası",
			pers: "شماره سفارش",
			ch: "订单号"
		},
		priority: {
			en: "Proirity",
			ru: "Приоритет",
			hy: "Կարևորություն",
			fr: "Priorité",
			sp: "Prioridad",
			ar: "أولوية",
			tur: "Öncelik",
			pers: "اولویت",
			ch: "优先"
		},
		saved: {
			en: "Saved",
			ru: "Сохранено",
			hy: "Պահպանվել է",
			fr: "Enregistré",
			sp: "Guardado",
			ar: "تم الحفظ",
			tur: "Kaydedildi",
			pers: "ذخیره شده",
			ch: "保存"
		},
		projectDeadline: {
			en: "Project Deadline",
			ru: "Срок проекта",
			hy: "Նախագծի վերջնաժամկետ",
			fr: "Date limite du projet",
			sp: "Fecha límite del proyecto",
			ar: "موعد تسليم المشروع",
			tur: "Proje Son Tarihi",
			pers: "مهلت پروژه",
			ch: "项目截止日期"
		},
		yes: {
			en: "Yes",
			ru: "Да",
			hy: "Այո",
			fr: "Oui",
			sp: "Sí",
			ar: "نعم",
			tur: "Evet",
			pers: "بله",
			ch: "是"
		},
		removed: {
			en: "Removed",
			ru: "Удалено",
			hy: "Ջնջելվել է",
			fr: "Supprimé",
			sp: "Eliminado",
			ar: "تمت الإزالة",
			tur: "Kaldırıldı",
			pers: "حذف شده",
			ch: "已删除"
		},
		areYouSure: {
			en: "Are you sure?",
			ru: "Вы уверенны ?",
			hy: "Դուք համոզված եք ?",
			fr: "Êtes-vous sûr?",
			sp: "¿Estás seguro?",
			ar: "هل أنت متأكد؟",
			tur: "Emin misiniz?",
			pers: "آیا مطمئن هستید؟",
			ch: "你确定吗？"
		},
		youCannotRevertThisAction: {
			en: "You cannot revert this action!",
			ru: "Вы не можете вернуть это действие!",
			hy: "Դուք չեք կարող հետ բերել այս գործողությունը հետագայում!",
			fr: "Vous ne pouvez pas annuler cette action!",
			sp: "¡No puedes revertir esta acción!",
			ar: "لا يمكنك عكس هذا الإجراء!",
			tur: "Bu eylemi geri alamazsınız!",
			pers: "شما نمی توانید این عمل را برگردانید!",
			ch: "您无法撤消此操作！"
		},
		moreDetailed: {
			en: "More detailed",
			ru: "Подробнее",
			hy: "Մանրամասն",
			fr: "Plus détaillé",
			sp: "Más detallado",
			ar: "أكثر تفصيلا",
			tur: "Daha detaylı",
			pers: "بیشتر جزییات",
			ch: "更详细"
		},
		relatedList: {
			en: "Related List",
			ru: "Связанный список",
			hy: "Համապատասխան ցուցակը",
			fr: "Liste connexe",
			sp: "Lista relacionada",
			ar: "قائمة متصلة",
			tur: "İlgili Liste",
			pers: "لیست مرتبط",
			ch: "相关列表"
		},
		duration: {
			en: "Duration",
			ru: "Продолжительность",
			hy: "Տևողություն",
			fr: "Durée",
			sp: "Duración",
			ar: "المدة",
			tur: "Süre",
			pers: "مدت",
			ch: "持续时间"
		},
		problemDescription: {
			en: "Problem Description",
			ru: "Описание проблемы",
			hy: "Խնդիրի նկարագիր",
			fr: "Description du problème",
			sp: "Descripción del problema",
			ar: "وصف المشكلة",
			tur: "Sorun Açıklaması",
			pers: "توضیح مشکل",
			ch: "问题描述"
		},
		openedDate: {
			en: "Open Date",
			ru: "Дата открытия",
			hy: "Բացման ամսաթիվը",
			fr: "Date d'ouverture",
			sp: "Fecha de apertura",
			ar: "تاريخ الافتتاح",
			tur: "Açılış Tarihi",
			pers: "تاریخ باز",
			ch: "开放日期"
		},
		problem: {
			en: "Problem",
			ru: "Проблема",
			hy: "Խնդիր",
			fr: "Problème",
			sp: "Problema",
			ar: "مشكلة",
			tur: "Sorun",
			pers: "مشکل",
			ch: "问题"
		},
		comment: {
			en: "Comment",
			ru: "Комментарий",
			hy: "Մեկնաբանություն",
			fr: "Commentaire",
			sp: "Comentario",
			ar: "تعليق",
			tur: "Yorum",
			pers: "نظر",
			ch: "评论"
		},
		date: {
			en: "Date",
			ru: "Дата",
			hy: "Ամսաթիվ",
			fr: "Date",
			sp: "Fecha",
			ar: "تاريخ",
			tur: "Tarih",
			pers: "تاریخ",
			ch: "日期"
		}
	},
	menu: [
		{
			name: {
				en: "Homepage",
				ru: "Главная страница",
				hy: "Գլխավոր էջ",
				fr: "Page d'accueil",
				sp: "Página de inicio",
				ar: "الصفحة الرئيسية",
				tur: "Ana Sayfa",
				pers: "صفحه اصلی",
				ch: "主页"
			},
			icon: "city-buildings-svgrepo-com.svg",
			users: ["admin", "manager"],
			subMenu: [
				{
					name: {
						en: "Intro",
						ru: "Вступление",
						hy: "Ներկայացում",
						fr: "Introduction",
						sp: "Introducción",
						ar: "مقدمة",
						tur: "Giriş",
						pers: "مقدمه",
						ch: "介绍"
					},
					icon: "city-buildings-svgrepo-com.svg",
					users: ["admin", "manager"],
					route: "/homepage-intro"
				},
				{
					name: {
						en: "Steps",
						ru: "Шаги",
						hy: "Քայլեր",
						fr: "Étapes",
						sp: "Pasos",
						ar: "خطوات",
						tur: "Adımlar",
						pers: "مراحل",
						ch: "步骤"
					},
					icon: "city-buildings-svgrepo-com.svg",
					users: ["admin", "manager"],
					route: "/homepage-steps"
				},
				{
					name: {
						en: "Why we",
						ru: "Почему мы",
						hy: "Ինչու մենք",
						fr: "Pourquoi nous",
						sp: "Por qué nosotros",
						ar: "لماذا نحن",
						tur: "Biz Neden",
						pers: "چرا ما",
						ch: "为什么我们"
					},
					icon: "city-buildings-svgrepo-com.svg",
					users: ["admin", "manager"],
					route: "/homepage-whywe"
				}
			]
		},
		{
			name: {
				en: "About Us",
				ru: "О нас",
				hy: "Մեր մասին",
				fr: "À propos de nous",
				sp: " Sobre nosotros",
				ar: "حولنا",
				tur: "Hakkımızda",
				pers: "درباره ما",
				ch: "关于我们"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "aboutus",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Blog",
				ru: "Блог",
				hy: "Բլոգ",
				fr: "Blog",
				sp: "Blog",
				ar: "بلوج",
				tur: "Blog",
				pers: "بلاگ",
				ch: "博客"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "blog",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Client",
				ru: "Клиент",
				hy: "Հաճախորդ",
				fr: "Client",
				sp: "Cliente",
				ar: "العميل",
				tur: "müşteri",
				pers: "مشتری",
				ch: "客户"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "client",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Contact",
				ru: "Контакт",
				hy: "Կապ",
				fr: "Contact",
				sp: "Contacto",
				ar: "اتصل بنا",
				tur: "İletişim",
				pers: "تماس با ما",
				ch: ""
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "contact",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Contact Form",
				ru: "Форма контакта",
				hy: "Կապի ձևը",
				fr: "Formulaire de contact",
				sp: "Formulario de contacto",
				ar: "نموذج الاتصال",
				tur: "İletişim Formu",
				pers: "فرم تماس",
				ch: "联系表格"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "contact-form",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Contact parts",
				ru: "Контактные части",
				hy: "Կոնտակտային մասեր",
				fr: "Pièces de contact",
				sp: "Partes de contacto",
				ar: "أجزاء الاتصال",
				tur: "İletişim parçaları",
				pers: "قطعات تماس",
				ch: "联系部分"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "contact-part",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Contact socials",
				ru: "Контактные социальные",
				hy: "Կոնտակտային սոցիալական",
				fr: "Contacts sociaux",
				sp: "Contactos sociales",
				ar: "جهات الاتصال الاجتماعية",
				tur: "Sosyal iletişim",
				pers: "تماس های اجتماعی",
				ch: "社交联系"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "contact-social",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Map",
				ru: "Карта",
				hy: "Քարտեզ",
				fr: "Carte",
				sp: "Mapa",
				ar: "خريطة",
				tur: "Harita",
				pers: "نقشه",
				ch: "地图"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "map",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Services",
				ru: "Услуги",
				hy: "Ծառայություններ",
				fr: "Services",
				sp: "Servicios",
				ar: "خدمات",
				tur: "Hizmetler",
				pers: "خدمات",
				ch: "服务"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "service",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Valute",
				ru: "Валюта",
				hy: "Արժույթ",
				fr: "Devise",
				sp: "Moneda",
				ar: "عملة",
				tur: "Para birimi",
				pers: "ارز",
				ch: "货币"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "valute",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Terms and conditions",
				ru: "Условия обслуживания",
				hy: "Սպասարկման պայմաններ",
				fr: "Conditions de service",
				sp: "Términos de servicio",
				ar: "شروط الخدمة",
				tur: "Hizmet şartları",
				pers: "شرایط خدمات",
				ch: "服务条款"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "terms-and-conditions",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "Privacy policy",
				ru: "Политика конфиденциальности",
				hy: "Գաղտնիության քաղաքականություն",
				fr: "Politique de confidentialité",
				sp: "Política de privacidad",
				ar: "سياسة الخصوصية",
				tur: "Gizlilik politikası",
				pers: "سیاست حفظ حریم خصوصی",
				ch: "隐私政策"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "privacy-policy",
			users: ["admin", "manager"]
		},
		{
			name: {
				en: "User",
				ru: "Пользователь",
				hy: " Օգտատեր",
				fr: " Utilisateur",
				sp: "Usuario",
				ar: "المستخدم",
				tur: "Kullanıcı",
				pers: "کاربر",
				ch: "用户"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "user",
			users: ["admin", "manager"]
		},
		// order
		{
			name: {
				en: "Order",
				ru: "Заказ",
				hy: "Պատվեր",
				fr: "Commande",
				sp: "Orden",
				ar: "طلب",
				tur: "Sipariş",
				pers: "سفارش",
				ch: "订购"
			},
			icon: "city-buildings-svgrepo-com.svg",
			route: "order",
			users: ["admin", "manager"]
		}
	],
	notificationSettings: {
		position: "top-right",
		autoClose: 2500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		save: function (state, lang) {
			if (state === "success") {
				return {
					title: "saved",
					message: "yourFileHasBeenSaved",
					autoClose: this.autoClose,
					color: "green"
				};
			}
			if (state === "error") {
				return {
					title: "error",
					message: "yourFileHasBeenNotSaved",
					color: "red"
				};
			}
		},
		delete: function (state, lang) {
			if (state === "success") {
				return {
					title: "deleted",
					message: "yourFileHasBeenDeleted",
					autoClose: this.autoClose,
					color: "green"
				};
			}
			if (state === "error") {
				return {
					title: "error",
					message: "yourFileHasBeenNotSaved",
					color: "red"
				};
			}
		}
	}
};

export default config;
