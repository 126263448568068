import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbsComponent } from "./breadcrumbs.jsx";
import {
  createTheme,
  MantineProvider,
  AppShell,
  Burger,
  Image,
  Card,
  ScrollArea,
  Flex,
  Breadcrumbs,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Sidebar from "./sidebar";
import Loading from "../modules/loading";

export default function Main({ component: Component, user, ...rest }) {
  let [isBusy, setIsBusy] = useState(true);
  const [opened, { toggle }] = useDisclosure();

  useEffect(() => {
    (async () => {
      await setIsBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) {
    return <Loading />;
  } else {
    return (
      <>
        <AppShell
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
          padding="md"
        >
          <AppShell.Header>
            <Flex justify="space-between" align="center" p={20} h={"100%"}>
              <Burger
                opened={opened}
                onClick={toggle}
                hiddenFrom="sm"
                size="sm"
              />
              <Link to="/">
                <Image src="/logo.svg" alt="Mantine logo" height={40} />
              </Link>
              <BreadcrumbsComponent m={10} style={{ textDecoration: "none" }} />
            </Flex>
          </AppShell.Header>

          <AppShell.Navbar p="md">
            <Sidebar />
          </AppShell.Navbar>

          <AppShell.Main
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100vh - 92px)",
            }}
          >
            <Component user={user} />
          </AppShell.Main>
        </AppShell>
      </>
    );
  }
}
