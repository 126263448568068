import React, { useMemo, useEffect } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import moment from "moment";
import { Avatar, Box, Button, Flex, Menu, Text, Title } from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import { Image, useMantineColorScheme } from "@mantine/core";

const parsePhoneNumber = (number) =>
  `+374${number
    .split(/[-*\/\s]+/)
    .join("")
    .slice(1)}`;

function calculateAge(birthdate) {
  const today = moment();
  const birthdateMoment = moment(birthdate, moment.ISO_8601, true);

  if (!birthdateMoment.isValid()) {
    return "Неверная дата";
  }

  const years = today.diff(birthdateMoment, "years");

  birthdateMoment.add(years, "years");
  const isBirthday = today.isSame(birthdateMoment, "day");

  const nextBirthday = birthdateMoment.clone().add(1, "year");

  const upcomingBirthdayDays = nextBirthday.diff(today, "days");

  let formattedResult;

  if ([1, 2, 3, 4].includes(years % 10) && !(years >= 11 && years <= 14)) {
    if (isBirthday) {
      formattedResult = `<div class="asdasdas"><img class="birthday-icon" style="margin-right: 15px; margin-left:0;" src="/assets/birthday-cake-svgrepo-com.svg"><span>${years} года | ${moment(
        birthdate,
        moment.ISO_8601,
        true
      ).format("DD.MM.YYYY")}</span></div>`;
    } else {
      if (upcomingBirthdayDays < 7) {
        formattedResult = `<div class="asdasdas"><img class="birthday-icon" style="margin-right: 15px; margin-left:0;" src="/assets/birthday-cake-svgrepo-com.svg"><span>${
          upcomingBirthdayDays + 1
        } дней | ${years} года | ${moment(
          birthdate,
          moment.ISO_8601,
          true
        ).format("DD.MM.YYYY")}</span></div>`;
      } else {
        formattedResult = `<div class="asdasdas"><span>${years} года | ${moment(
          birthdate,
          moment.ISO_8601,
          true
        ).format("DD.MM.YYYY")}</span></div>`;
      }
    }
  } else {
    if (isBirthday) {
      formattedResult = `<div class="asdasdas"><img class="birthday-icon" style="margin-right: 15px; margin-left:0;" src="/assets/birthday-cake-svgrepo-com.svg"><span>${years} лет | ${moment(
        birthdate,
        moment.ISO_8601,
        true
      ).format("DD.MM.YYYY")}</span></div>`;
    } else {
      if (upcomingBirthdayDays < 7) {
        formattedResult = `<div class="asdasdas"><img class="birthday-icon" style="margin-right: 15px; margin-left:0;" src="/assets/birthday-cake-svgrepo-com.svg"><span>${
          upcomingBirthdayDays + 1
        } дней | ${years} лет | ${moment(
          birthdate,
          moment.ISO_8601,
          true
        ).format("DD.MM.YYYY")}</span></div>`;
      } else {
        formattedResult = `<div class="asdasdas"><span>${years} лет | ${moment(
          birthdate,
          moment.ISO_8601,
          true
        ).format("DD.MM.YYYY")}</span></div>`;
      }
    }
  }

  return parse(typeof formattedResult === "string" ? formattedResult : "");
}

const customColors = {
  titleColor: "#2ea7c1",
  textColor: "#666",
};

export default function List() {
  const { user } = useUser();
  const { lang } = useLang();
  let { userWorkingStatus } = useParams();
  const { colorScheme } = useMantineColorScheme();

  const columns = [
    {
      accessorKey: "index",
      header: config.translate.index[lang],
      enableClickToCopy: true,
      size: 3,
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = row.index ? row.index : "...";
        return result;
      },
    },
    {
      accessorFn: (row) =>
        `${row.firstName.en} ${row.firstName.ru} ${row.firstName.hy}`,
      header: config.translate.firstName[lang],
      filterVariant: "multi-select",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.firstName[lang] ? row.firstName[lang] : "...");
        return result;
      },
    },
    {
      accessorFn: (row) =>
        `${row.lastName.en} ${row.lastName.ru} ${row.lastName.hy}`,
      header: config.translate.lastName[lang],
      filterVariant: "multi-select",
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.lastName[lang] ? row.lastName[lang] : "...");
        return result;
      },
    },
    {
      accessorKey: "dateOfBirth",
      header: config.translate.dateOfBirth[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = calculateAge(row.dateOfBirth);
        return result;
      },
    },
    {
      accessorKey: "phone",
      enableColumnActions: false,
      enableColumnDragging: false,
      header: config.translate.more[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = "";
        if (row.phone && row.phone?.length > 0) {
          result = (
            <div
              className="icon-comment-wrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <a href={`tel:${parsePhoneNumber(row.phone)}`}>
                <Image
                  w="20"
                  h="20"
                  className="icon-comment"
                  src="/assets/phone-call-svgrepo-com.svg"
                  alt="phone"
                  style={{ filter: colorScheme === "dark" ? "invert(1)" : "" }}
                />
              </a>
              <a href={`mailto:${row.email}`}>
                <Image
                  w="20"
                  h="20"
                  className="icon-comment"
                  src="/assets/email-svgrepo-com.svg"
                  alt="email"
                  style={{ filter: colorScheme === "dark" ? "invert(1)" : "" }}
                />
              </a>
            </div>
          );
        }
        return result;
      },
    },
  ];

  let renderDetailPanel = ({ row }) => (
    <Box
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "16px",
        padding: "16px",
      }}
    >
      <Link to={`/user/${row.original._id}`}>
        <Avatar
          variant="filled"
          radius="sm"
          h={200}
          w={200}
          src={`${config.api.API_URL}/${row.original.uploads?.[0]?.path}`}
        />
      </Link>
      <Box sx={{ textAlign: "center" }}>
        <Flex justify="flex-center">
          <Title style={{ color: customColors.titleColor }}>
            {row.original.name[lang]}
          </Title>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.position[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {row.original.position?.name[lang]}
          </Text>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.email[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {row.original.email}
          </Text>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.phone[lang]}
          </Text>
          <Text style={{ color: customColors.textColor }}>
            : {row.original.phone}
          </Text>
        </Flex>
        <Flex justify="flex-start">
          <Text fw={700} style={{ color: customColors.textColor }}>
            {config.translate.dateOfBirth[lang]} :
          </Text>
          <Text style={{ color: customColors.textColor }}>
            {calculateAge(row.original.dateOfBirth)}
          </Text>
        </Flex>
      </Box>
    </Box>
  );

  return (
    <ListPage
      route={config.api.user}
      query={user.role === "admin" ? {} : { createdByUser: user._id }}
      sorting={{ index: "asc" }}
      uploadType={true}
      limit={null}
      columns={columns}
      renderDetailPanel={renderDetailPanel}
      enableFacetedValues={true}
      enableColumnOrdering={true}
    />
  );
}
