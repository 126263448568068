import React, { useMemo } from "react";
import { ListPage } from "../../ListPage";
import { useUser } from "../../../helpers/userContext";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import config from "../../../config";

export default function List() {
  const { user } = useUser();
  const { lang } = useLang();
  const columns = [
    {
      accessor: "index",
      header: config.translate.index[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = row.index ? row.index : "...";
        return result;
      },
    },
    {
      accessorFn: (row) =>
        `${row.title.en} ${row.title.ru} ${row.title.hy} ${row.title.fr} ${row.title.sp}  ${row.title.ar} ${row.title.tur} ${row.title.pers}${row.title.ch}`,
      header: config.translate.name[lang],
      Cell: (
        RecievedObject,
        cell = RecievedObject.renderedCellValue,
        row = RecievedObject.row.original
      ) => {
        let result = parse(row.title[lang] ? row.title[lang] : "...");
        return result;
      },
    },
  ];
  return (
    <ListPage
      route={config.api.homepage.whywe}
      query={user.role === "admin" ? {} : { createdByUser: user._id }}
      sorting={{ index: "asc" }}
      uploadType={true}
      limit={null}
      columns={columns}
    />
  );
}
