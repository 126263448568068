import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../helpers/auth";
import config from "../../config";
import { ActionToggle } from "../../helpers/actionToggle/actionToggle";
import { useUser } from "../../helpers/userContext";
import { useLang, LanguagePicker } from "../../helpers/language";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight, IconFolder } from "@tabler/icons-react";
import { useViewportSize } from "@mantine/hooks";
import {
  Avatar,
  Text,
  Group,
  Card,
  Grid,
  Modal,
  Button,
  List,
  Image,
  Flex,
  NavLink,
  ScrollArea,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconPhoneCall,
  IconAt,
  IconLogout,
  IconSettings,
} from "@tabler/icons-react";
import classes from "./UserInfoIcons.module.css";

export default function Sidebar() {
  const { logout } = useAuth();
  const location = useLocation();
  const { user } = useUser();
  const { lang } = useLang();
  const history = useHistory();
  const [opened, { open, close }] = useDisclosure(false);
  const { colorScheme } = useMantineColorScheme();
  const { height, width } = useViewportSize();
  const [active, setActive] = useState(0);
  const [subActive, setSubActive] = useState(0);

  return (
    <div className="sidebar">
      <Card shadow="sm" padding="lg">
        <Grid>
          <Modal
            opened={opened}
            onClose={close}
            title={config.translate.settings[lang]}
            centered
          >
            <Card withBorder shadow="sm" radius="md">
              <Card.Section withBorder inheritPadding py="xs">
                {config.translate.languagePickerLabel[lang]}
              </Card.Section>
              <Card.Section withBorder inheritPadding py="xs">
                <LanguagePicker />
              </Card.Section>
            </Card>

            <Card withBorder shadow="sm" radius="md" mt={20}>
              <Card.Section withBorder inheritPadding py="xs">
                {config.translate.themePickerLabel[lang]}
              </Card.Section>
              <Card.Section withBorder inheritPadding py="xs">
                <ActionToggle />
              </Card.Section>
            </Card>
          </Modal>
          <Grid.Col span={5}>
            <Avatar
              src={`${config.api.API_URL}/${user.uploads?.path}`}
              size={94}
              radius="md"
            />
          </Grid.Col>
          <Grid.Col span={7}>
            <Flex wrap="nowrap" mt={1} direction={"column"}>
              <Button
                justify="center"
                width
                onClick={logout}
                p={5}
                leftSection={
                  <IconLogout
                    stroke={1.5}
                    size="1rem"
                    className={classes.icon}
                    color={colorScheme === "dark" ? "white" : "black"}
                  />
                }
                variant="default"
              >
                {config.translate.logOut[lang]}
              </Button>

              <Button
                justify="center"
                width
                onClick={open}
                p={5}
                leftSection={
                  <IconSettings
                    stroke={1.5}
                    size="1rem"
                    className={classes.icon}
                    color={colorScheme === "dark" ? "white" : "black"}
                  />
                }
                variant="default"
                mt={10}
              >
                {config.translate.settings[lang]}
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12}>
            <div>
              <Text fz="lg" fw={500} className={classes.name}>
                {user.name?.[lang]}
              </Text>
              <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                {user.position?.name?.[lang]}
              </Text>
            </div>
          </Grid.Col>
        </Grid>
      </Card>

      <Card shadow="sm" padding="" mt={20}>
        <ScrollArea h={height - 40 - 267}>
          {config.menu.flatMap((item, index) => {
            return (
              <NavLink
                label={item.name[lang]}
                key={index}
                childrenOffset={0}
                onClick={() => {
                  setActive(index);
                  if (!item.subMenu) {
                    return history.push(`/${item.route}/`);
                  }
                }}
                leftSection={<IconFolder size="1.5rem" stroke={1.5} />}
                rightSection={
                  item.subMenu && (
                    <IconChevronRight
                      size="0.8rem"
                      stroke={1.5}
                      className="mantine-rotate-rtl"
                    />
                  )
                }
                variant="subtle"
                active={index === active}
              >
                {item.subMenu &&
                  item.subMenu.flatMap((subItem, subIndex) => {
                    return (
                      <NavLink
                        label={
                          subItem.name[lang]?.charAt(0).toUpperCase() +
                          subItem.name[lang]?.slice(1)
                        }
                        onClick={() => {
                          setSubActive(subIndex);
                          history.push(`${subItem.route}/`);
                        }}
                        leftSection={
                          <Image
                            src={`/assets/${subItem.icon}`}
                            alt="navigation-icon"
                            w={20}
                            h={20}
                            style={{
                              filter: `invert(${
                                colorScheme === "dark" ? 1 : 0
                              })`,
                            }}
                          />
                        }
                        active={subIndex === subActive}
                        key={subIndex}
                      />
                    );
                  })}
              </NavLink>
            );
          })}
        </ScrollArea>
      </Card>
    </div>
  );
}
