import React, { useState, useRef, useEffect } from "react";
import { useLang } from "../../helpers/language";
import { useDisclosure } from "@mantine/hooks";
import config from "../../config";

import {
  Modal,
  Button,
  Flex,
  Menu,
  CloseIcon,
  Container,
  MantineProvider,
  useMantineTheme,
  Card,
  Input,
  Text,
  CloseButton,
} from "@mantine/core";
import { useLocation } from "react-router-dom";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
} from "mantine-react-table";

export function ToDoComponent(component) {
  const { lang } = useLang();
  const [opened, { open, close }] = useDisclosure(false);
  const [tempArray, setTempArray] = useState(component.value || []);
  const [tempFields, setTempFields] = useState([]);
  const [object, setObject] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  let formatedLocation = location.search
    .replace("?", "")
    .split("&")
    .reduce((a, v) => ({ ...a, [v.split("=")[0]]: v.split("=")[1] }), {});
  const [pagination, setPagination] = useState({
    pageIndex: formatedLocation.pageIndex
      ? Number(formatedLocation.pageIndex)
      : 0,
    pageSize: Number(
      formatedLocation.pageSize ? formatedLocation.pageSize : 10
    ), //customize the default page size
  });

  const saveItem = async () => {
    component.setter([...component.value, object]);
    close();
  };

  useEffect(() => {
    if (component.value) {
      setTempArray(component.value);
    }
  }, [component.value]);

  useEffect(() => {
    let tempObject = {};
    setTempFields(
      component.columns.map((item) => {
        tempObject = { ...tempObject, [item.accessorKey]: "" };
        return {
          header: item.header,
          accessorKey: item.accessorKey,
        };
      })
    );
    setObject(tempObject);
  }, []);

  const table = useMantineReactTable({
    columns: component.columns,
    data: [...tempArray],
    state: { isLoading },
    initialState: {
      sorting: [],
      filters: [],
      grouping: [],
      pinnedColumns: [],
      hiddenColumns: [],
      selectedRows: [],
      searchQuery: "",
      showColumnFilters: false,
      showGlobalFilter: true,
      pagination: {
        pageIndex: Number(formatedLocation.pageIndex),
        pageSize: Number(formatedLocation.pageSize),
      },
    },
    paginationDisplayMode: "pages",
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
    manualPagination: true,
    onPaginationChange: setPagination,
    renderTopToolbar: ({ table }) => {
      return (
        <Flex p="md" justify="space-between">
          <Flex gap="xs">{component.title?.[lang]}</Flex>
          <Flex sx={{ gap: "8px" }}>
            <Button color="green" onClick={open} variant="filled">
              {config.translate.add[lang]}
            </Button>
          </Flex>
        </Flex>
      );
    },
  });

  return (
    <div mt={20} mb={20}>
      <Modal opened={opened} onClose={close} title="Authentication" centered>
        {tempFields.map((field, index) => {
          return (
            <>
              <Text size="sm" weight={500}>
                {field.header}
              </Text>
              <Input
                key={index}
                label={field.header}
                onChange={(e) => {
                  setObject({ ...object, [field.accessorKey]: e.target.value });
                }}
              />
            </>
          );
        })}
        <Button
          mt={52}
          mb={10}
          color="blue"
          variant="filled"
          fullWidth
          onClick={saveItem}
        >
          {config.translate.save[lang]}
        </Button>
      </Modal>
      <MantineReactTable table={table} />
    </div>
  );
}
