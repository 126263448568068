import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import NProgress from "nprogress";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";

export default function Page() {
	const [isBusy, setBusy] = useState(true);
	let { id } = useParams();
	const route = config.api.user;
	const { lang } = useLang();

	const [index, setindex] = useState("");

	const [username, setusername] = useState("");
	const [password, setpassword] = useState("");
	const [phone, setphone] = useState("");
	const [email, setemail] = useState("");
	const [role, setrole] = useState("");
	const [gender, setgender] = useState("");
	const [dateOfBirth, setdateOfBirth] = useState("");

	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");
	const [nameFr, setNameFr] = useState("");
	const [nameSp, setNameSp] = useState("");
	const [nameAr, setNameAr] = useState("");
	const [nameTur, setNameTur] = useState("");
	const [namePers, setNamePers] = useState("");
	const [nameCh, setNameCh] = useState("");

	const [firstNameEn, setfirstNameEn] = useState("");
	const [firstNameRu, setfirstNameRu] = useState("");
	const [firstNameHy, setfirstNameHy] = useState("");
	const [firstNameFr, setfirstNameFr] = useState("");
	const [firstNameSp, setfirstNameSp] = useState("");
	const [firstNameAr, setfirstNameAr] = useState("");
	const [firstNameTur, setfirstNameTur] = useState("");
	const [firstNamePers, setfirstNamePers] = useState("");
	const [firstNameCh, setfirstNameCh] = useState("");

	const [lastNameEn, setlastNameEn] = useState("");
	const [lastNameRu, setlastNameRu] = useState("");
	const [lastNameHy, setlastNameHy] = useState("");
	const [lastNameFr, setlastNameFr] = useState("");
	const [lastNameSp, setlastNameSp] = useState("");
	const [lastNameAr, setlastNameAr] = useState("");
	const [lastNameTur, setlastNameTur] = useState("");
	const [lastNamePers, setlastNamePers] = useState("");
	const [lastNameCh, setlastNameCh] = useState("");

	const [addressEn, setAddressEn] = useState("");
	const [addressRu, setAddressRu] = useState("");
	const [addressHy, setAddressHy] = useState("");
	const [addressFr, setAddressFr] = useState("");
	const [addressSp, setAddressSp] = useState("");
	const [addressAr, setAddressAr] = useState("");
	const [addressTur, setAddressTur] = useState("");
	const [addressPers, setAddressPers] = useState("");
	const [addressCh, setAddressCh] = useState("");

	const [image, setimage] = useState(undefined);
	const { user } = useUser();
	const [chatID, setchatID] = useState("");

	const roles = [
		{
			name: { en: "Admin", ru: "Администратор", hy: "Adminchik" }
		},
		{
			name: { en: "Manager", ru: "Менеджер", hy: "Managerhy" }
		},
		{
			name: { en: "Editor", ru: "Редактор", hy: "Managerhy" }
		}
	];
	const genders = [
		{
			name: {
				en: "Male",
				ru: "Мужской",
				hy: "Արական",
				fr: "Homme",
				sp: "Hombre",
				ar: "رجل",
				tur: "Erkek",
				pers: "مرد",
				ch: "男"
			}
		},
		{
			name: {
				en: "Female",
				ru: "Женский",
				hy: "Իգական",
				fr: "Femme",
				sp: "Mujer",
				ar: "أنثى",
				tur: "Kadın",
				pers: "زن",
				ch: "女"
			}
		}
	];

	const modelSendToServer = {
		name: {
			en: firstNameEn + " " + lastNameEn,
			ru: firstNameRu + " " + lastNameRu,
			hy: firstNameHy + " " + lastNameHy,
			fr: firstNameFr + " " + lastNameFr,
			sp: firstNameSp + " " + lastNameSp,
			ar: firstNameAr + " " + lastNameAr,
			tur: firstNameTur + " " + lastNameTur,
			pers: firstNamePers + " " + lastNamePers,
			ch: firstNameCh + " " + lastNameCh
		},
		firstName: {
			en: firstNameEn,
			ru: firstNameRu,
			hy: firstNameHy,
			fr: firstNameFr,
			sp: firstNameSp,
			ar: firstNameAr,
			tur: firstNameTur,
			pers: firstNamePers,
			ch: firstNameCh
		},
		lastName: {
			en: lastNameEn,
			ru: lastNameRu,
			hy: lastNameHy,
			fr: lastNameFr,
			sp: lastNameSp,
			ar: lastNameAr,
			tur: lastNameTur,
			pers: lastNamePers,
			ch: lastNameCh
		},
		address: {
			en: addressEn,
			ru: addressRu,
			hy: addressHy,
			fr: addressFr,
			sp: addressSp,
			ar: addressAr,
			tur: addressTur,
			pers: addressPers,
			ch: addressCh
		},
		username,
		password,
		email,
		phone,
		dateOfBirth,
		gender,
		role: role || null,
		index,
		chatID
	};

	useEffect(() => {
		NProgress.start();
		NProgress.inc();
		(async () => {
			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id
					},
					{},
					true,
					1
				);

				setindex(currentItem[0]?.index);
				setgender(currentItem[0]?.gender);

				setrole(currentItem[0]?.role);
				setusername(currentItem[0]?.username);
				setpassword(currentItem[0]?.password);
				setemail(currentItem[0]?.email);
				setphone(currentItem[0]?.phone);

				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);
				setNameFr(currentItem[0]?.name?.fr);
				setNameSp(currentItem[0]?.name?.sp);
				setNameAr(currentItem[0]?.name?.ar);
				setNameTur(currentItem[0]?.name?.tur);
				setNamePers(currentItem[0]?.name?.pers);
				setNameCh(currentItem[0]?.name?.ch);

				setfirstNameEn(currentItem[0]?.firstName?.en);
				setfirstNameRu(currentItem[0]?.firstName?.ru);
				setfirstNameHy(currentItem[0]?.firstName?.hy);
				setfirstNameFr(currentItem[0]?.firstName?.fr);
				setfirstNameSp(currentItem[0]?.firstName?.sp);
				setfirstNameAr(currentItem[0]?.firstName?.ar);
				setfirstNameTur(currentItem[0]?.firstName?.tur);
				setfirstNamePers(currentItem[0]?.firstName?.pers);
				setfirstNameCh(currentItem[0]?.firstName?.ch);

				setlastNameEn(currentItem[0]?.lastName?.en);
				setlastNameRu(currentItem[0]?.lastName?.ru);
				setlastNameHy(currentItem[0]?.lastName?.hy);
				setlastNameFr(currentItem[0]?.lastName?.fr);
				setlastNameSp(currentItem[0]?.lastName?.sp);
				setlastNameAr(currentItem[0]?.lastName?.ar);
				setlastNameTur(currentItem[0]?.lastName?.tur);
				setlastNamePers(currentItem[0]?.lastName?.pers);
				setlastNameCh(currentItem[0]?.lastName?.ch);

				setAddressEn(currentItem[0]?.address?.en);
				setAddressRu(currentItem[0]?.address?.ru);
				setAddressHy(currentItem[0]?.address?.hy);
				setAddressFr(currentItem[0]?.address?.fr);
				setAddressSp(currentItem[0]?.address?.sp);
				setAddressAr(currentItem[0]?.address?.ar);
				setAddressTur(currentItem[0]?.address?.tur);
				setAddressPers(currentItem[0]?.address?.pers);
				setAddressCh(currentItem[0]?.address?.ch);

				currentItem[0]?.dateOfBirth && setdateOfBirth(currentItem[0]?.dateOfBirth);
				setrole(currentItem[0]?.role);

				setimage(undefined);
				currentItem[0].uploads?.map((item) => {
					if (item.destiny === "image") setimage(item);
					return item;
				});
				genders.forEach((item) => {
					if (item._id === currentItem[0]?.gender) {
						setgender(item._id);
					}
				});
			}
			setBusy(false);
			NProgress.done();
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				chatID={chatID}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					user.role === "admin"
						? {
								value: index,
								setter: setindex,
								type: "number",
								usageType: "number",
								label: config.translate.index[lang],
								placeholder: config.translate.index[lang]
						  }
						: {
								value: index,
								type: "text",
								usageType: "textfield",
								label: config.translate.index[lang],
								disabled: true
						  },
					user.role === "admin"
						? {
								value: role,
								setter: setrole,
								optionListValue: roles,
								type: "optionList",
								usageType: "optionList",
								label: "Роль"
						  }
						: {
								value: role,
								type: "text",
								usageType: "textfield",
								label: "Роль",
								disabled: true
						  },
					user.role === "admin"
						? {
								value: username,
								setter: setusername,
								type: "text",
								usageType: "textfield",
								label: config.translate.login[lang],
								placeholder: config.translate.login[lang]
						  }
						: {
								value: username,
								type: "text",
								usageType: "textfield",
								label: config.translate.login[lang],
								disabled: true
						  },
					user.role === "admin"
						? {
								value: password,
								setter: setpassword,
								type: "password",
								usageType: "password",
								label: config.translate.password[lang],
								placeholder: config.translate.password[lang]
						  }
						: user.role !== "editor"
						? {
								value: password,
								type: "text",
								usageType: "textfield",
								label: config.translate.password[lang],
								disabled: true
						  }
						: null,
					user.role !== "editor"
						? {
								value: firstNameEn,
								setter: setfirstNameEn,
								type: "text",
								usageType: "textfield",
								label: config.translate.firstName[lang],
								placeholder: config.translate.firstName[lang],
								lang: "en"
						  }
						: {
								value: firstNameEn,
								type: "text",
								usageType: "textfield",
								label: config.translate.firstName[lang],
								disabled: true,
								lang: "en"
						  },
					user.role !== "editor"
						? {
								value: lastNameEn,
								setter: setlastNameEn,
								type: "text",
								usageType: "textfield",
								label: config.translate.lastName[lang],
								placeholder: config.translate.lastName[lang],
								lang: "en"
						  }
						: {
								value: lastNameEn,
								type: "text",
								usageType: "textfield",
								label: config.translate.lastName[lang],
								disabled: true,
								lang: "en"
						  },
					user.role !== "editor"
						? {
								value: firstNameRu,
								setter: setfirstNameRu,
								type: "text",
								usageType: "textfield",
								label: config.translate.firstName[lang],
								placeholder: config.translate.firstName[lang],
								lang: "ru"
						  }
						: {
								value: firstNameRu,
								type: "text",
								usageType: "textfield",
								label: config.translate.firstName[lang],
								disabled: true,
								lang: "ru"
						  },
					user.role !== "editor"
						? {
								value: lastNameRu,
								setter: setlastNameRu,
								type: "text",
								usageType: "textfield",
								label: config.translate.lastName[lang],
								placeholder: config.translate.lastName[lang],
								lang: "ru"
						  }
						: {
								value: lastNameRu,
								type: "text",
								usageType: "textfield",
								label: config.translate.lastName[lang],
								disabled: true,
								lang: "ru"
						  },
					user.role !== "editor"
						? {
								value: firstNameHy,
								setter: setfirstNameHy,
								type: "text",
								usageType: "textfield",
								label: config.translate.firstName[lang],
								placeholder: config.translate.firstName[lang],
								lang: "hy"
						  }
						: {
								value: firstNameHy,
								type: "text",
								usageType: "textfield",
								label: config.translate.firstName[lang],
								disabled: true,
								lang: "hy"
						  },
					user.role !== "editor"
						? {
								value: lastNameHy,
								setter: setlastNameHy,
								type: "text",
								usageType: "textfield",
								label: config.translate.lastName[lang],
								placeholder: config.translate.lastName[lang],
								lang: "hy"
						  }
						: {
								value: lastNameHy,
								type: "text",
								usageType: "textfield",
								label: config.translate.lastName[lang],
								disabled: true,
								lang: "hy"
						  },

					user.role !== "editor"
						? {
								value: addressHy,
								setter: setAddressHy,
								type: "text",
								usageType: "textfield",
								label: config.translate.address[lang],
								placeholder: config.translate.address[lang],
								lang: "hy"
						  }
						: {
								value: addressHy,
								type: "text",
								usageType: "textfield",
								label: config.translate.address[lang],
								disabled: true,
								lang: "hy"
						  },
					user.role !== "editor"
						? {
								value: addressRu,
								setter: setAddressRu,
								type: "text",
								usageType: "textfield",
								label: config.translate.address[lang],
								placeholder: config.translate.address[lang],
								lang: "ru"
						  }
						: {
								value: addressRu,
								type: "text",
								usageType: "textfield",
								label: config.translate.address[lang],
								disabled: true,
								lang: "ru"
						  },
					user.role !== "editor"
						? {
								value: addressEn,
								setter: setAddressEn,
								type: "text",
								usageType: "textfield",
								label: config.translate.address[lang],
								placeholder: config.translate.address[lang],
								lang: "en"
						  }
						: {
								value: addressEn,
								type: "text",
								usageType: "textfield",
								label: config.translate.address[lang],
								disabled: true,
								lang: "en"
						  },
					user.role !== "editor"
						? {
								value: dateOfBirth,
								setter: setdateOfBirth,
								type: "date",
								usageType: "datePicker",
								label: config.translate.dateOfBirth[lang],
								placeholder: config.translate.dateOfBirth[lang]
						  }
						: {
								value: dateOfBirth,
								type: "text",
								usageType: "textfield",
								label: config.translate.dateOfBirth[lang],
								disabled: true
						  },
					user.role !== "editor"
						? {
								value: gender,
								setter: setgender,
								optionListValue: genders,
								type: "text",
								usageType: "optionList",
								label: config.translate.gender[lang],
								lang: "ru"
						  }
						: {
								value: gender,
								type: "text",
								usageType: "textfield",
								label: config.translate.gender[lang],
								disabled: true,
								lang: "ru"
						  },
					user.role !== "editor"
						? {
								value: gender,
								setter: setgender,
								optionListValue: genders,
								type: "text",
								usageType: "optionList",
								label: config.translate.gender[lang],
								lang: "hy"
						  }
						: {
								value: gender,
								type: "text",
								usageType: "textfield",
								label: config.translate.gender[lang],
								disabled: true,
								lang: "hy"
						  },
					user.role !== "editor"
						? {
								value: gender,
								setter: setgender,
								optionListValue: genders,
								type: "text",
								usageType: "optionList",
								label: config.translate.gender[lang],
								lang: "en"
						  }
						: {
								value: gender,
								type: "text",
								usageType: "textfield",
								label: config.translate.gender[lang],
								disabled: true,
								lang: "en"
						  },
					user.role !== "editor"
						? {
								value: email,
								setter: setemail,
								type: "text",
								usageType: "email",
								label: config.translate.email[lang]
						  }
						: {
								value: email,
								type: "text",
								usageType: "textfield",
								label: config.translate.email[lang],
								disabled: true
						  },
					user.role !== "editor"
						? {
								value: phone,
								setter: setphone,
								type: "text",
								usageType: "phone",
								label: config.translate.phone[lang]
						  }
						: {
								value: phone,
								type: "text",
								usageType: "textfield",
								label: config.translate.phone[lang],
								disabled: true
						  }
				]}
				filesComponent={[
					{
						destiny: "image",
						placeholder: config.translate.image[lang],
						file: image,
						setFile: setimage,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						height: 500,
						width: 500
					}
				]}
			/>
		);
}
