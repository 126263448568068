import React, { useEffect, useState } from "react";
import config from "../../config";
import {
  Input,
  useMantineColorScheme,
  useMantineTheme,
  MantineProvider,
  Button,
  Box,
  Flex,
  Menu,
  Text,
  Title,
  Timeline,
  Card,
} from "@mantine/core";
import { useLang } from "../../helpers/language";
import {
  IconUserCircle,
  IconSend,
  IconEdit,
  IconDownload,
  IconGitBranch,
  IconGitPullRequest,
  IconGitCommit,
  IconMessageDots,
} from "@tabler/icons-react";
import moment from "moment";

export function TimelineComponent({
  data,
  placeholder,
  width,
  height,
  bullet,
}) {
  const { lang } = useLang();
  const { colorScheme } = useMantineColorScheme();
  const Theme = useMantineTheme();

  return (
    <Card
      p={20}
      style={{
        maxWidth: width ? width + 40 : "100%",
        height: height ? height + 130 : "100%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        border: `1px solid ${
          colorScheme === "dark" ? Theme.colors.gray[7] : "white"
        }`,
      }}
    >
      <Timeline active={data.length} bulletSize={24} lineWidth={2}>
        {data?.map((item, index) => {
          return (
            <Timeline.Item
              // bullet={<IconGitCommit size={12} />}
              bullet={bullet ? bullet : <IconMessageDots size={12} />}
              title={item.status?.name?.[lang] || "No title"}
              key={index}
            >
              <Text c="dimmed" size="sm">
                {item.user?.name?.[lang] || "No description"}
              </Text>
              <Text size="xs" mt={4}>
                {moment(item.date).format("HH:mm")}
                {index > 0 &&
                  index < data.length - 1 &&
                  " - " +
                    moment(data[index + 1]?.date).diff(
                      moment(item.date),
                      "minutes"
                    )}
              </Text>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Card>
  );
}
