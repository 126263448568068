import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { parsePhoneNumber } from "../../helpers/parsePhoneNumber";
import { useUser } from "../../helpers/userContext";

export default function Page() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.client;
	const { lang } = useLang();
	const { user } = useUser();

	const [index, setindex] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [tel, setTel] = useState("");
	const [telegram, setTelegram] = useState("");
	const [viber, setViber] = useState("");
	const [whatsapp, setWhatsapp] = useState("");
	const [registeredDate, setRegisteredDate] = useState(new Date());
	const [corporation, setCorporation] = useState("");
	const [address, setAddress] = useState("");

	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");
	const [nameFr, setNameFr] = useState("");
	const [nameSp, setNameSp] = useState("");
	const [nameAr, setNameAr] = useState("");
	const [nameTur, setNameTur] = useState("");
	const [namePers, setNamePers] = useState("");
	const [nameCh, setNameCh] = useState("");

	const [image, setImage] = useState(undefined);

	const modelSendToServer = {
		index,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy,
			fr: nameFr,
			sp: nameSp,
			ar: nameAr,
			tur: nameTur,
			pers: namePers,
			ch: nameCh
		},
		email,
		phone,
		tel,
		telegram,
		viber,
		whatsapp,
		registeredDate,
		corporation,
		address
	};

	useEffect(() => {
		(async () => {
			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id
					},
					{},
					{},
					{},
					[]
				);
				setindex(currentItem[0]?.index);

				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);
				setNameFr(currentItem[0]?.name?.fr);
				setNameSp(currentItem[0]?.name?.sp);
				setNameAr(currentItem[0]?.name?.ar);
				setNameTur(currentItem[0]?.name?.tur);
				setNamePers(currentItem[0]?.name?.pers);
				setNameCh(currentItem[0]?.name?.ch);

				setEmail(currentItem[0]?.email);
				setPhone(parsePhoneNumber(currentItem[0]?.phone));
				setTel(parsePhoneNumber(currentItem[0]?.tel));
				setTelegram(currentItem[0]?.telegram);
				setViber(currentItem[0]?.viber);
				setWhatsapp(currentItem[0]?.whatsapp);
				currentItem[0]?.registeredDate && setRegisteredDate(currentItem[0]?.registeredDate);
				setCorporation(currentItem[0]?.corporation);
				setAddress(currentItem[0]?.address);

				setImage(undefined);
				currentItem[0].uploads?.map((item) => {
					if (item.destiny === "image") setImage(item);
					return item;
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setindex,
						type: "number",
						usageType: "number",
						label: config.translate.index[lang],
						placeholder: config.translate.index[lang]
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "en"
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ru"
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "hy"
					},
					{
						value: nameFr,
						setter: setNameFr,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "fr"
					},
					{
						value: nameSp,
						setter: setNameSp,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "sp"
					},
					{
						value: nameAr,
						setter: setNameAr,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ar"
					},
					{
						value: nameTur,
						setter: setNameTur,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "tur"
					},
					{
						value: namePers,
						setter: setNamePers,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "pers"
					},
					{
						value: nameCh,
						setter: setNameCh,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: "ch"
					},
					{
						value: email,
						setter: setEmail,
						type: "email",
						usageType: "email",
						label: config.translate.email[lang],
						placeholder: config.translate.email[lang]
					},
					{
						value: phone,
						setter: setPhone,
						type: "phone",
						usageType: "phone",
						label: config.translate.phone[lang],
						placeholder: config.translate.phone[lang]
					},
					{
						value: tel,
						setter: setTel,
						type: "phone",
						usageType: "phone",
						label: config.translate.tel[lang],
						placeholder: config.translate.tel[lang]
					},
					{
						value: telegram,
						setter: setTelegram,
						type: "url",
						usageType: "url",
						label: config.translate.telegram[lang],
						placeholder: config.translate.telegram[lang]
					},
					{
						value: viber,
						setter: setViber,
						type: "url",
						usageType: "url",
						label: config.translate.viber[lang],
						placeholder: config.translate.viber[lang]
					},
					{
						value: whatsapp,
						setter: setWhatsapp,
						type: "url",
						usageType: "url",
						label: config.translate.whatsapp[lang],
						placeholder: config.translate.whatsapp[lang]
					},
					{
						value: registeredDate,
						setter: setRegisteredDate,
						type: "text",
						usageType: "textfield",
						disabled: true,
						label: config.translate.registeredDate[lang],
						placeholder: config.translate.registeredDate[lang]
					},
					{
						value: corporation,
						setter: setCorporation,
						type: "text",
						usageType: "textfield",
						label: config.translate.corporation[lang],
						placeholder: config.translate.corporation[lang]
					},
					{
						value: address,
						setter: setAddress,
						type: "text",
						usageType: "textfield",
						label: config.translate.address[lang],
						placeholder: config.translate.address[lang]
					}
				]}
				filesComponent={[
					{
						destiny: "image",
						placeholder: config.translate.image[lang],
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						height: 500,
						width: 500
					}
				]}
			/>
		);
}
