import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { parsePhoneNumber } from "../../helpers/parsePhoneNumber";

export default function Page() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.contactForm;
	const { lang } = useLang();

	const [index, setIndex] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [titleRu, setTitleRu] = useState("");
	const [titleHy, setTitleHy] = useState("");
	const [titleFr, setTitleFr] = useState("");
	const [titleSp, setTitleSp] = useState("");
	const [titleAr, setTitleAr] = useState("");
	const [titleTur, setTitleTur] = useState("");
	const [titlePers, setTitlePers] = useState("");
	const [titleCh, setTitleCh] = useState("");

	const [buttonTextEn, setButtonTextEn] = useState("");
	const [buttonTextRu, setButtonTextRu] = useState("");
	const [buttonTextHy, setButtonTextHy] = useState("");
	const [buttonTextFr, setButtonTextFr] = useState("");
	const [buttonTextSp, setButtonTextSp] = useState("");
	const [buttonTextAr, setButtonTextAr] = useState("");
	const [buttonTextTur, setButtonTextTur] = useState("");
	const [buttonTextPers, setButtonTextPers] = useState("");
	const [buttonTextCh, setButtonTextCh] = useState("");

	const [name, setName] = useState(false);
	const [desc, setDesc] = useState(false);
	const [email, setEmail] = useState(false);
	const [phone, setPhone] = useState(false);
	const [serviceType, setServiceType] = useState(false);
	const [dateToDeliver, setDateToDeliver] = useState(false);
	const [dateInterval, setDateInteval] = useState(false);
	const [paymentType, setPaymentType] = useState(false);
	const [amountOfMoney, setAmountOfMoney] = useState(false);
	const [bussinessType, setBussinessType] = useState(false);
	const [itemName, setItemName] = useState(false);
	const [itemUrl, setItemUrl] = useState(false);
	const [itemPhoto, setItemPhoto] = useState(false);
	const [itemVideo, setItemVideo] = useState(false);
	const [itemDesc, setItemDesc] = useState(false);
	const [deliveryType, setDeliveryType] = useState(false);
	const [packageName, setPackageName] = useState(false);
	const [packageUrl, setPackageUrl] = useState(false);
	const [packagePhoto, setPackagePhoto] = useState(false);
	const [packageVideo, setPackageVideo] = useState(false);
	const [packageDesc, setPackageDesc] = useState(false);
	const [packageM3, setPackageM3] = useState(false);
	const [packageKg, setPackageKg] = useState(false);
	const [packageType, setPackageType] = useState(false);

	const modelSendToServer = {
		index,
		title: {
			en: titleEn,
			ru: titleRu,
			hy: titleHy,
			fr: titleFr,
			sp: titleSp,
			ar: titleAr,
			tur: titleTur,
			pers: titlePers,
			ch: titleCh
		},
		package: {
			name: packageName,
			url: packageUrl,
			photo: packagePhoto,
			video: packageVideo,
			desc: packageDesc,
			m3: packageM3,
			kg: packageKg,
			packageType: packageType
		},
		buttonText: {
			en: buttonTextEn,
			ru: buttonTextRu,
			hy: buttonTextHy,
			fr: buttonTextFr,
			sp: buttonTextSp,
			ar: buttonTextAr,
			tur: buttonTextTur,
			pers: buttonTextPers,
			ch: buttonTextCh
		},
		item: {
			name: itemName,
			url: itemUrl,
			photo: itemPhoto,
			video: itemVideo,
			desc: itemDesc
		},
		name,
		desc,
		email,
		phone,
		serviceType,
		dateToDeliver,
		dateInterval,
		paymentType,
		amountOfMoney,
		bussinessType,
		deliveryType
	};

	useEffect(() => {
		(async () => {
			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id
					},
					{},
					{},
					{},
					[]
				);

				setIndex(currentItem[0]?.index);
				setTitleEn(currentItem[0]?.title?.en);
				setTitleRu(currentItem[0]?.title?.ru);
				setTitleHy(currentItem[0]?.title?.hy);
				setTitleFr(currentItem[0]?.title?.fr);
				setTitleSp(currentItem[0]?.title?.sp);
				setTitleAr(currentItem[0]?.title?.ar);
				setTitleTur(currentItem[0]?.title?.tur);
				setTitlePers(currentItem[0]?.title?.pers);
				setTitleCh(currentItem[0]?.title?.ch);

				setButtonTextEn(currentItem[0]?.buttonText?.en);
				setButtonTextRu(currentItem[0]?.buttonText?.ru);
				setButtonTextHy(currentItem[0]?.buttonText?.hy);
				setButtonTextFr(currentItem[0]?.buttonText?.fr);
				setButtonTextSp(currentItem[0]?.buttonText?.sp);
				setButtonTextAr(currentItem[0]?.buttonText?.ar);
				setButtonTextTur(currentItem[0]?.buttonText?.tur);
				setButtonTextPers(currentItem[0]?.buttonText?.pers);
				setButtonTextCh(currentItem[0]?.buttonText?.ch);

				setPackageName(currentItem[0]?.package?.name);
				setPackageUrl(currentItem[0]?.package?.url);
				setPackagePhoto(currentItem[0]?.package?.photo);
				setPackageVideo(currentItem[0]?.package?.video);
				setPackageDesc(currentItem[0]?.package?.desc);
				setPackageM3(currentItem[0]?.package?.m3);
				setPackageKg(currentItem[0]?.package?.kg);
				setPackageType(currentItem[0]?.package?.packageType);

				setName(currentItem[0]?.name);
				setDesc(currentItem[0]?.desc);
				setEmail(currentItem[0]?.email);
				setPhone(currentItem[0]?.phone);
				setServiceType(currentItem[0]?.serviceType);
				setDateToDeliver(currentItem[0]?.dateToDeliver);
				setDateInteval(currentItem[0]?.dateInterval);
				setPaymentType(currentItem[0]?.paymentType);
				setAmountOfMoney(currentItem[0]?.amountOfMoney);
				setBussinessType(currentItem[0]?.bussinessType);
				setItemName(currentItem[0]?.item?.name);
				setItemUrl(currentItem[0]?.item?.url);
				setItemPhoto(currentItem[0]?.item?.photo);
				setItemVideo(currentItem[0]?.item?.video);
				setItemDesc(currentItem[0]?.item?.desc);
				setDeliveryType(currentItem[0]?.deliveryType);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "textfield",
						label: config.translate.index[lang],
						placeholder: config.translate.index[lang]
					},
					{
						value: titleEn,
						setter: setTitleEn,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "en"
					},
					{
						value: titleRu,
						setter: setTitleRu,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "ru"
					},
					{
						value: titleHy,
						setter: setTitleHy,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "hy"
					},
					{
						value: titleFr,
						setter: setTitleFr,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "fr"
					},
					{
						value: titleSp,
						setter: setTitleSp,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "sp"
					},
					{
						value: titleAr,
						setter: setTitleAr,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "ar"
					},
					{
						value: titleTur,
						setter: setTitleTur,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "tur"
					},
					{
						value: titlePers,
						setter: setTitlePers,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "pers"
					},
					{
						value: titleCh,
						setter: setTitleCh,
						type: "text",
						usageType: "textfield",
						label: config.translate.title[lang],
						placeholder: config.translate.title[lang],
						lang: "ch"
					},
					{
						value: buttonTextEn,
						setter: setButtonTextEn,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "en"
					},
					{
						value: buttonTextRu,
						setter: setButtonTextRu,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "ru"
					},
					{
						value: buttonTextHy,
						setter: setButtonTextHy,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "hy"
					},
					{
						value: buttonTextFr,
						setter: setButtonTextFr,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "fr"
					},
					{
						value: buttonTextSp,
						setter: setButtonTextSp,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "sp"
					},
					{
						value: buttonTextAr,
						setter: setButtonTextAr,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "ar"
					},
					{
						value: buttonTextTur,
						setter: setButtonTextTur,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "tur"
					},
					{
						value: buttonTextPers,
						setter: setButtonTextPers,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "pers"
					},
					{
						value: buttonTextCh,
						setter: setButtonTextCh,
						type: "text",
						usageType: "textfield",
						label: config.translate.buttonText[lang],
						placeholder: config.translate.buttonText[lang],
						lang: "ch"
					},
					{
						value: name,
						setter: setName,
						type: "switch",
						usageType: "switch",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang]
					},
					{
						value: desc,
						setter: setDesc,
						type: "switch",
						usageType: "switch",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang]
					},
					{
						value: email,
						setter: setEmail,
						type: "switch",
						usageType: "switch",
						label: config.translate.email[lang],
						placeholder: config.translate.email[lang]
					},
					{
						value: phone,
						setter: setPhone,
						type: "switch",
						usageType: "switch",
						label: config.translate.phone[lang],
						placeholder: config.translate.phone[lang]
					},
					{
						value: serviceType,
						setter: setServiceType,
						type: "switch",
						usageType: "switch",
						label: config.translate.serviceType[lang],
						placeholder: config.translate.serviceType[lang]
					},
					{
						value: dateToDeliver,
						setter: setDateToDeliver,
						type: "switch",
						usageType: "switch",
						label: config.translate.dateToDeliver[lang],
						placeholder: config.translate.dateToDeliver[lang]
					},
					{
						value: dateInterval,
						setter: setDateInteval,
						type: "switch",
						usageType: "switch",
						label: config.translate.dateInterval[lang],
						placeholder: config.translate.dateInterval[lang]
					},
					{
						value: paymentType,
						setter: setPaymentType,
						type: "switch",
						usageType: "switch",
						label: config.translate.paymentType[lang],
						placeholder: config.translate.paymentType[lang]
					},
					{
						value: amountOfMoney,
						setter: setAmountOfMoney,
						type: "switch",
						usageType: "switch",
						label: config.translate.amountOfMoney[lang],
						placeholder: config.translate.amountOfMoney[lang]
					},
					{
						value: bussinessType,
						setter: setBussinessType,
						type: "switch",
						usageType: "switch",
						label: config.translate.bussinessType[lang],
						placeholder: config.translate.bussinessType[lang]
					},
					{
						value: itemName,
						setter: setItemName,
						type: "switch",
						usageType: "switch",
						label: config.translate.itemName[lang],
						placeholder: config.translate.itemName[lang]
					},
					{
						value: itemUrl,
						setter: setItemUrl,
						type: "switch",
						usageType: "switch",
						label: config.translate.itemUrl[lang],
						placeholder: config.translate.itemUrl[lang]
					},
					{
						value: itemPhoto,
						setter: setItemPhoto,
						type: "switch",
						usageType: "switch",
						label: config.translate.itemPhoto[lang],
						placeholder: config.translate.itemPhoto[lang]
					},
					{
						value: itemVideo,
						setter: setItemVideo,
						type: "switch",
						usageType: "switch",
						label: config.translate.itemVideo[lang],
						placeholder: config.translate.itemVideo[lang]
					},
					{
						value: itemDesc,
						setter: setItemDesc,
						type: "switch",
						usageType: "switch",
						label: config.translate.itemDesc[lang],
						placeholder: config.translate.itemDesc[lang]
					},
					{
						value: deliveryType,
						setter: setDeliveryType,
						type: "switch",
						usageType: "switch",
						label: config.translate.deliveryType[lang],
						placeholder: config.translate.deliveryType[lang]
					},
					{
						value: packageName,
						setter: setPackageName,
						type: "switch",
						usageType: "switch",
						label: config.translate.packageName[lang],
						placeholder: config.translate.packageName[lang]
					},
					{
						value: packageUrl,
						setter: setPackageUrl,
						type: "switch",
						usageType: "switch",
						label: config.translate.packageUrl[lang],
						placeholder: config.translate.packageUrl[lang]
					},
					{
						value: packageDesc,
						setter: setPackageDesc,
						type: "switch",
						usageType: "switch",
						label: config.translate.packageDesc[lang],
						placeholder: config.translate.packageDesc[lang]
					},
					{
						value: packageM3,
						setter: setPackageM3,
						type: "switch",
						usageType: "switch",
						label: config.translate.m3[lang],
						placeholder: config.translate.m3[lang]
					},
					{
						value: packageKg,
						setter: setPackageKg,
						type: "switch",
						usageType: "switch",
						label: config.translate.kg[lang],
						placeholder: config.translate.kg[lang]
					},
					{
						value: packagePhoto,
						setter: setPackagePhoto,
						type: "switch",
						usageType: "switch",
						label: config.translate.packagePhoto[lang],
						placeholder: config.translate.packagePhoto[lang]
					},
					{
						value: packageVideo,
						setter: setPackageVideo,
						type: "switch",
						usageType: "switch",
						label: config.translate.packageVideo[lang],
						placeholder: config.translate.packageVideo[lang]
					},
					{
						value: packageType,
						setter: setPackageType,
						type: "switch",
						usageType: "switch",
						label: config.translate.packageType[lang],
						placeholder: config.translate.packageType[lang]
					}
				]}
			/>
		);
}
