import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { parsePhoneNumber } from "../../helpers/parsePhoneNumber";

export default function Page() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.order;
	const { lang } = useLang();

	const [ID, setID] = useState("");
	const [name, setName] = useState(false);
	const [desc, setDesc] = useState(false);
	const [email, setEmail] = useState(false);
	const [phone, setPhone] = useState(false);
	const [serviceType, setServiceType] = useState(false);
	const [dateToDeliver, setDateToDeliver] = useState(false);
	const [dateIntevalStart, setDateIntevalStart] = useState(false);
	const [dateIntevalEnd, setDateIntevalEnd] = useState(false);
	const [paymentType, setPaymentType] = useState(false);
	const [amountOfMoney, setAmountOfMoney] = useState(false);
	const [bussinessType, setBussinessType] = useState(false);
	const [deliveryType, setDeliveryType] = useState(false);

	const [itemName, setItemName] = useState(false);
	const [itemUrl, setItemUrl] = useState(false);
	const [itemDesc, setItemDesc] = useState(false);

	const [packageName, setPackageName] = useState(false);
	const [packageUrl, setPackageUrl] = useState(false);
	const [packageDesc, setPackageDesc] = useState(false);
	const [packageM3, setPackageM3] = useState(false);
	const [packageKg, setPackageKg] = useState(false);
	const [packageType, setPackageType] = useState(false);

	const [itemImages, setItemImages] = useState([]);
	const [itemVideo, setItemVideo] = useState(undefined);
	const [packageImages, setPackageImages] = useState([]);
	const [packageVideo, setPackageVideo] = useState(undefined);

	const modelSendToServer = {
		ID,
		item: {
			name: itemName,
			url: itemUrl,
			desc: itemDesc
		},
		Package: {
			name: packageName,
			url: packageUrl,
			desc: packageDesc,
			m3: packageM3,
			kg: packageKg,
			type: packageType
		},
		name,
		desc,
		email,
		phone,
		serviceType,
		dateToDeliver,
		dateInterval: {
			startDate: dateIntevalStart,
			endDate: dateIntevalEnd
		},
		paymentType,
		amountOfMoney,
		bussinessType,
		deliveryType
	};

	useEffect(() => {
		(async () => {
			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id
					},
					{},
					true,
					{},
					[]
				);

				setID(currentItem[0]?.ID);

				setItemName(currentItem[0]?.item?.name);
				setItemUrl(currentItem[0]?.item?.url);
				setItemDesc(currentItem[0]?.item?.desc);

				setPackageName(currentItem[0]?.package?.name);
				setPackageUrl(currentItem[0]?.package?.url);
				setPackageDesc(currentItem[0]?.package?.desc);
				setPackageM3(currentItem[0]?.package?.m3);
				setPackageKg(currentItem[0]?.package?.kg);
				setPackageType(currentItem[0]?.package?.packageType);

				setName(currentItem[0]?.name);
				setDesc(currentItem[0]?.desc);
				setEmail(currentItem[0]?.email);
				setPhone(currentItem[0]?.phone);
				setServiceType(currentItem[0]?.serviceType);
				setDateToDeliver(currentItem[0]?.dateToDeliver);
				setDateIntevalStart(currentItem[0]?.dateInterval?.startDate);
				setDateIntevalEnd(currentItem[0]?.dateInterval?.endDate);
				setPaymentType(currentItem[0]?.paymentType);
				setAmountOfMoney(currentItem[0]?.amountOfMoney);
				setBussinessType(currentItem[0]?.bussinessType);
				setDeliveryType(currentItem[0]?.deliveryType);

				setItemImages([]);
				setItemVideo(undefined);
				setPackageImages([]);
				setPackageVideo(undefined);

				currentItem[0].uploads?.map((item) => {
					if (item.destiny === "itemImages") {
						setItemImages((prev) => [...prev, item]);
					}
					if (item.destiny === "itemVideo") {
						setItemVideo(item);
					}
					if (item.destiny === "packageImages") {
						setPackageImages((prev) => [...prev, item]);
					}
					if (item.destiny === "packageVideo") {
						setPackageVideo(item);
					}
					return item;
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					ID && {
						value: ID,
						setter: setID,
						type: "number",
						usageType: "number",
						label: config.translate.ID[lang],
						placeholder: config.translate.ID[lang]
					},
					name && {
						value: name,
						setter: setName,
						type: "text",
						usageType: "textfield",
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang]
					},
					desc && {
						value: desc,
						setter: setDesc,
						type: "text",
						usageType: "textfield",
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang]
					},
					email && {
						value: email,
						setter: setEmail,
						type: "text",
						usageType: "textfield",
						label: config.translate.email[lang],
						placeholder: config.translate.email[lang]
					},
					phone && {
						value: phone,
						setter: setPhone,
						type: "text",
						usageType: "textfield",
						label: config.translate.phone[lang],
						placeholder: config.translate.phone[lang]
					},
					serviceType && {
						value: serviceType,
						setter: setServiceType,
						type: "text",
						usageType: "textfield",
						label: config.translate.serviceType[lang],
						placeholder: config.translate.serviceType[lang]
					},
					dateToDeliver && {
						value: dateToDeliver,
						setter: setDateToDeliver,
						type: "text",
						usageType: "textfield",
						label: config.translate.dateToDeliver[lang],
						placeholder: config.translate.dateToDeliver[lang]
					},
					dateIntevalStart && {
						value: dateIntevalStart,
						setter: setDateIntevalStart,
						type: "text",
						usageType: "textfield",
						label: config.translate.dateIntevalStart[lang],
						placeholder: config.translate.dateIntevalStart[lang]
					},
					dateIntevalEnd && {
						value: dateIntevalEnd,
						setter: setDateIntevalEnd,
						type: "text",
						usageType: "textfield",
						label: config.translate.dateIntevalEnd[lang],
						placeholder: config.translate.dateIntevalEnd[lang]
					},
					paymentType && {
						value: paymentType,
						setter: setPaymentType,
						type: "text",
						usageType: "textfield",
						label: config.translate.paymentType[lang],
						placeholder: config.translate.paymentType[lang]
					},
					amountOfMoney && {
						value: amountOfMoney,
						setter: setAmountOfMoney,
						type: "text",
						usageType: "textfield",
						label: config.translate.amountOfMoney[lang],
						placeholder: config.translate.amountOfMoney[lang]
					},
					bussinessType && {
						value: bussinessType,
						setter: setBussinessType,
						type: "text",
						usageType: "textfield",
						label: config.translate.bussinessType[lang],
						placeholder: config.translate.bussinessType[lang]
					},
					itemName && {
						value: itemName,
						setter: setItemName,
						type: "text",
						usageType: "textfield",
						label: config.translate.itemName[lang],
						placeholder: config.translate.itemName[lang]
					},
					itemUrl && {
						value: itemUrl,
						setter: setItemUrl,
						type: "text",
						usageType: "textfield",
						label: config.translate.itemUrl[lang],
						placeholder: config.translate.itemUrl[lang]
					},
					itemDesc && {
						value: itemDesc,
						setter: setItemDesc,
						type: "text",
						usageType: "textfield",
						label: config.translate.itemDesc[lang],
						placeholder: config.translate.itemDesc[lang]
					},
					deliveryType && {
						value: deliveryType,
						setter: setDeliveryType,
						type: "text",
						usageType: "textfield",
						label: config.translate.deliveryType[lang],
						placeholder: config.translate.deliveryType[lang]
					},
					packageName && {
						value: packageName,
						setter: setPackageName,
						type: "text",
						usageType: "textfield",
						label: config.translate.packageName[lang],
						placeholder: config.translate.packageName[lang]
					},
					packageUrl && {
						value: packageUrl,
						setter: setPackageUrl,
						type: "text",
						usageType: "textfield",
						label: config.translate.packageUrl[lang],
						placeholder: config.translate.packageUrl[lang]
					},
					packageDesc && {
						value: packageDesc,
						setter: setPackageDesc,
						type: "text",
						usageType: "textfield",
						label: config.translate.packageDesc[lang],
						placeholder: config.translate.packageDesc[lang]
					},
					packageM3 && {
						value: packageM3,
						setter: setPackageM3,
						type: "number",
						usageType: "number",
						label: config.translate.m3[lang],
						placeholder: config.translate.m3[lang]
					},
					packageKg && {
						value: packageKg,
						setter: setPackageKg,
						type: "number",
						usageType: "number",
						label: config.translate.kg[lang],
						placeholder: config.translate.kg[lang]
					},
					packageType && {
						value: packageType,
						setter: setPackageType,
						type: "text",
						usageType: "textfield",
						label: config.translate.packageType[lang],
						placeholder: config.translate.packageType[lang]
					}
				]}
				filesComponent={[
					{
						destiny: "itemImages",
						files: itemImages,
						setFiles: setItemImages,
						filesUploadLimit: 3,
						filesMaxSize: 5,
						height: 500,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						placeholder: config.translate.itemImages[lang]
					},
					{
						destiny: "itemVideo",
						file: itemVideo,
						setFile: setItemVideo,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						height: 500,
						acceptedFiles: ["video/mp4", "video/ogg", "video/webm"],
						placeholder: config.translate.itemVideo[lang]
					},
					{
						destiny: "packageImages",
						files: packageImages,
						setFiles: setPackageImages,
						filesUploadLimit: 3,
						filesMaxSize: 5,
						height: 500,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						placeholder: config.translate.packageImages[lang]
					},
					{
						destiny: "packageVideo",
						file: packageVideo,
						setFile: setPackageVideo,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						height: 500,
						acceptedFiles: ["video/mp4", "video/ogg", "video/webm"],
						placeholder: config.translate.packageVideo[lang]
					}
				]}
			/>
		);
}
